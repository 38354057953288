import React, { useEffect, useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg';
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import {ReactComponent as IZipFile} from '../../../../styles/images/svg/file-archive.svg';
import { ArcoRegistryImportDocumentModal } from "./ArcoRegistryImportDocumentModal"
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import axiosService from "../../../../services/axios-service";


registerLocale("it", it);

export function ArcoRegistryClipForm(props) {

    const dispatch = useDispatch();
    const [checkedClipRow, setCheckedClipRow] = useState([]);
    const [showImportModal, setShowImportModal] = useState(false);

    const openShowImportModal =()=>{
        if(props?.arcoRegistriesOperation?.id){
            setShowImportModal(!showImportModal);
        }else{
            PopupError({ text: "Impossibile aggiungere documento, salvare prima l'operazione" })
        }
    }


    const arcoDocument = useSelector(state => state.arcoRegistryDocument.data);    
        
    useEffect(() =>{
        getAllArcoDocumentsByOperationId();
    },[]);

    const getAllArcoDocumentsByOperationId = () => {
        if(props.arcoRegistriesOperation){
            dispatch(actions.getArcoRegistryDocuments(props.arcoRegistriesOperation.id));
        }

    }

    const downloadDocument = (documento) => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: "/api/arco/custom/arco-operations-document",
            data: documento,
            method: 'POST',
            responseType: 'blob',
        }).then((response) => {
            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documento.documentName);
                document.body.appendChild(link);
                link.click();
            }
        },(err)=>{
            if(err.errorKey === "arcoOperationCheck"){
                PopupError({ ...props, text: err.title });
            }else{
                PopupError({text:"Errore download documento"});
            }
        });
    };

    const onCheckBoxClick = (event, clipDocuments) => {
        if (event.target.checked) {
            checkClipDocuments(clipDocuments);
            let element = document.getElementById("clip-" + clipDocuments.documentName);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            uncheckClipDocuments(clipDocuments);
            document.getElementById('checkboxAllClipId').checked = false;
            let element = document.getElementById("clip-" + clipDocuments.documentName);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    const checkClipDocuments = (clipDocuments) => {
        setCheckedClipRow(checkedClipRow.concat(clipDocuments));
        if(checkedClipRow.length + 1 === arcoDocument.length ){
            checkAll();
        }
    };

    const uncheckClipDocuments = (clipDocuments) => {
        let newList = [];
        checkedClipRow.map(current => {
            if (current.documentName !== clipDocuments.documentName)
                newList.push(current);
        });
        setCheckedClipRow(newList);
    };

    const onCheckClick = (event) => {
        if (event.target.checked)
            checkAll();
        else
            uncheckAll();
    };

    const checkAll = () => {
        document.getElementById('checkboxAllClipId').checked = true;
        arcoDocument.map(clipDocuments => {
            const checkboxDetailsId = `checkboxClipDetails${clipDocuments.documentName}`;
            const idCheckbox = `clip-${clipDocuments.documentName}`;
            document.getElementById(checkboxDetailsId).checked = true;
            document.getElementById(idCheckbox).checked = true;
        });
        let rowIds = document.querySelectorAll('[id^="clip-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        checkallArcoRegistriesclips();
    };

    const checkallArcoRegistriesclips = () => {
        let allClipDocuments = arcoDocument;
        setCheckedClipRow(allClipDocuments);
    };

    const uncheckAll = () => {
        document.getElementById('checkboxAllClipId').checked = false;
        arcoDocument.map(clipDocuments => {
            const checkboxDetailsId = `checkboxClipDetails${clipDocuments.documentName}`;
            const idCheckbox = `clip-${clipDocuments.documentName}`;
            document.getElementById(checkboxDetailsId).checked = false;
            document.getElementById(idCheckbox).checked = false;
        });
        uncheckallArcoRegistriesclips();
        let rowIds = document.querySelectorAll('[id^="clip-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    const uncheckallArcoRegistriesclips = () => {
        setCheckedClipRow([]);
    };

    const getBlobFromBase64 = (base64) => {
        let byteString = atob(base64);

        // Convert that text into a byte array.
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {
            type: "application/zip"
        });
    }

    const zipAllDocuments = () => {

        actions.downloadArcoOperationZip(checkedClipRow).then((response) => {
            if (response) {
                const url = window.URL.createObjectURL(getBlobFromBase64(response));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "DOCUMENTI - " + props?.arcoRegistriesOperation?.description?.substring(0,30) + ".zip");
                document.body.appendChild(link);
                link.click();
            }
        },(err)=>{
            if(err.errorKey === "arcoOperationCheck"){
                PopupError({ ...props, text: err.title });
            }else{
                PopupError({text:"Errore creazione cartella .zip"});
            }
        });
    };

    const deleteClipDocumentsSubject = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance({
            url: "/api/arco/custom/arco-operations-document",
            data: checkedClipRow,
            method: 'DELETE',
        }).then((response) => {
                    setCheckedClipRow([]);
                    getAllArcoDocumentsByOperationId();   
                    
                    //chiudo form se contiene l'elemento cancellato
                },(err)=>{
                    PopupError({ ...props, text: err.title });
                });
                checkedClipRow.map(clipDocuments => {
            document.getElementById(`checkboxClipDetails${clipDocuments.documentName}`).checked = false;
            document.getElementById(`checkboxAllClipId`).checked = false;

            let newList = [];
            arcoDocument.map(current => {
                if (current.id !== clipDocuments.documentName)
                    newList.push(current);
            });
            setCheckedClipRow(newList);
        });
        let rowIds = document.querySelectorAll('[id^="clip-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    const renderRows = () => {
        return arcoDocument.map((document, index) => {
            const idCheckbox = `checkboxClipDetails${document.documentName}`;
            return (
                <React.Fragment >
                    <div
                        className={'text-start row-table'}
                        id={`clip-${document.documentName}`}
                    >
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={e => onCheckBoxClick(e, document)}
                            />
                        </div>
                        <div className='col-lg-10 div-td text-truncate' onClick={()=>downloadDocument(document)}><strong>&nbsp;&nbsp;{document.documentName}</strong> </div>
                        <div className='col-lg-1 div-td text-center'><strong>&nbsp;&nbsp;<IPdfFile onClick={()=>downloadDocument(document)} className={"padding-right-4px svg"} width="16" fill={"#128186"} /></strong> </div>

                    </div>
                </React.Fragment>
            )
        });
    };

    return (
        <React.Fragment>
            <div className="col-12">
                <div className="table-custom mb-1">
                    <div className="tab-content tab-rel p-0" id={"searchButtonFatherDiv"}>
                        <div className="row header-btn-relationship" style={{height:75 + 'px'}}>
                            <div className="col-3 p-0">
                                <div className="btn-group w-7" role="group" aria-label="action buttons">
                                    <button type="button" disabled={`${((checkedClipRow).length > 0) ? '' : 'disabled'}`} className={`btn btn-outline-primary btn-empty px-4 btn-sm ${((checkedClipRow).length === 0) ? 'disabled' : ''}`} onClick={() => deleteClipDocumentsSubject()} >
                                        <IDelete className={"padding-right-4px"} width="12" fill={` ${((checkedClipRow).length === 0) ? '#FFFFFF' : '#128186'}`} />&nbsp; Elimina</button>
                                </div>
                            </div>
                            <div className="col-6 p-0">
                                <h4 className="fw-bolder">DOCUMENTI ALLEGATI ALLA OPERAZIONE</h4>
                            </div>
                            <div className="col-2 p-0 ps-5 ms-4">
                                <div className="btn-group">
                                    <button type="button"
                                    disabled={props.disableInsert}
                                    className="btn btn-primary btn-new-rel px-5 btn-sm float-end" onClick={openShowImportModal}>
                                        <IPlus className={"padding-right-4px"} width="12" />&nbsp; AGGIUNGI DOCUMENTO
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row me-0 ms-0">
                            <div className="col-lg-12 ps-0 pe-0">

                                <div className="text-center header-table">
                                    <div className='col-sm-1 text-center div-td'>
                                        <input type="checkbox"
                                            id='checkboxAllClipId'
                                            onClick={e => onCheckClick(e)}
                                        />
                                    </div>
                                    <div className='col-lg-10' ><strong>DOCUMENTO</strong></div>
                                    <div className='col-lg-1' ><strong>DOWNLOAD</strong></div>
                                </div>
                            </div>
                        </div>
                        {renderRows()}
                    </div>
                </div>
                <div className="row ps-3 pt-4">
                <div className="col-5">
                    <button className="btn btn-outline-primary btn-empty px-4 btn-sm"
                        onClick={props.onHide}><IUndo className={"padding-right-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                </button>
                </div>
                <div className="col">
                    <button className={`btn btn-outline-primary btn-empty px-4 btn-sm ${((checkedClipRow).length === 0) && 'disabled'}`} disabled={`${((checkedClipRow).length > 0) ? '' : 'disabled'}`}
                        onClick={zipAllDocuments}><IZipFile className={"padding-right-4px svg"} width="16" fill={` ${((checkedClipRow).length === 0) ? '#FFFFFF' : '#128186'}`} />&nbsp; SCARICA TUTTI
                </button>

                </div>
                </div>
                {showImportModal &&
                <ArcoRegistryImportDocumentModal
                    show={showImportModal}
                    onHide={openShowImportModal} 
                    id={props.arcoRegistriesOperation.id}
                    getAllArcoDocumentsByOperationId={getAllArcoDocumentsByOperationId}
                    uncheckAll={uncheckAll}
                />}
            </div>
        </React.Fragment>
    );
}