import React, { useState } from "react";
import { ReactComponent as IUpload } from "../../../styles/images/svg/file-upload.svg";
import { ReactComponent as ICheck } from "../../../styles/images/svg/check.svg";
import { PopupError } from "../../shared/PopupError";
import { PopupSuccess } from "../../shared/PopupSuccess";
import { ReactComponent as ISearch } from "../../../styles/images/svg/search-solid.svg";
import * as actions from "../../../actions";
import { ReactComponent as IUndo } from "../../../styles/images/svgs/regular/times-circle.svg";
import getText from './labels';
import { useSelector } from 'react-redux';
import { BwmInput } from "../../shared/form/BwmInput";

export default function BlueFileImportModal(props) {
  const lang = useSelector(state => state.blueLanguage.language)
  const labels = getText(lang);
  const [dataFile, setDataFile] = useState([]);
  const [errorDataFile, setErrorDataFile] = useState({
    isValid: true,
    msg: "",
  });

  const [email, setEmail] = useState(props?.subject?.email);
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: "" })

  const checkFileName = (name) => {
    return name.split(".").pop().toLowerCase() === "csv" || name.split(".").pop().toLowerCase() === "txt";
  };


  const handleUploadFile = (val) => {
    if (!val) {
      setErrorDataFile({ isValid: false, msg: labels.FILEREQUIRED });
    }

    let files = [];
    for (let i = 0; i < val.target.files.length; i++) {
      if (!checkFileName(val.target.files[i].name)) {
        setErrorDataFile({
          isValid: false,
          msg: "Estensione o nome file errati",
        });
      } else {
        setErrorDataFile({ isValid: true, msg: "" });
        files.push(val.target.files[i]);
      }
    }
    if (files.length > 0) {
      setDataFile(files);
    }
  };

  const validateFormData = () => {
    let isValid = true;
    if (!email) {
      setErrorEmail({ isValid: false, msg: labels.REQUIREDFIELD });
      isValid = false;
    }
    if (dataFile?.length < 1) {
      setErrorDataFile({ isValid: false, msg: labels.FILEREQUIRED });
      isValid = false;
    }
    return isValid;
  }

  const handleSendDataFile = () => {
    if (!validateFormData()) {
      return;
    }
    let formData = new FormData();
    formData.append("email", email);
    formData.append("precision", props.precision);
    formData.append("language", lang);
    if (dataFile?.length === 1) {
      for (let i = 0; i < dataFile.length; i++) {
        formData.append("files", dataFile[i]);
      }
    } else {
      setErrorDataFile({
        isValid: false,
        msg: "È consentito un solo file CSV",
      });
      return;
    }


    actions.importBLUEFile(props?.subject?.id, formData).then(
      (res) => {
        if (!res.error) {
          PopupSuccess({
            title: labels.SUCCESSTITLE,
            text: labels.MASSIVEPOPUPDROPFILE[3],
            ok: labels.SUCCESSOK
          });
          props.closeModalExt();
        } else {
          setDataFile([]);
        }
      },
      (err) => {
        if (err?.errorKey === "massiveSearchNotAuthorized") {
          PopupError({
            text: labels.MASSIVESEARCHNOTAUTHORIZED,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          });
        } else if (err?.title) {
          PopupError({
            text: err.title,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          });
        } else {
          PopupError({
            text: labels.FILEERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          });
        }
      }
    )
  };


  const handleDownloadClick = () => {
    dowloadLocalDocument("blue_example.csv");
  };

  const handleinputEmail = (e) => {
    setEmail(e.target.value);
    setErrorEmail({ isValid: true, msg: "" });
  };


  const dowloadLocalDocument = (fileName) => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/docs/" + fileName;
    link.setAttribute("download", fileName);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="text-start">
          <h4>
            {labels.MASSIVEPOPUPTEXT[0]} <b>CSV</b> {labels.MASSIVEPOPUPTEXT[1]}
          </h4>
          <h6>
            {labels.MASSIVE100LIMIT}
          </h6>

          <a onClick={() => handleDownloadClick()} href="#">
            <b>{labels.MASSIVEPOPUPTEXT[2]}</b>
          </a>
          <div className="certificatiLoaded ps-0 ms-0 text-start">
            {!errorDataFile.isValid ? (
              <div className="certificatiLoaded-error">
                {errorDataFile.msg}
              </div>
            ) : (
              <div>
                {dataFile.length > 0 &&
                  dataFile.map((file, index) => {
                    return (
                      <div key={"blue-import" + { index }} className="green-label">
                        <ICheck width="14" fill="#128186" />
                        {file.name}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="form-group mt-5">
            <form>
              <BwmInput name='fiscalCode'
                label={labels.MASSIVEPOPUPEMAIL}
                placeholder={labels.MASSIVEPOPUPEMAIL}
                className='form-control'
                value={email}
                onChange={e => handleinputEmail(e)}
                error={errorEmail}
              />
            </form>
          </div>

          <div className="text-center">
            <div className="image-upload text-center">

              <label htmlFor={`dataFile`}>
                <div className="btn btn-primary btn-new-rel px-3">
                  <IUpload
                    className={"padding-right-4px svg"}
                    width="16"
                    fill={"#FFFFFF"}
                  /> {labels.UPLOADCSV}
                </div>
              </label>
              <input
                id={`dataFile`}
                type="file"
                name={`dataFile`}
                onChange={(e) => handleUploadFile(e)}
              />
            </div>

            <div className="mt-5 btngroup form-relationship-button col-lg-12 justify-content-between">
              <button onClick={() => props.closeModalExt()} className="btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm">
                <IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; {labels.CANCEL}
              </button>
              <button onClick={() => handleSendDataFile()} disabled={!errorDataFile.isValid || !dataFile} className="btn btn-primary w-45 btn-new-rel px-3">
                <ISearch className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />{labels.CONFIRM}
              </button>
            </div>
          </div>
        </div>
      </div>


    </React.Fragment>
  );
}
