import React from "react";
import { ReactComponent as IAnagrafica } from "../../../../styles/images/svg/id-card.svg";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustomB5";
import { BwmInput } from "../../../shared/form/BwmInputB5";
import { DateField } from "../../../shared/form/DateFieldB5";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import {IPlus, ISave, ITextFile, IUndo} from '../../../../styles/icons';
import * as Constants from "../../../../config/Constants";
import { BwmSelect } from "../../../shared/form/BwmSelectB5";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import { BtnFill } from "../../../shared/BtnFill";
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer';
import ErrorListAlert from "../../../shared/form/ErrorListAlert";
import {Formik, Field, Form} from 'formik';
import * as Yup from "yup";

export const CoraSubjectForm = (self) => {

  const nameApplication = Constants.APPLICATION_CORA;
  const id = self.props.subject?.id || 0;
  const personType = self.state.personType;

  return (
    <Formik
    initialValues={self.initialValues}
    validationSchema={self.validationSchema}
    onSubmit={(values) => self.handleFormSubmit(values)}
>
    {({handleChange, handleBlur, values, errors, touched, setValues, submitForm, validateForm}) => (
        <Form>

            {errors && typeof errors === 'object' && Object.keys(errors).length > 0 && (
                <ErrorListAlert errors={self.extractErrors(errors, self.formErrorsTabMapping)} />
            )}    

          <div className="row row-detail bg-gray pt-4 pe-3 pb-4 ps-3 d-flex">

            <div className={"col-6"}>
              <div className="soggetto-crs big">
                <div id={`anagrafica-${id}`}>
                  <div className="row">
                    <div className="col-md-1 mb-2 ms-4">
                      <div className="input-group mt-2 d-flex justify-content-between">
                        <div className="inputSwitcher me-5 form-check form-switch">
                          <label className="d-flex align-items-center">
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              id={`pType-switch-${id}`}
                              checked={personType === Constants.PF}
                              onChange={(e) => {
                                const newPersonType = e.target.checked ? Constants.PF : Constants.PNF;
                                self.handlePersonTypeClick({ target: { value: newPersonType } });
                              }}
                            />
                            {personType === Constants.PF ? "Persona fisica" : "Persona giuridica"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 me-2">
                      <Field name="fiscalCode">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            name="fiscalCode"
                            label="Codice Fiscale"
                            type="text"
                            className="form-control"
                            value={values.fiscalCode}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              handleChange({
                                target: {
                                  name,
                                  value: value.toUpperCase(),
                                },
                              });
                            }}
                            maxLength="16"
                            onBlur={(e) => self.handleFiscalCode(e.target.value, setValues, values)}
                            error={errors.fiscalCode}
                            touched={touched.fiscalCode}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-md-3 me-2">
                      <Field name="location">
                        {({ field }) => (
                          <AutoCompileCustom
                            {...field}
                            label={
                              personType === Constants.PNF
                                ? "Sede Legale (Comune)"
                                : "Luogo di nascita"
                            }
                            handleInputAutocompile={(e) => self.handleSelectLocation(e, setValues, values)}
                            filtro={self.props.customLocations}
                            value={values.location}
                            error={errors.location}
                            touched={touched.location}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="col-md-2">
                      <Field name="province">
                        {({ field }) => (
                          <BwmInput
                            {...field}
                            label="Provincia"
                            type="provincia"
                            className="form-control"
                            value={values.province}
                            error={errors.province}
                            touched={touched.province}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  
                  

                  <div className="row">
                    {personType === Constants.PNF ? (
                      <div className="col-md-11">
                        <Field name="companyName">
                          {({ field }) => (
                            <BwmInput
                              {...field}
                              label="Ragione Sociale"
                              className="form-control"
                              value={field.value}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                handleChange({
                                  target: {
                                    name,
                                    value: value.toUpperCase(),
                                  },
                                });
                              }}
                              error={errors.companyName}
                              touched={touched.companyName}
                              maxLength="60"
                            />
                          )}
                        </Field>
                      </div>
                    ) : (
                      <>
                        <div className="col-5 me-2">
                          <Field name="firstName">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                name="firstName"
                                label="Nome"
                                type="text"
                                className="form-control"
                                value={values.firstName}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase(),
                                    },
                                  });
                                }}
                                error={errors.firstName}
                                touched={touched.firstName}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-5">
                          <Field name="lastName">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="Cognome"
                                type="text"
                                className="form-control"
                                value={values.lastName}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase(),
                                    },
                                  });
                                }}
                                error={errors.lastName}
                                touched={touched.lastName}
                              />
                            )}
                          </Field>
                        </div>
                      </>
                    )}
                  </div>
                </div>


                <div
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    right: "100px",
                    fontSize: "15px",
                  }}
                  className="text-danger"
                >
                  {self.state.errorInConfigSid}
                </div>
              </div>
            </div>
            <div className={"col-6"}>
              <div className="configurazioneSID">
                <SidContactFormContainer  {...self} nameApplication={nameApplication} />
                {!self.modal && (
                  <div className="panel-info align-items-end">
                    <h5>Codice soggetto: {self.props.subject?.id}</h5>
                    {self.state.coraSubjectSid ? (
                      <h5>Codice SID: {self.state.coraSubjectSid?.id}</h5>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={"row row-detail"}>
              <div className={"col-12 mt-4"}>
              <BtnEmpty type="button" text="ANNULLA" classCustom="float-start" handlerOnClick={self.closeModal} />
              <button type="submit"
                      className="btn btn-primary btn-new-rel px-5 ms-auto"><ISave
                  className={"padding-right-4px svg"} width="20" fill={"#FFFFFF"}/>&nbsp; CONFERMA
              </button>
            </div>
          </div>
        </div>
      </Form>
      )
    }
  </Formik>   
  );
};
