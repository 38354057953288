import React from "react";
import * as actions from "../../../../actions";
import Modal from "react-bootstrap/Modal";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { PopupError } from "../../../shared/PopupError";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import Row from "react-bootstrap/Row";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import * as Constants from "../../../../config/Constants";
import { UploadFileLabel } from "../../../shared/form/UploadFileLabel";
import { checkReceiptSize } from "../../../shared/UploadReceipt";
import { Form } from "react-bootstrap";
import { isEmpty, isNotEmpty } from "../../../shared/Utility";
import { ReactComponent as ICheck } from "../../../../styles/images/svg/check.svg";
import ModalProcessingOutComeReceipt from "./ModalProcessingOutRecepts/ModalProcessingOutComeReceipt";


const CONSISTENCY = "CONSISTENCY";
const RECEIPT = "RECEIPT"
export class UploadReceiptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loadedFiles: [],
      errorReceipt: { isValid: true, msg: "" },
      loading: false,
      communication: [],
      receiptType: null,
      consistencyStartingYear: ""
    };
  }

  closeModal = () => {
    this.setState({ showModal: false, loadedFiles: [], communication: [] });
  };


  openModal = () => {
    this.setState({ showModal: true, loadedFiles: [], receiptType: null,consistencyStartingYear: "", communication: [] });
  };


  /**
   * verifica che tutti i file siano dello stesso tipo, e setta lo stato
   * @param {*} files 
   * @returns tipo modale CONSISTENCY o RECEIPT, o null se i file sono di tipo diverso
   */
  determineModalType = (files = []) => {
    let modalType = null;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (isAtpec05(file.name)) {
        if (modalType === RECEIPT) {
          this.setState({
            errorReceipt: {
              isValid: false,
              msg: "Selezionare un tipo file per volta",
            },
          });
          return null;
        } else {
          modalType = CONSISTENCY;
        }
      } else {
        if (modalType === CONSISTENCY) {
          this.setState({
            errorReceipt: {
              isValid: false,
              msg: "Selezionare un tipo file per volta",
            },
          });
          return null;
        } else {
          modalType = RECEIPT;
        }
      }
    }
    return modalType;
  }

  checkFileName = (file) => {
    let name = file.name;
    return Constants.ATPEC_REGEXP.test(name);
  };

  handleSendReceipt = () => {

    const loadedFiles = this.state.loadedFiles;
    const receiptType = this.state.receiptType;
    this.closeModal();
    this.setState({ loading: true});
    const formData = new FormData();
    for (var x = 0; x < loadedFiles.length; x++) {
      let file = loadedFiles[x];
      formData.append("receiptFile", file);
    }
    if (receiptType === RECEIPT) {
      actions.createCoraReceipt(formData).then(
        (communication) => {
          if (communication?.length > 0) {
            this.setState({
              communication: communication,
            });
          } else {
            PopupError({
              text: "Si è verificato un errore non gestito nell'elaborazione della ricevuta.",
            });
          }
        },
        (errors) => {
          PopupError({ text: errors });
        }
      ).then(()=>this.setState({loading: false}));
    } else if (this.state.receiptType === CONSISTENCY) {
      if (isNotEmpty(this.state.consistencyStartingYear)) {
        formData.append("startingYear", this.state.consistencyStartingYear)
      }
      actions.consistencyPhotoReceipt(formData).then(
        (communication) => {
          if (communication?.length > 0) {
              this.props.refreshSubjects();
              this.setState({
                communication: communication,
              });
          } else {
            PopupError({
              text: "Si è verificato un errore inatteso nell'elaborazione della fotografia.",
            });
          }
        },
        (errors) => {

          PopupError({
            text: "Si è verificato un errore inatteso nell'elaborazione della fotografia.",
          });
        }
      ).then(()=>this.setState({loading: false}));
    }
  };

  writeClearFile = (clearTxt) => {
    let lines = clearTxt.split("\r\n");
    return lines.map((line) => {
      return <Row className="alert-header">{line}</Row>;
    });
  };

  renderOptions = () => {
    const currYear = new Date().getFullYear() - 1;
    let options = [];
    options.push(<option key={`year-select-empty`} value={""}>Tutti</option>)
    for (let index = 0; index < 9; index++) {
      const year = currYear - index
      options.push(<option key={`year-select-${year}`} value={year}>{year}</option>)
    }
    return options;
  }


  onFileInput = (files) => {
    //valida nome, estensione e dimensioni files
    //popola stato, con oggetti di tipo {file, anno(se atpec05)}, sovrascrivendo duplicati
    let hasInvalidFile = false;
    let invalidFileName = '';
    let loadedFiles = [];
    let modalType = this.determineModalType(files);
    if (!modalType) {
      return;
    }

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (!this.checkFileName(file) || !checkReceiptSize(file)) {
        hasInvalidFile = true;
        invalidFileName = file.name;
      }
    }
    let error = { isValid: true, msg: "" };
    if (hasInvalidFile) {
      error = { isValid: false
        , msg: `Il file ${invalidFileName || ""} non è valido. Verificare estensione (.run, .p7m, .enc), nome (senza parentesi), dimensioni (max 5MB) e l'eventuale presenza di caratteri speciali nel nome del file: { } ( ) [ ] | ~ \` $ % & * + - = < > @ # ^ _ \\ / ; : '.` }
      modalType=null;//azzero il tipo modale
    } else {
      loadedFiles = files;
      error = { isValid: true, msg: "" };
    }
    this.setState({ errorReceipt: error, loadedFiles: loadedFiles, receiptType: modalType });
  }


  render() {
    let disabled = this.props.disabled;

    return (
      <React.Fragment>
        {this.state.loading && <PageSpinner />}

        <button
          type="button"
          className={`btn btn-empty btn-sm ${disabled ? "disabled" : ""}`}
          data-tip={
            "Caricare file RICEVUTA DI TRASMISSIONE (ATPEZC), FOTOGRAFIA (ATPEC05) e ESITI (ATPECXX)"
          }
          onClick={this.openModal}
          disabled={disabled}
        >
          <ITextFile
            className={"padding-right-4px svg"}
            width="18"
            fill={` ${disabled ? "#FFFFFF" : "#128186"}`}
          />
          &nbsp; Elabora file ricevuti
        </button>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.receiptType === CONSISTENCY &&
                <h2>ELABORA FOTOGRAFIE DI CONSISTENZA</h2>
              }
              {this.state.receiptType === RECEIPT &&
                <h2>ELABORA RICEVUTE ED ESITI</h2>
              }
              {isEmpty(this.state.receiptType) &&
                <h2>ELABORA FILE RICEVUTI</h2>
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column justify-content-between">
              <div className="image-upload text-start">
                <UploadFileLabel
                  label={"CARICA FILE RICEVUTO"}
                  forLabel={"receiptFile"}
                />
                <input
                  id={`receiptFile`}
                  type="file"
                  name={`receiptFile`}
                  multiple
                  accept=".run,.enc,.p7m"
                  onChange={(e) => this.onFileInput(e.target.files)}
                />
                <div className="certificatiLoaded text-start">
                  {!this.state.errorReceipt.isValid &&
                    <div className="certificatiLoaded-error">
                      {this.state.errorReceipt.msg}
                    </div>
                  }

                  {Array.from(this.state.loadedFiles).map((file, index) => {
                    return <div key={`file-row-${index}`}>
                      <FileRow
                        file={file}
                      />
                    </div>
                  })

                  }
                </div>
                {this.state.receiptType === CONSISTENCY &&
                  <>
                    <div className={`form-group d-flex mt-4 pt-4`}>
                      <label>Seleziona l'anno di inizio elaborazione: </label>
                      <Form.Control className='form-control ms-2' style={{ maxWidth: "4rem" }}
                        onChange={(e) => this.setState({ consistencyStartingYear: e.target.value })} as="select"
                        defaultValue={this.state.consistencyStartingYear}
                      >
                        {
                          this.renderOptions()
                        }
                      </Form.Control>
                    </div>
                    <div className="form-group" ><label>Se non si indica nulla verranno elaborati TUTTI i rapporti presenti nella fotografia</label></div>
                    <div className="form-group"><label>Per la selezione sarà letta la DATA DI INIZIO dei rapporti</label></div>
                  </>
                }
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty
                text="ANNULLA"
                classCustom="float-start"
                handlerOnClick={this.closeModal}
              />
              <button
                disabled={!this.state.errorReceipt.isValid || this.state.loadedFiles.length < 1}
                onClick={this.handleSendReceipt}
                type="button"
                className="btn btn-new-rel px-5"
              >
                CONFERMA
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {this.state.communication.map((communication, index) => {
          return (
            <ModalProcessingOutComeReceipt
              communication={communication.coraCommunication}
              key={communication.filename + "-" + index}
              fileName={communication.filename}
              subjectName={communication.coraCommunication?.subject?.completeName}
              receiptType={this.state.receiptType}
            />
          );
        })}
      </React.Fragment>
    );
  }
}


function FileRow({ file }) {

  return (

    <div className="d-flex green-label text-truncate align-items-center">

      <ICheck width="12" fill="#128186" />
      {file.name}
    </div>
  );
}

function isAtpec05(fileName = "") {
  return ["ATPEC05"].includes(fileName.toUpperCase().slice(0, 7));
}