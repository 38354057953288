import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

export function NumberOfResultsSelector(props) {

    const defaultOptions = [10, 20, 50, 100];
    const options = props.options || defaultOptions;
    const [selected, setSelected] = useState(props.selected || options[1]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    const handleChange = (e) => {
        props.onChange(e.target.value);
    }


    return (
        <React.Fragment>
            <Form.Select className="star-table-per-page-select" value={selected} aria-label="Default select example" onChange={handleChange}>
                {options.map((option) => (
                    <option value={option} key={option}>{option}</option>
                ))}
            </Form.Select>
        </React.Fragment>
    )



}