import React, {useEffect, useState} from 'react';
import {baseSearchObject, fieldTypes, TableUtils} from "../../../shared/tables/TableUtils";
import {StarTable} from "../../../shared/tables/StarTable";
import {searchComureiSubject} from "../../../../actions";
import CommunicationsHistoryModal from "../communications/CommunicationsHistoryModal";
import {useSelector} from "react-redux";
import {ComureiSubjectModal} from "./ComureiSubjectModal";
import ComureiForm from "./NewComureiForm";


export function ComureiSubjectList(props) {
    const [subjects, setSubjects] = useState();
    const [historyModal, setHistoryModal] = useState();
    const communicationTypes = useSelector(state => state.comureiCommunicationTypes.data);
    const [show] = useState(false);
    const [rows, setRows] = useState([]);
    const subjectTypes = useSelector(state => state.comureiSubjectTypes.data);
    const [searchObject, setSearchObject] = useState({
        ...baseSearchObject,
        subjectType: null,
        communicationType: null,
        denomination: null,
        fiscalCode: null,
        responsibleName: null,
        section: null
    })

    const [id, setId] = useState(null);
    const tableUtils = TableUtils;
    const tableName = "comurei-subject"


    const closeModal = () =>  {
        setId(undefined)
        setHistoryModal(false)
    }

    const headers = [
        tableUtils.composeHeader({
            fieldName: "subjectType",
            type: fieldTypes.SELECTION,
            options: subjectTypes,
            displayedName: "Tipo Soggetto",
        }),
        tableUtils.composeHeader({
            fieldName: "communicationType",
            type: fieldTypes.SELECTION,
            options: communicationTypes,
            displayedName: "Tipo Comunicazione",
        }),
        tableUtils.composeHeader({
            fieldName: "denomination",
            displayedName: "Soggetto Obbligato",
        }),
        tableUtils.composeHeader({
            fieldName: "fiscalCode",
            displayedName: "Codice Fiscale"
        }),
        tableUtils.composeHeader({
            fieldName: "responsibleName",
            displayedName: "Responsabile"
        }),
        tableUtils.composeHeader({
            fieldName: "section",
            sortable: false,
            searchable: false,
            displayedName: "Sezione"
        }),
        tableUtils.composeHeader({
            fieldName: "actions",
            sortable: false,
            searchable: false,
            maxWidth: '100px',
            additionalClass: 'justify-content-center',
            displayedName: "Storico"
        })
    ];

    const prepareRows = (newSubjects) => {
        return newSubjects.map(c => {
            return tableUtils.composeRow({
                    id: c.id,
                    cellData: {
                        subjectType: tableUtils.composeCell({
                            fieldName: "subjectType",
                            fieldValue: c.subjectType,
                        }),
                        communicationType: tableUtils.composeCell({
                            fieldName: "communicationType",
                            fieldValue: c.communicationType
                        }),
                        denomination: tableUtils.composeCell({
                            fieldName: "denomination",
                            fieldValue: c.denomination
                        }),
                        fiscalCode: tableUtils.composeCell({
                            fieldName: "fiscalCode",
                            fieldValue: c.fiscalCode
                        }),
                        responsibleName: tableUtils.composeCell({
                            fieldName: "responsibleName",
                            fieldValue: c.responsibleName
                        }),
                        section: tableUtils.composeCell({
                            fieldName: "section",
                            fieldValue: c.section
                        }),
                        actions: tableUtils.composeCell({
                            fieldName: "actions",
                            additionalClass: 'd-flex justify-content-center',
                            component: () => {
                                return <div className="d-flex justify-content-center p-0">
                                    <CommunicationsHistoryModal
                                        subjectId={c.id}
                                        subjectName={c.denomination}
                                        setHistoryModal={setHistoryModal}
                                        closeModal={closeModal}
                                    />
                                </div>
                            }
                        }),
                    },
                }
            )


        })

    }

    const reloadRows = (searchObject) => {
        searchComureiSubject(searchObject).then(
            (res) => {
                setSubjects(res.content||res.data)
            }
        )
    }


    useEffect(() => {
        let newSubjects = subjects ? subjects : props.subjects;
        setRows(prepareRows(newSubjects));
        setId(newSubjects?.[0]?.id)
    }, [subjects])

    const renderTable = () => {
        return (
            <StarTable
                headerComponent={props.headerComponent}
                headerColums={headers}
                rows={rows}
                isLoading={show}
                tableConfigurationKey={tableName}
                searchObjectPrototype={searchObject}
                setSearchObject={setSearchObject}
                searchCallBack={(searchObject) => searchComureiSubject(searchObject)}
                onExecutedSearch={content => setSubjects(content)}
                usePagination={true}
                setId={setId}
                formComponent={ComureiForm}
                formComponentProps={{
                    subjectId: id,
                    locations: props.locations,       // Usa i due punti ":" non "="
                    subjectTypes: props.subjectTypes, // Props dinamiche
                    closeModal: closeModal,        // Funzione per chiudere il modal
                    getSubjects:() => { props.reloadList(); reloadRows(searchObject) }
                }}
                // dialogComponent={
                //     id !== null && id !== undefined && !historyModal &&
                //     <ComureiSubjectModal
                //         id={id}
                //         hideButton={true}
                //         locations={props.locations}
                //         subjectTypes={subjectTypes}
                //         getSubjects={() => { props.reloadList(); reloadRows(searchObject) }}
                //         closeModal={setId}
                //     />
                // }
            />

        )
    }

    return (
        <React.Fragment >
            {subjectTypes?.length > 0 && renderTable()}
        </React.Fragment>
    )
}

