import React from 'react';
import checkbox from "../../../styles/images/checkbox-inactive.png";
import checkboxSel from "../../../styles/images/checkbox-selected.png";

export const BwmCheckbox = ({
    label,
    name,
    checked,
    error,
    disabled,
    onChange, // Use onChange instead of onClick
}) => (
    <div>
        <div className='input-group'>
            <input
                hidden={true}
                type="checkbox"
                className="form-check-input"
                name={name}
                id={name}
                checked={checked} // Control checked state via Formik
                onChange={onChange} // Use onChange to toggle
                disabled={disabled}
            />
            <label className="form-check-label" htmlFor={name}>
                <img width="20" className="me-1" src={checked ? checkboxSel : checkbox} alt="checkbox" />
                {label}
            </label>
        </div>
        {error && !error.isValid && (
            <div className="text-danger">{error.msg}</div>
        )}
    </div>
);
