import React from "react";
import { IUpload } from "../../../styles/icons";
export function UploadFileLabel(props) {

    return (
        <React.Fragment>
            <label htmlFor={props.forLabel }>
                <div className={props.altDivClass || "btn btn-primary btn-new-rel px-3"}>
                    <IUpload className={props.altIconClass ||"padding-right-4px svg me-1"}
                        width={props.altWidth || "16"}
                        fill={"#FFFFFF"} />{props.label}
                </div>
            </label>
        </React.Fragment>
    )

}