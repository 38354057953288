import React, { useState } from "react";
import { ReactComponent as IComunicazioni } from "../../../../styles/images/svg/file-archive.svg";
import { PopupError } from "../../PopupError";
import { PopupSuccess } from "../../PopupSuccess";
import { saveSid } from "../../SidCertsHandler";
import { isEmpty, isNotEmpty } from "../../Utility";
import { BwmCheckbox } from "../BwmCheckbox";
import { BwmInput } from "../BwmInputB5";
import * as Constants from "../../../../config/Constants"
import { ParametriPecModal } from "./ParametriPecModal";
import { PopupAlert } from "../../PopupAlert";
import { PopupConfirm, titleColors } from "../../PopupConfirm";
function ContactForm(self) {

  const [isPecModalOpen, setPecModalOpen] = useState(false);

  const subjectFiscalCode = self.state?.subject?.fiscalCode || self.state?.fiscalCode;


  const closePecModal = () => {
    setPecModalOpen(false);
  }
  let email = self.state.coraSubjectSid?.email ? self.state.coraSubjectSid?.email : self.state.defaultEmail;


  const onSaveMailParameters = (subjectSid) => {
    self.updatePecConfiguration(subjectSid);

    if (isNotEmpty(subjectSid?.id)) {
      saveSid(undefined
        , subjectSid
        , subjectSid.fiscalCode
        , subjectSid.application
        , () => {
          PopupSuccess()
          closePecModal()
        }
        , (errors) => PopupError({ text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR }))
    } else {
      closePecModal();
    }

  }


  return (
    <div >
      <div className="row pt-3">
        <div className="col-md-3">
          <h5 >
            <IComunicazioni fill="#000" width="18" height="17" />
            Comunicazioni
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 mt-2">
          <div className="form-group" data-tip={"Indirizzo email del soggetto"}>
            <BwmInput
              className={"form-control"}
              label={`Indirizzo email a cui ricevere quanto generato da ${self.nameApplication ? self.nameApplication.toUpperCase() : "CORA"}`}
              name="email"
              error={self.state.errorEmail}
              value={email}
              onChange={(e) =>
                self.handleChangeSid(
                  e,
                  self.state.errorEmail,
                  "Indirizzo email non corretto"
                )
              }
            />
            <p className={"text-x-small"}>
              Da questo indirizzo si farà l'inoltro all'Agenzia (Sid1) dalla PEC
              del soggetto
            </p>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <BwmCheckbox
            name={`flagPec_${self.state.coraSubjectSid?.id}`}
            label="Selezionare in caso di email PEC"
            className="form-control"
            value={
              self.state.coraSubjectSid?.flagPec
                ? self.state.coraSubjectSid?.flagPec
                : false
            }
            error={self.state.errorFlagPec}
            onClick={(e) => self.handlerChangeFlagPec(e)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5  d-flex justify-content-left align-self-center">
          <button
            style={{ flexGrow: 1 }}
            className={`btn btn-primary btn-new-rel px-3`}
            onClick={() => {
              if (isEmpty(self.state.coraSubjectSid?.id)) {
                PopupConfirm({
                  titleColor: titleColors.ALERT,
                  text: "Per configurare la gestione automatica della pec devono essere correttamente caricati i certificati, la relativa password e il numero SID",
                  showCancelButton: false
                })
              } else {
                setPecModalOpen(true)
              }

            }}>
            Configura gestione automatica PEC
          </button>

        </div>
      </div>

      <ParametriPecModal
        coraSubjectSid={self.state.coraSubjectSid}
        show={isPecModalOpen}
        onClose={closePecModal}
        afterSave={(subjectSid) => onSaveMailParameters(subjectSid)}
      />

    </div>
  );
}

export default ContactForm;
