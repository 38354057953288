import React, { useEffect, useState } from 'react';
import { reduxForm } from "redux-form";
import Tabs from "../../application-cora/relationships/Tabs";
import { BwmSelect } from "../../../shared/form/BwmSelectB5";
import { BwmInput } from "../../../shared/form/BwmInputB5";
import * as actions from "../../../../actions";
import ErrorListAlert from "../../../shared/form/ErrorListAlert";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustomB5";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import {IPlus, ISave, ITextFile, IUndo} from '../../../../styles/icons';
import { DateField } from "../../../shared/form/DateFieldB5";
import * as Constants from "../../../../config/Constants";
import { BwmCheckbox } from "../../../shared/form/BwmCheckboxB5";
import { ReactComponent as IInfo } from '../../../../styles/images/svg/info-circle.svg';
import '../../../../styles/comurei-form.css';
import CodiceFiscale from "codice-fiscale-js";
import { PopupError } from "../../../shared/PopupError";
import * as Utility from "../../../shared/Utility"
import { Row } from 'react-bootstrap';
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm';
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense';
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker';
import { ProvinceField } from "../../application-cora/relationships/ProvinceFieldB5";
import {Formik, Field, Form} from 'formik';
import FormCheck from 'react-bootstrap/FormCheck'
import * as Yup from "yup";
import {getComureiSubjectDetail} from "../../../../actions";


const ComureiForm = props => {
    const [subjectSummary, setSubjectSummary] = useState({});
    const [subjectItem, setSubjectItem] = useState(undefined);
    const [tabError, setTabError] = useState({ subject: true, responsible: true, pec: true, canc: true });
    const [activeTab, setActiveTab] = useState("tab-sub");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showErrorAlert, setShowErrorAlert] = useState(false) 

    const setTab = (tab) => {
        setActiveTab(tab)
    }

    const retrieveSubject = (id) => {
        if (id) {
            getComureiSubjectDetail(id).then((response) => {
                setSubjectItem(response.data)
                setNewInitialValues()
            })
        } else {
            setSubjectItem(undefined)
            setNewInitialValues()
        }
    }

    const setNewInitialValues = () => {
        initialValues = {
                id: subjectItem?.id || null,
                fiscalCode: subjectItem?.fiscalCode || '',
                companyName: subjectItem?.companyName || '',
                location: subjectItem?.location || '',
                province: subjectItem?.province || '',
                registryReason: (subjectItem && subjectItem.registryReason !== null) ? subjectItem.registryReason : true,
                monitoringReason: subjectItem?.monitoringReason || false,
                factaReason: subjectItem?.factaReason || false,
                birthDate: subjectItem?.birthDate ? new Date(subjectItem?.birthDate) : null,
                firstName: subjectItem?.firstName || '',
                lastName: subjectItem?.lastName || '',
                centralizedStructure: subjectItem?.centralizedStructure || false,
                gender: subjectItem?.gender || ((subjectItem?.personType || Constants.PNF) === Constants.PF ? Constants.genderList[0].id : null),
                personType: subjectItem?.personType || Constants.PNF,
                comureiCommunicationType: {
                    id: subjectItem?.comureiCommunicationType.id.toString() || '1',
                },
                comureiSubjectType: {
                    id: subjectItem?.comureiSubjectType?.id || '',
                    code: subjectItem?.comureiSubjectType?.code || ''
                },
                comureiSubjectResponsible: {
                    id: subjectItem?.comureiSubjectResponsible?.id || null,
                    fiscalCode: subjectItem?.comureiSubjectResponsible?.fiscalCode || '',
                    firstName: subjectItem?.comureiSubjectResponsible?.firstName || '',
                    lastName: subjectItem?.comureiSubjectResponsible?.lastName || '',
                    gender: subjectItem?.comureiSubjectResponsible?.gender || 'FEMALE',
                    birthDate: subjectItem?.comureiSubjectResponsible?.birthDate ? new Date(subjectItem?.comureiSubjectResponsible?.birthDate) : null,
                    location: subjectItem?.comureiSubjectResponsible?.location || '',
                    province: subjectItem?.comureiSubjectResponsible?.province || '',
                    email: subjectItem?.comureiSubjectResponsible?.email || (props.user?.login || ''),
                    phone: subjectItem?.comureiSubjectResponsible?.phone || ''
                },
                comureiSubjectPec: {
                    id: subjectItem?.comureiSubjectPec?.id || null,
                    pecEmail: subjectItem?.comureiSubjectPec?.pecEmail || '',
                    pecManager: subjectItem?.comureiSubjectPec?.pecManager || '',
                    effectiveDate: subjectItem?.comureiSubjectPec?.effectiveDate ? new Date(subjectItem.comureiSubjectPec.effectiveDate) : '',
                    endDate: subjectItem?.comureiSubjectPec?.endDate ? new Date(subjectItem.comureiSubjectPec.endDate) : '',
                    reiEmail: subjectItem?.comureiSubjectPec?.reiEmail || ''
                },
                comureiSubjectCancellation: {
                    id: subjectItem?.comureiSubjectCancellation?.id || null,
                    cancellationDate: subjectItem?.comureiSubjectCancellation?.cancellationDate ? new Date(subjectItem.comureiSubjectCancellation.cancellationDate) : null,
                    address: subjectItem?.comureiSubjectCancellation?.address || '',
                    incorporatingFiscalCodeOne: subjectItem?.comureiSubjectCancellation?.incorporatingFiscalCodeOne || '',
                    incorporatingFiscalCodeTwo: subjectItem?.comureiSubjectCancellation?.incorporatingFiscalCodeTwo || '',
                    incorporatingFiscalCodeThree: subjectItem?.comureiSubjectCancellation?.incorporatingFiscalCodeThree || '',
                    location: subjectItem?.comureiSubjectCancellation?.location || '',
                    province: subjectItem?.comureiSubjectCancellation?.province || '',
                    comureiSubjectCancellationType: {
                        id: subjectItem?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1',
                        code: subjectItem?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1'
                    }
                },
                comureiTransmission: {
                    id: subjectItem?.comureiTransmission?.id || null,
                    fiscalCode: subjectItem?.comureiTransmission?.fiscalCode || '',
                    comureiTransmissionType: { id: subjectItem?.comureiTransmission?.comureiTransmissionType.id.toString() || '1' }
                }
            }    
    }
   

    var initialValues = {
        id: subjectItem?.id || null,
        fiscalCode: subjectItem?.fiscalCode || '',
        companyName: subjectItem?.companyName || '',
        location: subjectItem?.location || '',
        province: subjectItem?.province || '',
        registryReason: (subjectItem && subjectItem.registryReason !== null) ? subjectItem.registryReason : true,
        monitoringReason: subjectItem?.monitoringReason || false,
        factaReason: subjectItem?.factaReason || false,
        birthDate: subjectItem?.birthDate ? new Date(subjectItem?.birthDate) : null,
        firstName: subjectItem?.firstName || '',
        lastName: subjectItem?.lastName || '',
        centralizedStructure: subjectItem?.centralizedStructure || false,
        gender: subjectItem?.gender || ((subjectItem?.personType || Constants.PNF) === Constants.PF ? Constants.genderList[0].id : null),
        personType: subjectItem?.personType || Constants.PNF,
        comureiCommunicationType: {
            id: subjectItem?.comureiCommunicationType.id.toString() || '1',
        },
        comureiSubjectType: {
            id: subjectItem?.comureiSubjectType?.id || '',
            code: subjectItem?.comureiSubjectType?.code || ''
        },
        comureiSubjectResponsible: {
            id: subjectItem?.comureiSubjectResponsible?.id || null,
            fiscalCode: subjectItem?.comureiSubjectResponsible?.fiscalCode || '',
            firstName: subjectItem?.comureiSubjectResponsible?.firstName || '',
            lastName: subjectItem?.comureiSubjectResponsible?.lastName || '',
            gender: subjectItem?.comureiSubjectResponsible?.gender || 'FEMALE',
            birthDate: subjectItem?.comureiSubjectResponsible?.birthDate ? new Date(subjectItem?.comureiSubjectResponsible?.birthDate) : null,
            location: subjectItem?.comureiSubjectResponsible?.location || '',
            province: subjectItem?.comureiSubjectResponsible?.province || '',
            email: subjectItem?.comureiSubjectResponsible?.email || (props.user?.login || ''),
            phone: subjectItem?.comureiSubjectResponsible?.phone || ''
        },
        comureiSubjectPec: {
            id: subjectItem?.comureiSubjectPec?.id || null,
            pecEmail: subjectItem?.comureiSubjectPec?.pecEmail || '',
            pecManager: subjectItem?.comureiSubjectPec?.pecManager || '',
            effectiveDate: subjectItem?.comureiSubjectPec?.effectiveDate ? new Date(subjectItem.comureiSubjectPec.effectiveDate) : '',
            endDate: subjectItem?.comureiSubjectPec?.endDate ? new Date(subjectItem.comureiSubjectPec.endDate) : '',
            reiEmail: subjectItem?.comureiSubjectPec?.reiEmail || ''
        },
        comureiSubjectCancellation: {
            id: subjectItem?.comureiSubjectCancellation?.id || null,
            cancellationDate: subjectItem?.comureiSubjectCancellation?.cancellationDate ? new Date(subjectItem.comureiSubjectCancellation.cancellationDate) : null,
            address: subjectItem?.comureiSubjectCancellation?.address || '',
            incorporatingFiscalCodeOne: subjectItem?.comureiSubjectCancellation?.incorporatingFiscalCodeOne || '',
            incorporatingFiscalCodeTwo: subjectItem?.comureiSubjectCancellation?.incorporatingFiscalCodeTwo || '',
            incorporatingFiscalCodeThree: subjectItem?.comureiSubjectCancellation?.incorporatingFiscalCodeThree || '',
            location: subjectItem?.comureiSubjectCancellation?.location || '',
            province: subjectItem?.comureiSubjectCancellation?.province || '',
            comureiSubjectCancellationType: {
                id: subjectItem?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1',
                code: subjectItem?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1'
            }
        },
        comureiTransmission: {
            id: subjectItem?.comureiTransmission?.id || null,
            fiscalCode: subjectItem?.comureiTransmission?.fiscalCode || '',
            comureiTransmissionType: { id: subjectItem?.comureiTransmission?.comureiTransmissionType.id.toString() || '1' }
        }
    }

    useEffect(() => {
        retrieveSubject(props.subjectId)
    }, [props.subjectId])

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth); // Aggiorna la larghezza ogni volta che la finestra viene ridimensionata
        };
    
        // Aggiungi l'evento resize per aggiornare la larghezza
        window.addEventListener('resize', handleResize);
    
        // Pulisci l'evento quando il componente viene smontato
        return () => window.removeEventListener('resize', handleResize);
      }, [])

    const setTabWithError = (tabDict) =>  {
        const tabList = Object.entries(tabDict)
        .filter(([key, value]) => value === true) // Filtra solo dove il valore è true
        .map(([key]) => key); // Mappa solo le chiavi

        document.querySelectorAll('.tab-list-item').forEach(item => {
            item.firstChild.classList.remove('text-danger');
        });
        tabList.forEach((tabId)=>{
            // Add 'border-danger' to the element with id 'test'
            document.getElementById(tabId).firstChild.classList.add('text-danger');
        })
    }



    const validationSchema = Yup.object().shape({
        personType: Yup.string().required('Selezionare il tipo di persona'),
        companyName: Yup.string().when('personType', {
            is: (val) => val === Constants.PNF,
            then: schema => schema.required('Campo obbligatorio'),
            otherwise: schema => schema.notRequired()
        }),
        firstName: Yup.string().when('personType', {
            is: (val) => val === Constants.PF,
            then: schema => schema.required('Campo obbligatorio'),
            otherwise: schema => schema.notRequired()
        }),
        lastName: Yup.string().when('personType', {
            is: (val) => val === Constants.PF,
            then: schema => schema.required('Campo obbligatorio'),
            otherwise: schema => schema.notRequired()
        }),
        gender: Yup.string().when('personType', {
            is: (val) => val === Constants.PF,
            then: schema => schema.required('Campo obbligatorio'),
            otherwise: schema => schema.notRequired()
        }),
        birthDate: Yup.date().when('personType', {
            is: (val) => val === Constants.PF,
            then: schema => schema.required('Campo obbligatorio'),
            otherwise: schema => schema.notRequired()
        }),
        fiscalCode: Yup.string().required('Campo obbligatorio'),
        location: Yup.string().required('Campo obbligatorio'),
        province: Yup.string().required('Campo obbligatorio'),
        comureiSubjectType: Yup.object().shape({
            id: Yup.string().required('Campo obbligatorio')
                .when(['monitoringReason', 'registryReason', 'factaReason'], {
                    is: (monitoringReason, registryReason, factaReason) => !monitoringReason && !registryReason && !factaReason,
                    then: schema => schema.required('Selezionare almeno una tipologia')
                })
                .when('subjectType', {
                    is: (val) => val?.toString() === '5',
                    then: schema => schema.notOneOf(['5'], 'Monitoraggio fiscale non può essere selezionato')
                })
                .when('subjectType', {
                    is: (val) => val?.toString() === '29',
                    then: schema => schema.notOneOf(['29'], 'Il tipo soggetto 29 non può essere iscritto alla sezione INDAGINI BANCARIE. In caso di Holding da iscrivere per la comunicazione Anagrafe Rapporti scegliere il tipo 05')
                }),
            code: Yup.string()

        }),
        comureiSubjectResponsible: Yup.object().shape({
            id: Yup.string().required('Campo obbligatorio'),
            fiscalCode: Yup.string().required('Campo obbligatorio'),
            firstName: Yup.string().required('Campo obbligatorio'),
            lastName: Yup.string().required('Campo obbligatorio'),
            gender: Yup.string().required('Campo obbligatorio'),
            birthDate: Yup.date().required('Campo obbligatorio'),
            location: Yup.string().required('Campo obbligatorio'),
            province: Yup.string().required('Campo obbligatorio'),
            email: Yup.string().email('Formato email non valido').required('Campo obbligatorio'),
            phone: Yup.string().required('Campo obbligatorio'),
        }),
        //
        comureiSubjectPec: Yup.object().shape({
            id: Yup.string().required('Campo obbligatorio'),
            pecEmail: Yup.string().when('comureiCommunicationType', {
                is: comureiCommunicationType => comureiCommunicationType?.id !== '2' && comureiCommunicationType?.id !== '3',
                then: schema => schema.email('Formato email non valido').required('Campo obbligatorio')
            }),
            pecManager: Yup.string().when('comureiCommunicationType', {
                is: comureiCommunicationType => comureiCommunicationType?.id !== '2',
                then: schema => schema.required('Campo obbligatorio')
            }),
            effectiveDate: Yup.string().required('Campo obbligatorio'),
            endDate: Yup.date()
                .nullable()
                .when('effectiveDate', (effectiveDate, schema) => {
                    return schema.test({
                        test: endDate => {
                            return !(endDate && endDate < effectiveDate);
                        },
                        message: 'La data fine non può essere minore della data inizio',
                    });
                }),
            reiEmail: Yup.string().email('Formato email non valido').required('Campo obbligatorio')
        }),

        comureiSubjectCancellation: Yup.object().shape({
            id: Yup.string().when('comureiCommunicationType', {
                is: comureiCommunicationType => comureiCommunicationType?.id?.toString() === '2',
                then: schema => schema.required('Campo obbligatorio')
            }),
            cancellationDate: Yup.string().when('comureiCommunicationType', {
                is: comureiCommunicationType => comureiCommunicationType?.id?.toString() === '2',
                then: schema => schema.required('Campo obbligatorio')
            }),
            address: Yup.string().when(['comureiCommunicationType', 'comureiSubjectCancellation'], {
                is: (comureiCommunicationType, comureiSubjectCancellation) => {
                    return (
                        comureiCommunicationType?.id === '2' &&
                        (['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id))
                    )
                },
                then: schema => schema.required('Campo obbligatorio')
            }),
            location: Yup.string().when(['comureiCommunicationType.id', 'comureiSubjectCancellation'], {
                is: (comureiCommunicationType, comureiSubjectCancellation) => {
                    return (
                        comureiCommunicationType?.id === '2' &&
                        (['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id))
                    )
                },
                then: schema => schema.required('Campo obbligatorio')
            }),
            province: Yup.string().when(['comureiCommunicationType.id', 'comureiSubjectCancellation'], {
                is: (comureiCommunicationType, comureiSubjectCancellation) => {
                    return (
                        comureiCommunicationType?.id === '2' &&
                        (['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id))
                    )
                },
                then: schema => schema.required('Campo obbligatorio')
            }),
            incorporatingFiscalCodeOne: Yup.string()
                .when(['comureiCommunicationType', 'comureiSubjectCancellation'], {
                    is: (comureiCommunicationType, comureiSubjectCancellation) => {
                        if (
                            comureiCommunicationType?.id?.toString() === '2' &&
                            comureiSubjectCancellation?.comureiSubjectCancellationType?.id?.toString() === '1'
                        ) return true
                        return checkIncorporatingFiscalCodeOne(comureiCommunicationType, comureiSubjectCancellation)
                    },
                    then: schema => schema.required('Campo obbligatorio')
                }),
            incorporatingFiscalCodeTwo: Yup.string()
                .when(['comureiCommunicationType', 'comureiSubjectCancellation'], {
                    is: (comureiCommunicationType, comureiSubjectCancellation) => {
                        return checkIncorporatingFiscalCodeOne(comureiCommunicationType, comureiSubjectCancellation)
                    },
                    then: schema => schema.required('Campo obbligatorio')
                }),
            incorporatingFiscalCodeThree: Yup.string()
                .when(['comureiCommunicationType', 'comureiSubjectCancellation'], {
                    is: (comureiCommunicationType, comureiSubjectCancellation) => {
                        return checkIncorporatingFiscalCodeOne(comureiCommunicationType, comureiSubjectCancellation)
                    },
                    then: schema => schema.required('Campo obbligatorio')
                }),
            comureiSubjectCancellationType: Yup.object().shape({
                id: Yup.string().when('comureiSubjectCancellation', {
                    is: comureiSubjectCancellation => !!comureiSubjectCancellation?.comureiSubjectCancellationType?.id,
                    then: schema => schema.required('Campo obbligatorio')
                }),
                code: Yup.string().when('comureiSubjectCancellation', {
                    is: comureiSubjectCancellation => !!comureiSubjectCancellation?.comureiSubjectCancellationType?.id,
                    then: schema => schema.required('Campo obbligatorio')
                })
            })
        })
    })

    const formErrorsTabMapping = {
        "tab-sub": [
            { 
                errorKey: 'fiscalCode',
                errorLabel: 'Codice Fiscale'
            },
            { 
                errorKey: 'companyName',
                errorLabel: 'Ragione Sociale'
            },
            { 
                errorKey: 'location',
                errorLabel: 'Sede legale'
            },
            { 
                errorKey: 'province',
                errorLabel: 'Provincia'
            },
            { 
                errorKey: 'firstName',
                errorLabel: 'Nome'
            },
            { 
                errorKey: 'lastName',
                errorLabel: 'Cognome'
            },
            { 
                errorKey: 'birthDate',
                errorLabel: 'Data di nascita'
            },
            { 
                errorKey: 'gender',
                errorLabel: 'Sesso'
            },
        ],
        "tab-resp": [
            { 
                errorKey: 'comureiSubjectResponsible.fiscalCode',
                errorLabel: 'Codice Fiscale'
            },
            { 
                errorKey: 'comureiSubjectResponsible.firstName',
                errorLabel: 'Nome'
            },
            { 
                errorKey: 'comureiSubjectResponsible.lastName',
                errorLabel: 'Cognome'
            },
            { 
                errorKey: 'comureiSubjectResponsible.gender',
                errorLabel: 'Sesso'
            },   
            { 
                errorKey: 'comureiSubjectResponsible.birthDate',
                errorLabel: 'Data di nascita'
            },
            { 
                errorKey: 'comureiSubjectResponsible.location',
                errorLabel: 'Comune di nascita'
            },               
            { 
                errorKey: 'comureiSubjectResponsible.province',
                errorLabel: 'Provincia'
            },
            { 
                errorKey: 'comureiSubjectResponsible.email',
                errorLabel: 'Email'
            },
            { 
                errorKey: 'comureiSubjectResponsible.phone',
                errorLabel: 'Telofono'
            }     
        ],
        "tab-pec": [
            { 
                errorKey: 'comureiSubjectPec.pecEmail',
                errorLabel: 'Posta Elettronica'
            },
            { 
                errorKey: 'comureiSubjectPec.pecManager',
                errorLabel: 'Gestore PEC'
            },
            { 
                errorKey: 'comureiSubjectPec.effectiveDate',
                errorLabel: 'Data DECORRENZA'
            },
            { 
                errorKey: 'comureiSubjectPec.endDate',
                errorLabel: 'Data CESSAZIONE'
            },     
            { 
                errorKey: 'comureiSubjectPec.pecEmailAddress',
                errorLabel: 'Indirizzo PEC ultima pecEmailAddress'
            },           
        ],
        "tab-delete": [],
        "tab-transmission": [],
    }

    const formTabsWithErrors = {
        "tab-sub": false,
        "tab-resp": false,
        "tab-pec": false,
        "tab-delete": false,
        "tab-transmission": false,
    }

    const extractErrors= (errorObj, fields) => {
        const result = [];
      
        // Helper function to access nested fields using dot notation
        const getNestedField = (obj, fieldPath) => {
          return fieldPath.split('.').reduce((o, key) => (o && o[key] !== undefined) ? o[key] : null, obj);
        };
      
        // Iterate over each field and extract errors
        fields.forEach(({ errorKey, errorLabel }) => {
          const error = getNestedField(errorObj, errorKey);
          if (error) {
            result.push({ errorKey, errorLabel, errorMessage: error });
          }
        });
      
        return result;
      };

    const getActivePropsAndTab = (errors) => {

        var tabsWithErrors = findTabsWithErrors(errors, formTabsWithErrors, formErrorsTabMapping);
        setTabWithError(tabsWithErrors)    

        var activeErrorsLabels = undefined
        if (activeTab) {
            activeErrorsLabels = formErrorsTabMapping[activeTab]
        }
         
        if (activeErrorsLabels) {
            errors = extractErrors(errors, activeErrorsLabels)
        }

        return errors
    }

    // Funzione per cercare i tab con errori
    const findTabsWithErrors = (errors, formTabsWithErrors, formErrorsTabMapping) => {
        const updatedTabsWithErrors = { ...formTabsWithErrors }; // Clone the initial state
    
        // Helper function to check recursively if a field exists in errors (including nested objects)
        const checkFieldInErrors = (errors, fieldKey) => {
            const keys = fieldKey.split('.'); // Handle nested keys, e.g., comureiSubjectResponsible.fiscalCode
            let current = errors;
    
            // Traverse through nested objects
            for (let key of keys) {
                if (current[key]) {
                    current = current[key]; // Dive deeper if the key exists
                } else {
                    return false; // If any key is not found, return false
                }
            }
            return true; // If all keys are found, return true
        };
    
        // Iterate over each tab in the mapping
        for (let tab in formErrorsTabMapping) {
            const fields = formErrorsTabMapping[tab];
    
            // Check if at least one field in this tab has an error
            const hasError = fields.some(({ errorKey }) => checkFieldInErrors(errors, errorKey));
    
            // Update the tab status based on whether it has an error
            updatedTabsWithErrors[tab] = hasError;
        }
    
        return updatedTabsWithErrors;
    };

    useEffect(() => {
        if (!tabError.subject) {
            if (!document.getElementById('tab-sub').classList.contains('active')) {
                document.getElementById('tab-sub').click()
            }
        } else if (!tabError.responsible) {
            if (!document.getElementById('tab-resp').classList.contains('active')) {
                document.getElementById('tab-resp').click()
            }
        } else if (!tabError.pec) {
            if (!document.getElementById('tab-pec').classList.contains('active')) {
                document.getElementById('tab-pec').click()
            }
        } else if (!tabError.canc) {
            if (!document.getElementById('tab-delete').classList.contains('active')) {
                document.getElementById('tab-delete').click()
            }
        }
    }, [tabError]);

    useEffect(() => {
        checkRemainingSubjects();
    }, []);

    const checkIncorporatingFiscalCodeOne = (comureiCommunicationType, comureiSubjectCancellation = {}) => {
        const cancellationReason = comureiSubjectCancellation?.comureiSubjectCancellationType?.id
        if (comureiCommunicationType?.id?.toString() === '2') {
            if (cancellationReason?.string() === '5') {
                return (
                    (!comureiSubjectCancellation.incorporatingFiscalCodeOne && !comureiSubjectCancellation.incorporatingFiscalCodeTwo) ||
                    (!comureiSubjectCancellation.incorporatingFiscalCodeOne && !comureiSubjectCancellation.incorporatingFiscalCodeThree) ||
                    (!comureiSubjectCancellation.incorporatingFiscalCodeTwo && !comureiSubjectCancellation.incorporatingFiscalCodeThree)
                )
            }
        }
        return false
    }

    const checkRemainingSubjects = () => {
        CheckRemainingSubjects(Constants.APPLICATION_COMUREI, false
            , (subjectSummary) => {
                setSubjectSummary(subjectSummary);
            });
    }

    const addComureiSubject = (newSubject, sendComm) => {
        actions.addComureiSubject(newSubject).then(
            (response) => {
                if (sendComm) {
                    createCommunication(response);
                    props.getSubjects();
                } else {

                    PopupConfirm({
                        text: "DATI SALVATI, SI DESIDERA CREARE LA COMUNICAZIONE?",
                        confirm: "CREA COMUNICAZIONE",
                        cancel: "CHIUDI",
                        titleColor: titleColors.SUCCESS,
                        title: "Soggetto salvato correttamente",
                        handleClickConfirm: () => createCommunication(response)
                    })

                }

                props.getSubjects();
            },
            (errors) => {
                if (errors?.status === 511) {
                    PopupSubjectLicense({ total: subjectSummary.total, used: subjectSummary.used, link: Constants.COMUREI_BUY_LINK });
                } else {
                    PopupError(
                        {
                            text: Constants.APPLICATION_GENERIC_ERROR
                        }
                    );
                }
            }
        );

        if (subjectItem?.id === null) {
            props.closeModal();
        }

    };

    const createCommunication = (response) => {

        actions.createComureiCommunication(response).then(
            (communications) => {
                props.openCreateCommunicationModal(response, communications);
            },
            (errors) => {
                if (errors?.status === 511) {
                    PopupSubjectLicense({ total: subjectSummary.total, used: subjectSummary.used, link: Constants.COMUREI_BUY_LINK });
                } else if (errors?.data?.title) {
                    PopupError(
                        {
                            text: errors?.data?.title
                        }
                    );
                } else {
                    PopupError(
                        {
                            text: 'Errore nella generazione della comunicazione'
                        }
                    );
                }

            }
        );
    }

    const handlerChangeSubjectType = (e, setValues, values) => {
        const cod = e.target[e.target.selectedIndex].dataset.cod;
        setValues({
            ...values,
            arcoSubjectType: {id: parseInt(e.target.value), code: cod}
        });
    };

    const handleChangeTextField = (value, field, setValues, values, trim) => {
        let val = value.toUpperCase()
        if (trim) val = val.trim()
        const form = Object.assign({}, values)
        Utility.updateObjProp(form, val, field);
        setValues(form)
    }

    const handleFiscalCode = (val, setValues, values) => {
        actions.getBaseRegistryByFiscalCode(val).then(
            (baseRegistry) => {
                if (baseRegistry) {
                    if (values.personType === Constants.PNF) {
                        setValues({
                            ...values,
                            companyName: baseRegistry?.companyName || values.companyName || '',
                            location: baseRegistry?.location?.location || values.location || '',
                            province: baseRegistry?.location?.province || values.province || ''
                        })
                    } else if (values.personType === Constants.PF) {
                        setValues({
                            ...values,
                            firstName: baseRegistry?.firstName || '',
                            lastName: baseRegistry?.lastName || '',
                            location: baseRegistry?.location?.location || '',
                            province: baseRegistry?.location?.province || '',
                            birthDate: new Date(baseRegistry?.birthDate) || null,
                            gender: baseRegistry?.gender || ''
                        })
                    }
                }
            },
            (errors) => {
                console.log(errors);
            }
        )
    };

    const handleChangeValue = (val, field, setValues, values) => {
        const form = Object.assign({}, values)
        Utility.updateObjProp(form, val, field);
        setValues(form)
    };
    
    const handlerResponsibleFiscalCode = (val, setValues, values) => {
        actions.getBaseRegistryPersonByFiscalCode(val).then(
            (baseRegistry) => {
                if (baseRegistry !== undefined && baseRegistry?.personType === Constants.PF) {
                    const form = Object.assign({}, values)
                    form.comureiSubjectResponsible.fiscalCode = val
                    form.comureiSubjectResponsible.lastName = baseRegistry?.lastName || ''
                    form.comureiSubjectResponsible.firstName = baseRegistry?.firstName || ''
                    form.comureiSubjectResponsible.location = baseRegistry?.location?.location || ''
                    form.comureiSubjectResponsible.province = baseRegistry?.location?.province || ''
                    form.comureiSubjectResponsible.birthDate = new Date(baseRegistry?.birthDate) || null
                    form.comureiSubjectResponsible.gender = baseRegistry?.gender
                    setValues(form)
                } else {
                    try {
                        const cf = new CodiceFiscale(val).toJSON();
                        if (cf) {
                            const form = Object.assign({}, values)
                            form.comureiSubjectResponsible.location = cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace : ''
                            form.comureiSubjectResponsible.province = cf?.birthplaceProvincia || ''
                            form.comureiSubjectResponsible.birthDate = new Date(new Date(cf?.birthday) || null)
                            form.comureiSubjectResponsible.gender = cf?.gender === "M" ? 'MALE' : 'FEMALE'
                            setValues(form)
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            (errors) => {
                console.error(errors);
            }
        )
    };

    const handleInputAutocompile = (newLocation, objectKey, setValues, values) => {
        newLocation = newLocation.toUpperCase()
        actions.getLocation(newLocation).then(
            (loc) => {
                const form = Object.assign({}, values)
                if (objectKey) {
                    form[objectKey].location = newLocation
                    if (loc) form[objectKey].province = loc.province
                } else {
                    form.location = newLocation
                    if (loc) form.province = loc.province                   
                }    
                setValues(form)
            },
            (errors) => {
                console.log(errors);
                const form = Object.assign({}, values)
                if (objectKey) {
                    form[objectKey].location = ""
                    form[objectKey].province = ""
                } else {
                    form.location = ""
                    form.province = ""               
                }    
                setValues(form)
            })
    };

    const handlerChangeResponsiblePhoneNumber = (e, setValues, values) => {
        if (!Number(e.target.value) && e.target.value !== "") return
        handleChangeTextField(e.target.value, "comureiSubjectResponsible.phone", setValues, values, true)

    };

    const updateAll = (values, sendComm) => {
        addComureiSubject({...values, id: subjectItem?.id}, sendComm);
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                
            >
                {({ handleChange, handleBlur, values, errors, touched, setValues, submitForm, validateForm }) => (
                    <Form>
                        {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
                        {showErrorAlert && errors && typeof errors === "object" && Object.keys(errors).length > 0 && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 480,
                                    width: "99%",
                                    color: "white",
                                    zIndex: 1000,
                                    padding: "1px",
                                    textAlign: "center"
                                }}
                            >
                                <ErrorListAlert errors={getActivePropsAndTab(errors, activeTab)} />
                            </div>
                        )}
                        <div className="row-detail comurei pe-3 ps-3 d-flex shadow">
                            <div className="tab-content col-md-12 col-lg-12">
                                <Tabs setTab={setTab}>
                                    <div id="tab-sub" label="Soggetto" onCli>
                                        <div className="form-row mt-2">
                                            <div className="col-md-8 col-lg-7 subDetailComurei">
                                                {/* <h6>Dettaglio Soggetto che Comunica</h6> */}
                                                {/* TIPO PERSONA */}
                                                <div className="row mb-2">

                                                    <div className="col-md-12 col-lg-2 me-2">
                                                        <div className="inputSwitcher me-5 form-check form-switch">
                                                            <label className=" d-flex align-items-center mt-2">
                                                                <FormCheck
                                                                    className="form-switch switch-custom-comurei mt-0 "
                                                                    type="switch"
                                                                    rid="custom-switch"
                                                                    id="disabled-custom-switch"
                                                                    checked={values.personType === Constants.PF}
                                                                    onChange={() =>
                                                                        handleChangeValue(
                                                                            values.personType === Constants.PF ? Constants.PNF : Constants.PF,
                                                                            'personType',
                                                                            setValues,
                                                                            values
                                                                        )
                                                                    }
                                                                />
                                                                {values.personType === Constants.PF ? "Persona fisica" : "Persona giuridica"}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {/* TIPO SOGGETTO */}
                                                    <div className="col-md-12 col-lg-8 mt-1 mb-1 me-2">
                                                        <Field name="personType">
                                                            {({field}) => (
                                                                <BwmSelect
                                                                    {...field}
                                                                    options={props.subjectTypes || []}
                                                                    name='personType'
                                                                    label='Tipo Soggetto'
                                                                    className='form-control'
                                                                    onChange={e => handlerChangeSubjectType(e, setValues, values)}
                                                                    value={values.subjectType}
                                                                    showCode={true}
                                                                    error={errors.personType}
                                                                    touched={touched.personType}/>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    
                                                    {/* CODICE FISCALE */}
                                                    <div className="col-md-12 col-lg-2 mb-1 me-2">
                                                        <Field name="fiscalCode">
                                                            {({field}) => (
                                                                <BwmInput
                                                                    {...field}
                                                                    name='fiscalCode'
                                                                    label='Codice Fiscale'
                                                                    className='form-control'
                                                                    value={values.fiscalCode}
                                                                    onChange={e => handleChangeTextField(e.target.value, "fiscalCode", setValues, values, true)}
                                                                    error={errors.fiscalCode}
                                                                    touched={touched.fiscalCode}
                                                                    maxLength="16"
                                                                    onBlur={e => {
                                                                        handleFiscalCode(e.target.value, setValues, values);
                                                                        handleBlur(e);
                                                                    }}/>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    {values.personType === Constants.PNF &&
                                                    <div className="col-12 col-lg-8">
                                                        {/* RAGIONE SOCIALE */}
                                                        <Field name="companyName">
                                                            {({field}) => (
                                                                <BwmInput
                                                                    {...field}
                                                                    name='companyName'
                                                                    label='Ragione Sociale'
                                                                    className='form-control font-weight-bold font-size-big'
                                                                    value={values.companyName}
                                                                    onChange={e => handleChangeTextField(e.target.value, "companyName", setValues, values)}
                                                                    error={errors.companyName}
                                                                    touched={touched.companyName}
                                                                    maxLength="60"/>
                                                            )}
                                                        </Field>
                                                    </div>}
                                                    {values.personType === Constants.PF && (
                                                    <>
                                                        
                                                        {/* NOME */}
                                                        <div className="col-md-12 col-lg-3 me-2">
                                                            <Field name="firstName">
                                                                {({field}) => (
                                                                    <BwmInput
                                                                        {...field}
                                                                        name='firstName'
                                                                        label='Nome'
                                                                        className='form-control font-weight-bold font-size-big'
                                                                        value={values.firstName}
                                                                        onChange={e => handleChangeTextField(e.target.value, "firstName", setValues, values)}
                                                                        error={errors.firstName}
                                                                        touched={touched.firstName}
                                                                        maxLength="60"/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        {/* COGNOME */}
                                                        <div className="col-md-12 col-lg-3 me-2">
                                                            <Field name="lastName">
                                                                {({field}) => (
                                                                    <BwmInput
                                                                        {...field}
                                                                        name='lastName'
                                                                        label='Cognome'
                                                                        className='form-control font-weight-bold font-size-big'
                                                                        value={values.lastName}
                                                                        onChange={e => handleChangeTextField(e.target.value, "lastName", setValues, values)}
                                                                        error={errors.lastName}
                                                                        touched={touched.lastName}
                                                                        maxLength="60"/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    
                                                    
                                                        {/* SESSO */}
                                                        <div className="col-md-12 col-lg-1 me-2">
                                                            <Field name="gender">
                                                                {({field}) => (
                                                                    <BwmSelect
                                                                        {...field}
                                                                        options={Constants.genderList || []}
                                                                        name='gender'
                                                                        label='Sesso'
                                                                        className='form-control '
                                                                        onChange={e => handleChangeTextField(e.target.value, "gender", setValues, values)}
                                                                        value={values.gender}
                                                                        error={errors.gender}
                                                                        touched={touched.gender}/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-md-12 col-lg-2 me-2">
                                                        {/* DATA DI NASCITA */}
                                                            <Field name="birthDate">
                                                                {({field, form}) => (
                                                                    <DateField
                                                                        {...field}
                                                                        label="Data di nascita"
                                                                        date={values.birthDate}
                                                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                                                        error={errors.birthDate}
                                                                        touched={touched.birthDate}/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        
                                                    </>)}

                                                    <div className={`col-12 col-lg-4 me-2`}>
                                                        {/* LOCALITÀ */}
                                                        <Field name="location">
                                                            {({field}) => (
                                                                <AutoCompileCustom
                                                                    {...field}
                                                                    label={values.personType === Constants.PNF ? 'Sede Legale (Località)' : 'Comune di nascita'}
                                                                    id="location"
                                                                    handleInputAutocompile={e => handleInputAutocompile(e, undefined, setValues, values)}
                                                                    filtro={props.locations}
                                                                    value={values.location}
                                                                    error={errors.location}
                                                                    touched={touched.location}/>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="col-md-2 col-lg-2 me-2">
                                                        {/* PROVINCIA */}
                                                        <Field name="province">
                                                            {({field}) => (
                                                                <ProvinceField
                                                                    {...field}
                                                                    placeholder="Prov. EE per Estero"
                                                                    label="EE per Estero"
                                                                    province={values.province}
                                                                    onChange={e => handleChangeTextField(
                                                                        e.target.value, "province", setValues, values, true
                                                                    )}
                                                                    error={errors.province}
                                                                    touched={touched.province}
                                                                    maxLength="2"/>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                            {/* Sezione Tipo Comunicazione (a sinistra) */}
                                            <div className="col-md-5">
                                                {/* <h6>Dettaglio Comunicazione</h6> */}
                                                <div className="form-check">
                                                    <label>Tipo Comunicazione</label>
                                                    <div className="input-group mt-2 d-flex flex-column">
                                                        <div className="col-12 me-5 p-0 inputRadio">
                                                            <Field type="radio" name="comureiCommunicationType.id" value="2">
                                                                {({ field }) => (
                                                                    <>
                                                                        <input
                                                                            hidden={true}
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            {...field}
                                                                            id={`ct2${subjectItem?.id}`}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`ct2${subjectItem?.id}`}>
                                                                            <img
                                                                                width="20"
                                                                                className="me-2"
                                                                                src={values.comureiCommunicationType.id === '2' ? radioSel : radioUnsel}
                                                                                alt="Cancellazione dal/dai Registro/i"
                                                                            />
                                                                            <span className={values.comureiCommunicationType.id === '2' ? 'fw-bold' : ''}>
                                                                                Cancellazione dal/dai Registro/i
                                                                            </span>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-12 p-0 inputRadio">
                                                            <Field type="radio" name="comureiCommunicationType.id" value="1">
                                                                {({ field }) => (
                                                                    <>
                                                                        <input
                                                                            hidden={true}
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            {...field}
                                                                            id={`ct1${subjectItem?.id}`}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`ct1${subjectItem?.id}`}>
                                                                            <img
                                                                                width="20"
                                                                                className="me-2"
                                                                                src={values.comureiCommunicationType.id === '1' ? radioSel : radioUnsel}
                                                                                alt="Prima iscrizione"
                                                                            />
                                                                            <span className={values.comureiCommunicationType.id === '1' ? 'fw-bold' : ''}>
                                                                                Prima iscrizione
                                                                            </span>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-12 p-0 inputRadio">
                                                            <Field type="radio" name="comureiCommunicationType.id" value="3">
                                                                {({ field }) => (
                                                                    <>
                                                                        <input
                                                                            hidden={true}
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            {...field}
                                                                            id={`ct3${subjectItem?.id}`}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`ct3${subjectItem?.id}`}>
                                                                            <img
                                                                                width="20"
                                                                                className="me-2"
                                                                                src={values.comureiCommunicationType?.id === '3' ? radioSel : radioUnsel}
                                                                                alt="Variazione di dati già comunicati"
                                                                            />
                                                                            <span className={values.comureiCommunicationType.id === '3' ? 'fw-bold' : ''}>
                                                                                Variazione di dati già comunicati
                                                                            </span>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-12 p-0 inputRadio">
                                                            <Field type="radio" name="comureiCommunicationType.id" value="4">
                                                                {({ field }) => (
                                                                    <>
                                                                        <input
                                                                            hidden={true}
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            {...field}
                                                                            id={`ct4${subjectItem?.id}`}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`ct4${subjectItem?.id}`}>
                                                                            <img
                                                                                width="20"
                                                                                className="me-2"
                                                                                src={values.comureiCommunicationType?.id === '4' ? radioSel : radioUnsel}
                                                                                alt="Reiscrizione al/ai Registri"
                                                                            />
                                                                            <span className={values.comureiCommunicationType.id === '4' ? 'fw-bold' : ''}>
                                                                                Reiscrizione al/ai Registri
                                                                            </span>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="form-check col-12 p-0 d-flex align-items-center">
                                                        <label style={{ color: '#128186', fontWeight: 'bold', marginRight: '10px' }}>
                                                            Struttura accentrata
                                                        </label>
                                                        <Field name="centralizedStructure">
                                                            {({ field }) => (
                                                                <div className="d-flex align-items-center">
                                                                    <BwmCheckbox
                                                                        {...field}
                                                                        name={`centralizedStructure_${subjectItem?.id}`}
                                                                        label="Sì"
                                                                        className="form-control"
                                                                        checked={field.value}
                                                                        error={errors.centralizedStructure}
                                                                        touched={touched.centralizedStructure}
                                                                        onChange={(e) => {
                                                                            handleChangeValue(e.target.checked, "centralizedStructure", setValues, values);
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Field>
                                                        {values.centralizedStructure && (
                                                            <span className="text-danger ms-1 me-1 fw-bold">
                                                                per le strutture accentrate non sarà possibile caricare l'impegno alla trasmissione di un intermediario
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Sezione Motivi della Comunicazione (a destra) */}
                                            <div className="col-md-7">
                                                <label className="ms-5" style={{ color: '#128186', fontWeight: 'bold', marginRight: '10px' }}>
                                                    Motivi della Comunicazione (sezioni REI)
                                                </label>
                                            <div className="form-check col-12 p-0 ms-5">
                                                <Field name="registryReason">
                                                    {({ field }) => (
                                                        <BwmCheckbox
                                                            {...field}
                                                            name={`registryReason_${subjectItem?.id}`}
                                                            label={
                                                                <span className={field.value ? "fw-bold" : ""}
                                                                style={{ fontSize: "13px" }}>
                                                                    Indagini Finanziarie (Anagrafe Rapporti)
                                                                </span>
                                                            } 
                                                            className="form-control"
                                                            checked={field.value}
                                                            error={errors.registryReason}
                                                            touched={touched.registryReason}
                                                            onChange={(e) => {
                                                                handleChangeValue(e.target.checked, "registryReason", setValues, values);
                                                                field.onChange(e);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            

                                                <div className="form-check col-12 p-0 ms-5">
                                                    <Field name="monitoringReason">
                                                        {({ field }) => (
                                                            <BwmCheckbox
                                                                {...field}
                                                                name={`monitoringReason_${subjectItem?.id}`}
                                                                label={
                                                                    <span className={field.value ? "fw-bold" : ""}
                                                                    style={{ fontSize: "13px" }}>
                                                                        (Monitoraggio fiscale)
                                                                    </span>
                                                                } 
                                                                className="form-control"
                                                                checked={field.value}
                                                                error={errors.monitoringReason}
                                                                touched={touched.monitoringReason}
                                                                onChange={(e) => {
                                                                    handleChangeValue(e.target.checked, "monitoringReason", setValues, values);
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="form-check col-12 p-0 ms-5">
                                                    {/* <label>Sezione FACTA/CRS</label> */}
                                                    <Field name="factaReason">
                                                        {({ field }) => (
                                                            <BwmCheckbox
                                                                {...field}
                                                                name={`factaReason_${subjectItem?.id}`}
                                                                label={
                                                                    <span className={field.value ? "fw-bold" : ""}
                                                                    style={{ fontSize: "13px" }}>
                                                                        (FACTA/CRS)
                                                                    </span>
                                                                } 
                                                                className="form-control"
                                                                checked={field.value}
                                                                error={errors.factaReason}
                                                                touched={touched.factaReason}
                                                                onChange={(e) => {
                                                                    handleChangeValue(e.target.checked, "factaReason", setValues, values);
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div id="tab-resp" className="d-none" label="Responsabile">
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-2 mb-2 me-2 mt-2">
                                                <Field name="comureiSubjectResponsible.fiscalCode">
                                                    {({field}) => (
                                                        <BwmInput
                                                            {...field}
                                                            label='Codice Fiscale'
                                                            className='form-control'
                                                            value={values.comureiSubjectResponsible.fiscalCode}
                                                            onChange={e => handleChangeTextField(
                                                                e.target.value, "comureiSubjectResponsible.fiscalCode", setValues, values, true
                                                            )}
                                                            onBlur={e => handlerResponsibleFiscalCode(e.target.value, setValues, values)}
                                                            touched={touched.comureiSubjectResponsible?.fiscalCode}
                                                            error={errors.comureiSubjectResponsible?.fiscalCode}
                                                            maxLength="60"/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-3 col-lg-2 me-2 mt-2">
                                                <Field name="comureiSubjectResponsible.lastName">
                                                    {({field}) => (
                                                        <BwmInput
                                                            {...field}
                                                            label='Cognome'
                                                            className='form-control'
                                                            value={values.comureiSubjectResponsible.lastName}
                                                            onChange={e => handleChangeTextField(
                                                                e.target.value, "comureiSubjectResponsible.lastName", setValues, values
                                                            )}
                                                            error={errors.comureiSubjectResponsible?.lastName}
                                                            touched={errors.comureiSubjectResponsible?.lastName}
                                                            maxLength="60"/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-3 col-lg-2 mt-2 me-2">
                                                <Field name="comureiSubjectResponsible.firstName">
                                                    {({field}) => (
                                                        <BwmInput
                                                            {...field}
                                                            label='Nome'
                                                            className='form-control'
                                                            value={values.comureiSubjectResponsible.firstName}
                                                            onChange={e => handleChangeTextField(
                                                                e.target.value, "comureiSubjectResponsible.firstName", setValues, values
                                                            )}
                                                            error={errors.comureiSubjectResponsible?.firstName}
                                                            touched={errors.comureiSubjectResponsible?.firstName}
                                                            maxLength="60"/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-1 col-lg-1 me-2 mt-2">
                                                <Field name="comureiSubjectResponsible.gender">
                                                    {({field}) => (
                                                        <BwmSelect
                                                            {...field}
                                                            options={Constants.genderList || []}
                                                            name='responsibleGender'
                                                            label='Sesso'
                                                            className='form-control'
                                                            onChange={e => handleChangeTextField(
                                                                e.target.value, "comureiSubjectResponsible.gender", setValues, values
                                                            )}
                                                            value={values.comureiSubjectResponsible?.gender}
                                                            error={errors.comureiSubjectResponsible?.gender}
                                                            touched={touched.comureiSubjectResponsible?.gender}/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-2 col-lg-1 me-2 mt-2">
                                                <Field name="comureiSubjectResponsible.birthDate">
                                                    {({field}) => (
                                                        <DateField
                                                            {...field}
                                                            label="Data di nascita"
                                                            date={errors.comureiSubjectResponsible?.birthDate}
                                                            onChange={date => handleChangeValue(date, 'comureiSubjectResponsible.birthDate', setValues, values)}
                                                            error={errors.comureiSubjectResponsible?.birthDate}
                                                            touched={errors.comureiSubjectResponsible?.birthDate}/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-3 col-lg-2 me-2 mt-2">
                                                <Field name="comureiSubjectResponsible.location">
                                                    {({field}) => (
                                                        <AutoCompileCustom
                                                            {...field}
                                                            label='Comune di nascita'
                                                            handleInputAutocompile={location => handleInputAutocompile(
                                                                location, 'comureiSubjectResponsible', setValues, values
                                                            )}
                                                            filtro={props.locations}
                                                            value={values.comureiSubjectResponsible?.location}
                                                            error={errors.comureiSubjectResponsible?.location}
                                                            touched={touched.comureiSubjectResponsible?.location}/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-1 col-lg-1 me-2 mt-2">
                                                <Field name="comureiSubjectResponsible.province">
                                                    {({field}) => (
                                                        <ProvinceField
                                                            {...field}
                                                            placeholder="Prov. EE per Estero"                                                            province={values.comureiSubjectResponsible?.province}
                                                            onChange={e => handleChangeTextField(
                                                                e.target.value, "comureiSubjectResponsible.province", setValues, values, true
                                                            )}
                                                            error={errors.comureiSubjectResponsible?.province}
                                                            touched={touched.comureiSubjectResponsible?.province}/>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-5 col-lg-3 me-2">
                                                <Field name="comureiSubjectResponsible.email">
                                                    {({field}) => (
                                                        <BwmInput
                                                            {...field}
                                                            label='Indirizzo Email (indirizzo personale del responsabile)'
                                                            className='form-control'
                                                            value={values.comureiSubjectResponsible?.email}
                                                            onChange={e => handleChangeTextField(
                                                                e.target.value, "comureiSubjectResponsible.email", setValues, values, true
                                                            )}
                                                            error={errors.comureiSubjectResponsible?.email}
                                                            touched={touched.comureiSubjectResponsible?.email}
                                                            maxLength="60"/>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-2 col-lg-2">
                                                <Field name="comureiSubjectResponsible.phone">
                                                    {({field}) => (
                                                        <BwmInput
                                                            {...field}
                                                            label='Telefono'
                                                            className='form-control'
                                                            value={values.comureiSubjectResponsible.phone}
                                                            onChange={e => handlerChangeResponsiblePhoneNumber(e, setValues, values)}
                                                            error={errors.comureiSubjectResponsible?.phone}
                                                            touched={touched.comureiSubjectResponsible?.phone}
                                                            maxLength="10"/>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-pec" className="d-none" label="Posta Elettronica">
                                        <div className="form-row">
                                            <div className="col-md-7 col-lg-7">
                                                <div className="form-row mb-2">
                                                    <div
                                                        className={`col-md-12 col-lg-11 ${!(values.comureiCommunicationType?.id?.toString() === '1' || values.comureiCommunicationType?.id?.toString() === '4') ? 'hidden' : ''}`}>
                                                        <Field name="comureiSubjectPec.pecEmail">
                                                            {({field}) => (
                                                                <BwmInput
                                                                    {...field}
                                                                    label={`${values.comureiCommunicationType?.id === "4" ? 'INDIRIZZO PEC DA REISCRIVERE AL REI' : 'INDIRIZZO PEC DA SCRIVERE AL REI'} `}
                                                                    className='form-control'
                                                                    value={values.comureiSubjectPec.pecEmail}
                                                                    onChange={e => handleChangeTextField(
                                                                        e.target.value, "comureiSubjectPec.pecEmail", setValues, values, true
                                                                    )}
                                                                    error={errors.comureiSubjectPec?.pecEmail}
                                                                    touched={touched.comureiSubjectPec?.pecEmail}
                                                                    maxLength="100"/>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                {values.comureiCommunicationType?.id?.toString() !== '2' &&
                                                    <div className="form-row mb-2">
                                                        <div
                                                            className={`col-md-12 col-lg-4 me-2${(values.comureiCommunicationType?.id?.toString() === '2') ? 'hidden' : ''}`}>
                                                            <Field name="comureiSubjectPec.pecManager">
                                                                {({field}) => (
                                                                    <BwmInput
                                                                        {...field}
                                                                        label='Gestore PEC'
                                                                        className='form-control'
                                                                        value={values.comureiSubjectPec?.pecManager}
                                                                        onChange={e => handleChangeTextField(
                                                                            e.target.value, "comureiSubjectPec.pecManager", setValues, values
                                                                        )}
                                                                        error={errors.comureiSubjectPec?.pecManager}
                                                                        touched={touched.comureiSubjectPec?.pecManager}
                                                                        maxLength="100"/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                {values.comureiCommunicationType?.id?.toString() !== '2' &&(
                                                    <>
                                                        <div
                                                            className={`col-md-5 col-lg-3 me-2${(values.comureiCommunicationType?.id?.toString() === '2') ? 'hidden' : ''}`}>
                                                            <Field name="comureiSubjectPec.effectiveDate">
                                                                {({field}) => (
                                                                    <DateField
                                                                        {...field}
                                                                        label={"Data DECORRENZA"}
                                                                        date={values.comureiSubjectPec?.effectiveDate}
                                                                        onChange={date => handleChangeValue(date, 'comureiSubjectPec.effectiveDate', setValues, values)}
                                                                        error={errors.comureiSubjectPec?.effectiveDate}
                                                                        touched={touched.comureiSubjectPec?.effectiveDate}/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div
                                                            className={`col-md-5 col-lg-3 me-2${(values.comureiCommunicationType?.id?.toString() === '2') ? 'hidden' : ''}`}>
                                                            <Field name="comureiSubjectPec.endDate">
                                                                {({field}) => (
                                                                    <DateField
                                                                        {...field}
                                                                        label={"Data CESSAZIONE"}
                                                                        date={values.comureiSubjectPec?.endDate}
                                                                        onChange={date => handleChangeValue(date, 'comureiSubjectPec.endDate', setValues, values)}
                                                                        error={errors.comureiSubjectPec?.endDate}
                                                                        touched={touched.comureiSubjectPec?.endDate}/>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </>)}
                                                    </div>}
                                                    <div className={`form-row ${(values.comureiCommunicationType?.id?.toString() === '2' || values.comureiCommunicationType?.id?.toString() === '3') ? '' : 'hidden'}`}>
                                                    <div className={'col-5 me-2'}>
                                                        <Field name="comureiSubjectPec.reiEmail">
                                                        {({ field }) => (
                                                            <BwmInput
                                                            {...field}
                                                            label={` ${values.comureiCommunicationType?.id?.toString() === '2' ? 'Indirizzo di posta elettronica già comunicata al REI DA CANCELLARE' : 'Indirizzo PEC già comunicato DA VARIARE'}`}
                                                            className='form-control'
                                                            value={values.comureiSubjectPec?.reiEmail}
                                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectPec.reiEmail", setValues, values, true)}
                                                            error={errors.comureiSubjectPec?.reiEmail}
                                                            touched={touched.comureiSubjectPec?.reiEmail}
                                                            maxLength="255"
                                                            />
                                                        )}
                                                        </Field>
                                                    </div>
                                                    {values.comureiCommunicationType?.id?.toString() === '3' && (
                                                    <div className={'col-5'}>
                                                    <Field name="comureiSubjectPec.pecEmailAddress">
                                                        {({ field }) => (
                                                        <BwmInput
                                                            {...field}
                                                            label="Indirizzo PEC da variare"
                                                            className='form-control'
                                                            value={values.comureiSubjectPec?.pecEmailAddress}
                                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectPec.pecEmailAddress", setValues, values, true)}
                                                            error={errors.comureiSubjectPec?.pecEmailAddress}
                                                            touched={touched.comureiSubjectPec?.pecEmailAddress}
                                                            maxLength="255"
                                                        />
                                                        )}
                                                    </Field>
                                                    </div>
                                                )}
                                                </div>

                                            </div>

                                            <div className="col-md-5 col-lg-5  infoPostaEle mb-2">
                                                <div className="infoPoint-title">
                                                    <IInfo fill="#b8b8b8" width="20"/> Informazioni
                                                </div>
                                                <div className="infoPoint"><strong>Indirizzo EMAIL PEC:</strong> lo
                                                    stesso da indicare nella RICHIESTA DI ACCREDITAMENTO al SID
                                                </div>
                                                <div className="infoPoint"><strong>Gestore PEC:</strong> Il gestore del
                                                    servizio PEC (Infocamere, Aruba, PEC.it etc.)
                                                </div>
                                                <div className="infoPoint"><strong>Data DECORRENZA:</strong> è l'inizio
                                                    del contratto con il gestore del servizio PEC
                                                </div>
                                                <div className="infoPoint"><strong>Data CESSAZIONE:</strong> non
                                                    obbligatoria. Se indicata in caso di
                                                    rinnovo <strong>NON</strong> deve essere fatta una nuova
                                                    comunicazione
                                                </div>
                                                <div className="infoPoint"><strong>La Data VARIAZIONE</strong> non è più
                                                    prevista quindi <strong>NON</strong> va indicata
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {values.comureiCommunicationType?.id?.toString() === '2' ?
                                        <div id="tab-delete" className="d-none" label="Cancellazione">
                                            <Row className='align-items-center'>
                                                <div className="col-2 mb-2">
                                                    <Field name="comureiSubjectCancellation.cancellationDate">
                                                        {({field}) => (
                                                            <DateField
                                                                {...field}
                                                                label={"Data CANCELLAZIONE"}
                                                                date={values.comureiSubjectCancellation.cancellationDate}
                                                                onChange={date => handleChangeValue(date, 'comureiSubjectCancellation.cancellationDate', setValues, values)}
                                                                error={errors.comureiSubjectCancellation?.cancellationDate}
                                                                touched={touched.comureiSubjectCancellation?.cancellationDate}/>
                                                        )}
                                                    </Field>
                                                </div>
                                            </Row>
                                            <div className="form-row mt-5">
                                                <div className="col-lg-6 p-0">
                                                    <div className="form-check">
                                                        <label>Motivo della CANCELLAZIONE</label>
                                                        <div className="input-group mt-2">
                                                            <div className="col-12 p-0 inputRadio mb-2">
                                                                <Field type="radio" name="comureiSubjectCancellation.comureiSubjectCancellationType.id" value="1">
                                                                    {({ field }) => (
                                                                        <>                                                            
                                                                            <input
                                                                                hidden={true}
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`cr1${subjectItem?.id}`}
                                                                                />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`cr1${subjectItem?.id}`}
                                                                            >
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiSubjectCancellation.comureiSubjectCancellationType?.id === '1' ? radioSel : radioUnsel}
                                                                                    alt="Cessazione  con confluenza dell'attività finanziaria in un solo operatore finanziario"/> Cessazione
                                                                                con confluenza dell'attività
                                                                                finanziaria in un solo operatore finanziario
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-12 p-0 inputRadio mb-2">
                                                                <Field type="radio" name="comureiSubjectCancellation.comureiSubjectCancellationType.id" value="2">
                                                                    {({ field }) => (
                                                                        <>                                                            
                                                                            <input
                                                                                hidden={true}
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`cr2${subjectItem?.id}`}
                                                                                />
                                                                            <label className="form-check-label"
                                                                                htmlFor={`cr2${subjectItem?.id}`}>
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiSubjectCancellation.comureiSubjectCancellationType?.id === '2' ? radioSel : radioUnsel}
                                                                                    alt=""
                                                                                /> Perdita
                                                                                dei requisiti soggettivi
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-12 p-0  inputRadio mb-2">
                                                                <Field type="radio" name="comureiSubjectCancellation.comureiSubjectCancellationType.id" value="3">
                                                                    {({ field }) => (
                                                                        <>                                                                   
                                                                            <input
                                                                                hidden={true}
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`cr3${subjectItem?.id}`}
                                                                                />
                                                                            <label className="form-check-label"
                                                                                htmlFor={`cr3${subjectItem?.id}`}>
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiSubjectCancellation.comureiSubjectCancellationType?.id === '3' ? radioSel : radioUnsel}
                                                                                    alt="Cessazione senza confluenza in altro soggetto"/> Cessazione
                                                                                senza confluenza in altro soggetto
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-12 p-0  inputRadio mb-2">
                                                                <Field type="radio" name="comureiSubjectCancellation.comureiSubjectCancellationType.id" value="4">
                                                                    {({ field }) => (
                                                                        <>                                                                  
                                                                            <input
                                                                                hidden={true}
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`cr4${subjectItem?.id}`}
                                                                                />
                                                                            <label className="form-check-label"
                                                                                htmlFor={`cr4${subjectItem?.id}`}>
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiSubjectCancellation.comureiSubjectCancellationType?.id === '4' ? radioSel : radioUnsel}
                                                                                    alt="Cessazione con confluenza in altro soggetto non operatore finanziario"/> Cessazione
                                                                                con confluenza in altro soggetto non
                                                                                operatore finanziario
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>                                                                
                                                            </div>
                                                            <div className="col-12 p-0  inputRadio mb-2">
                                                                <Field type="radio" name="comureiSubjectCancellation.comureiSubjectCancellationType.id" value="5">
                                                                    {({ field }) => (
                                                                        <>                                                                    
                                                                            <input
                                                                                hidden={true}
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`cr5${subjectItem?.id}`}
                                                                                />
                                                                            <label className="form-check-label"
                                                                                htmlFor={`cr5${subjectItem?.id}`}>
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiSubjectCancellation.comureiSubjectCancellationType?.id === '5' ? radioSel : radioUnsel}
                                                                                    alt="Cessazione  con confluenza dell'attività finanziaria in più operatori finanziari"/> Cessazione
                                                                                con confluenza dell'attività
                                                                                finanziaria in più operatori finanziari
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>   
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 p-0">
                                                    <h6 className="mb-4"><strong>Disponibilità Contabilità
                                                        Ordinaria</strong></h6>
                                                    {(values.comureiSubjectCancellationType?.id?.toString() === '2' || values.comureiSubjectCancellationType?.id?.toString() === '3' || values.comureiSubjectCancellationType?.id?.toString() === '4') &&
                                                        <div className="row">
                                                            <div className="col-md-4 col-lg-4">
                                                                <Field name="comureiSubjectCancellation.address">
                                                                    {({field}) => (
                                                                        <BwmInput
                                                                            {...field}
                                                                            label='Indirizzo'
                                                                            className='form-control'
                                                                            value={values.comureiSubjectCancellation?.address}
                                                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.address", setValues, values)}
                                                                            error={errors.comureiSubjectCancellation?.address}
                                                                            touched={touched.comureiSubjectCancellation?.address}
                                                                            maxLength="100"/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-md-4 col-lg-4">
                                                                <Field name="comureiSubjectCancellation.location">
                                                                    {({field}) => (
                                                                        <AutoCompileCustom
                                                                            {...field}
                                                                            label='Comune Sede Legale'
                                                                            handleInputAutocompile={e => handleInputAutocompile(e, 'comureiSubjectCancellation', setValues, values)}
                                                                            filtro={props.locations}
                                                                            value={values.comureiSubjectCancellation?.location}
                                                                            error={errors.comureiSubjectCancellation?.location}
                                                                            touched={touched.comureiSubjectCancellation?.location}/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-md-2 col-lg-2 ">
                                                                <Field name="comureiSubjectCancellation.location">
                                                                    {({field}) => (
                                                                        <ProvinceField
                                                                            {...field}
                                                                            province={values.comureiSubjectCancellation?.province}
                                                                            onChange={e => handleChangeTextField(
                                                                                e.target.value, "comureiSubjectCancellation.province", setValues, values, true
                                                                            )}
                                                                            error={errors.comureiSubjectCancellation?.province}
                                                                            touched={touched.comureiSubjectCancellation?.province}/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>}
                                                    {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id === '1' &&
                                                        <div className="row">
                                                            <div className="col-md-12 col-lg-3">
                                                                <Field name="comureiSubjectCancellation.location">
                                                                    {({field}) => (
                                                                        <BwmInput
                                                                            {...field}
                                                                            label='Codice Fiscale operatore incorporante 1'
                                                                            className='form-control'
                                                                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                                                                            onChange={e => handleChangeTextField(e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeOne", setValues, values, true)}
                                                                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                                                                            touched={touched.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                                                                            maxLength="100"/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>}
                                                    {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id === '5' &&
                                                        <div className="row">
                                                            <div className="col-md-12 col-lg-3">
                                                                <Field
                                                                    name="comureiSubjectCancellation.incorporatingFiscalCodeOne">
                                                                    {({field}) => (
                                                                        <BwmInput
                                                                            {...field}
                                                                            label='Codice Fiscale operatore incorporante 1'
                                                                            className='form-control'
                                                                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                                                                            onChange={e => handleChangeTextField(
                                                                                e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeOne", setValues, values, true
                                                                            )}
                                                                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                                                                            touched={touched.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                                                                            maxLength="100"/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-md-12 col-lg-3">
                                                                <Field
                                                                    name="comureiSubjectCancellation.incorporatingFiscalCodeOne">
                                                                    {({field}) => (
                                                                        <BwmInput
                                                                            {...field}
                                                                            name='incorporatingFiscalCodeTwo'
                                                                            label='Codice Fiscale operatore incorporante 2'
                                                                            className='form-control'
                                                                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                                                                            onChange={e => handleChangeTextField(
                                                                                e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeTwo", setValues, values, true
                                                                            )}
                                                                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                                                                            touched={touched.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                                                                            maxLength="100"/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-md-4 col-lg-4">
                                                                <Field
                                                                    name="comureiSubjectCancellation.incorporatingFiscalCodeThree">
                                                                    {({field}) => (
                                                                        <BwmInput
                                                                            {...field}
                                                                            name='incorporatingFiscalCodeThree'
                                                                            label='Codice Fiscale operatore incorporante 3'
                                                                            className='form-control'
                                                                            value={values.comureiSubjectCancellation.incorporatingFiscalCodeThree}
                                                                            onChange={e => handleChangeTextField(
                                                                                e.target.value, "comureiSubjectCancellation.incorporatingFiscalCodeThree", setValues, values, true
                                                                            )}
                                                                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeThree}
                                                                            touched={touched.comureiSubjectCancellation?.incorporatingFiscalCodeThree}
                                                                            maxLength="100"/>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>}


                                                </div>
                                            </div>
                                        </div> : <div id="tab-delete" className="d-none" label="Cancellazione" disabled></div>}
                                    {values.centralizedStructure ? <div id="tab-transmission" className="d-none"
                                                                        label="Impegno alla trasmissione"></div> :
                                        <div id="tab-transmission" className="d-none" label="Impegno alla trasmissione">
                                            <div className="form-row mb-2">
                                                <div className="col-md-4 col-lg-4">
                                                    <Field name="comureiTransmission.fiscalCode">
                                                        {({field}) => (
                                                            <BwmInput
                                                                {...field}
                                                                label='Codice Fiscale'
                                                                className='form-control'
                                                                value={values.comureiTransmission?.fiscalCode}
                                                                onChange={e => handleChangeTextField(
                                                                    e.target.value, "comureiTransmission.fiscalCode", setValues, values, true
                                                                )}
                                                                error={errors.comureiTransmission?.fiscalCode}
                                                                touched={touched.comureiTransmission?.fiscalCode}
                                                                maxLength="100"/>
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-7 col-lg-7 commDetailComurei offset-1">
                                                    <div className="form-check">
                                                        <label>Impegno alla trasmissione telematica</label>
                                                        <div className="input-group mt-2">
                                                            <div className="inputRadio me-2">
                                                                <Field type="radio" name="comureiTransmission.comureiTransmissionType.id" value="1">
                                                                    {({ field }) => (
                                                                        <>                                                                  
                                                                            <input
                                                                                hidden={true} className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`tt1${subjectItem?.id}`}/>
                                                                            <label className="form-check-label"
                                                                                htmlFor={`tt1${subjectItem?.id}`}>
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiTransmission?.comureiTransmissionType?.id === '1' ? radioSel : radioUnsel}
                                                                                    alt="Da CONTRIBUENTE"/>
                                                                                Da CONTRIBUENTE
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>  
                                                            </div>
                                                            <div className="inputRadio">
                                                                <Field type="radio" name="comureiTransmission.comureiTransmissionType.id" value="2">
                                                                    {({ field }) => (
                                                                        <>                                                                      
                                                                            <input
                                                                                hidden={true}
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                {...field}
                                                                                id={`tt2${subjectItem?.id}`}/>
                                                                            <label className="form-check-label"
                                                                                htmlFor={`tt2${subjectItem?.id}`}>
                                                                                <img
                                                                                    width="20"
                                                                                    className="me-2"
                                                                                    src={values.comureiTransmission?.comureiTransmissionType?.id === '2' ? radioSel : radioUnsel}
                                                                                    alt="Da chi effettua l'INVIO"/>
                                                                                Da chi effettua l'INVIO
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </Field>                                                                  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-9 col-lg-9 me-4 mt-3">
                                                    <div className="infoPostaEle panelTrasm">
                                                        <div className="infoPoint-title"><IInfo fill="#b8b8b8"
                                                                                                width="20"/> Informazioni
                                                        </div>
                                                        <div className="infoPoint">
                                                            Compilare solo nel caso di comunicazione spedita tramite
                                                            Entratel di un intermediario (commercialista) diverso dal
                                                            soggetto obbligato.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </Tabs>
                            </div>
                        </div>
                        <div className="form-row mt-2 pb-3 row align-item-center">
                            <div className="col-8 d-flex justify-content-start">
                                {/* Bottone Annulla */}
                                <button
                                    className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
                                    onClick={props.closeModal}
                                >
                                    <IUndo className={"padding-right-4px svg"} width="20" fill={"#128186"}/>&nbsp; ANNULLA
                                </button>

                                {/* Bottone Crea Comunicazione */}
                                <button
                                    className="btn btn-primary btn-new-rel px-5 ml-2 me-4"
                                >
                                    <ITextFile className={"padding-right-4px svg"} width="16" fill={'#FFFFFF'}/>&nbsp; CREA COMUNICAZIONE
                                </button>

                                {/* Bottone Conferma */}
                                <button 
                                    type="submit"
                                    className="btn btn-primary btn-new-rel px-5 ml-2"
                                    onClick={async () => {
                                        const errors = await validateForm(values); // Validazione dei valori
                                        if (Object.keys(errors).length > 0) {
                                            
                                            setShowErrorAlert(true); // Mostra l'alert degli errori
                                        } else {
                                            updateAll(values, false); // Esegui l'aggiornamento se non ci sono errori
                                            setShowErrorAlert(false); // Nascondi l'alert
                                        }
                                    }}
                                >
                                    <ISave className={"padding-right-4px svg"} width="20" fill={"#FFFFFF"}/>&nbsp; CONFERMA
                                </button>
                            </div>
                            
                            {/* Vista sulla destra */}
                            <div className="col-4 d-flex justify-content-end ">
                                {windowWidth >= 1780 && props.paginationComponent}
                            </div>
                        </div>
                        {windowWidth < 1780 && <div className='pb-3'>
                            {props.paginationComponent}                                             
                        </div>}
                    </Form>
    )
}
</Formik>
</React.Fragment>
)

};

export default reduxForm({
    form: "ComureiForm"
})(ComureiForm)

