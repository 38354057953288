import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//Componente che costruisce una lista di elementi riordinabili, a partire da una lista e un componente di template
//TODO: aggiungere stili configurabili, se necessario
export function DraggableList({
    elements = [],
    listItemComponent = (element) => <></>,
    onReorder = (reorderedList) => { }
}) {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 2;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        ...draggableStyle,
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        top: isDragging ? 0 : '',
        left: isDragging ? 0 : '',
        position: isDragging ? 'relative' : '',
    });

    const getListStyle = isDraggingOver => ({
        //background: "lightblue",
        padding: grid,
        width: 250
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            elements,
            result.source.index,
            result.destination.index
        );

        onReorder(items)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {elements.map((element, index) => {
                            return (
                                <Draggable key={"selectable-header-" + index}
                                    draggableId={"selectable-header-" + index}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {listItemComponent(element)}
                                           
                                        </div>
                                    )}

                                </Draggable>
                            )
                        })}
                         {provided.placeholder}
                    </div>)}
            </Droppable>
        </DragDropContext>
    )
}