import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { BwmInput } from "../../shared/form/BwmInput";
import { BtnFill } from "../../shared/BtnFill";
import { Col, Row } from "react-bootstrap";
import { adminFindRestorableCoraSubjects, restoreCoraSubject } from "../../../actions";
import { PopupError } from "../../shared/PopupError";
import { PopupSuccess } from "../../shared/PopupSuccess";
import { testEmail } from "../../shared/Utility";
import { SimpleSpinner } from "../../shared/spinner/Spinner";
import { BwmSelect } from "../../shared/form/BwmSelect";
import { APPLICATION_GENERIC_ERROR } from "../../../config/Constants";

export function CoraAdmin() {

    const [userLogin, setUserLogin] = useState("");
    const [fiscalCode, setFiscalCode] = useState("");
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false);
    const [fiscalCodes, setFiscalCodes] = useState([]);

    useEffect(() => {
        handleChangeUserLogin(location?.state?.loginParam || "")
    }, [])
    const onRestore = () => {
        let dto = {
            userLogin,
            fiscalCode
        }

        restoreCoraSubject(dto).then((res) => {
            PopupSuccess({
                text: ` Soggetto ${res.companyName}${res.firstName && res.firstName + " " + res.lastName} con id ${res.id},  ripristinato.
             Potrebbe essere necessario reinserire rapporti e certificati.` })
        },
            (err) => {
                PopupError({ text: err.title || APPLICATION_GENERIC_ERROR })
            });
    }

    const handleChangeUserLogin = (value) => {
        setUserLogin(value);
        setFiscalCode("");
        
        const isEmailValid = testEmail(value);
        if (isEmailValid) {
            setIsLoading(true);
            //interrogo il BE per reperire i codici fiscali ripristinabili
            adminFindRestorableCoraSubjects(value)
                .then((res) => {
                    setFiscalCodes(res)
                },
                    (err) => {
                        console.log(err)
                    })
                .then(() => setIsLoading(false))
        } else {
            setFiscalCodes([])
        }
    }

    const buildDropDown = (fiscalCodes = []) => {
        const options = fiscalCodes.map(e => {
            return { id: e, description: e }
        })
        return <BwmSelect
            label={"Codice Fiscale soggetto: "}
            maxLength={16}
            className='form-control'
            value={fiscalCode}
            options={options}
            onChange={e => setFiscalCode(e.target.value)}
        />
    }

    return (
        <React.Fragment>
            <Row className="justify-content-center">
                <Col lg={6} >
                    <h4>
                        RIPRISTINA SOGGETTO CORA
                    </h4>
                    <div className="row">
                        <Col lg={7} >
                            <BwmInput
                                maxLength={255}
                                className='form-control'
                                name={"login"}
                                value={userLogin}
                                label={"Login utente: "}
                                onChange={e => handleChangeUserLogin(e.target.value.trim())}
                            />
                        </Col>

                    </div>
                    <div className="row">
                        <Col lg={7} >
                            {fiscalCodes.length > 0 ? buildDropDown(fiscalCodes) : "Questo utente non ha soggetti ripristinabili"}
                            {/* <BwmInput
                                name={"fiscalCode"}
                                maxLength={16}
                                className='form-control'
                                value={fiscalCode}
                                label={"Codice Fiscale soggetto: "}
                                onChange={e => setFiscalCode(e.target.value.trim())}
                            /> */}

                        </Col>
                        <Col lg={1}>
                            {isLoading && <SimpleSpinner size={18} />}
                        </Col>
                    </div>
                    {fiscalCode && <div className="row">
                        <BtnFill
                            text={"RIPRISTINA"}
                            handlerOnClick={() => { onRestore() }}
                        />
                    </div>}

                </Col>
            </Row>
        </React.Fragment>
    )



}