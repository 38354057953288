import React, { useEffect, useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import { ArcoRegistriesList } from "./ArcoRegistriesList"
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import * as Constants from "../../../../config/Constants"
import { formatDateForDisplay } from '../../../shared/Utility';



registerLocale("it", it);

export function ArcoRegistryHolders(props) {

    const [showModal, setShowModal] = useState(false);
    const [checkedArcoRegistryHoldersValues, setcheckedArcoRegistriesHoldersValues] = useState([]);

    const [holders, setHolders] = useState(props.holders || []);


    useEffect(() => {
        setHolders(props.holders || []);
    }, [props.holders]);

    const handleShowModal = () => {
        setShowModal(!showModal);
    };

    const onCheckBoxClick = (event, arcoRegistryHolders) => {
        if (event.target.checked) {
            checkArcoRegistryHolders(arcoRegistryHolders);
            let element = document.getElementById("holder-" + arcoRegistryHolders.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            uncheckArcoRegistryHolders(arcoRegistryHolders);
            document.getElementById('checkboxAllIdHolders').checked = false;
            let element = document.getElementById("holder-" + arcoRegistryHolders.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    const checkArcoRegistryHolders = (arcoRegistryHolders) => {
        setcheckedArcoRegistriesHoldersValues(checkedArcoRegistryHoldersValues.concat(arcoRegistryHolders));
    };

    const uncheckArcoRegistryHolders = (arcoRegistryHolders) => {
        let newList = [];
        checkedArcoRegistryHoldersValues.map(current => {
            if (current.id !== arcoRegistryHolders.id)
                newList.push(current);
        });
        setcheckedArcoRegistriesHoldersValues(newList);
    };

    const onCheckClick = (event) => {
        if (event.target.checked)
            checkAll();
        else
            uncheckAll();
    };

    const checkAll = () => {
        document.getElementById('checkboxAllIdHolders').checked = true;
        holders.map(arcoRegistryHolders => {
            const checkboxDetailsId = `checkboxDetailsHolder${arcoRegistryHolders.id}`;
            const idCheckbox = `holder-${arcoRegistryHolders.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = true;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = true;
            }
        });
        let rowIds = document.querySelectorAll('[id^="holder-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt", "bold");
            }
        }
        checkallArcoRegistriesHolders();
    };

    const checkallArcoRegistriesHolders = () => {
        let allArcoRegistryHolders = holders;
        setcheckedArcoRegistriesHoldersValues(allArcoRegistryHolders);
    };

    const uncheckAll = () => {
        document.getElementById('checkboxAllIdHolders').checked = false;
        holders.map(arcoRegistryHolders => {
            const checkboxDetailsId = `checkboxDetailsHolder${arcoRegistryHolders.id}`;
            const idCheckbox = `holder-${arcoRegistryHolders.id}`;
            if (document.getElementById(checkboxDetailsId)) {
                document.getElementById(checkboxDetailsId).checked = false;
            }
            if (document.getElementById(idCheckbox)) {
                document.getElementById(idCheckbox).checked = false;
            }
        });
        uncheckallArcoRegistriesHolders();
        let rowIds = document.querySelectorAll('[id^="holder-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
    };

    const addRegistry = (registry) => {
        if (registry) {
            if (Constants.PF === registry.personType) {
                props.addHolder(registry);
                handleShowModal();
            } else {
                PopupError({ text: "Non è consentito inserire persone giuridiche in questa lista" })
            }
        }
    }

    const uncheckallArcoRegistriesHolders = () => {
        setcheckedArcoRegistriesHoldersValues([]);
    };

    const deleteArcoArcoRegistryHoldersSubject = () => {
        let idsToDelete = checkedArcoRegistryHoldersValues.map(e => e.id)
        if (props?.selectedItem?.id) {
            actions.deleteArcoRegistriesHolders(props.selectedItem.id, idsToDelete).then(
                () => {
                    setcheckedArcoRegistriesHoldersValues([]);
                    //chiudo form se contiene l'elemento cancellato
                    props.removeHolder(idsToDelete);
                    document.getElementById('checkboxAllIdHolders').checked = false;

                },
                (errors) => {
                    PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
                })
        } else {
            setcheckedArcoRegistriesHoldersValues([]);

            props.removeHolder(idsToDelete);
            uncheckAll();
        }


    };

    const renderRows = () => {
        return holders?.map((item, index) => {
            const idCheckbox = `checkboxDetailsHolder${item.id}`;
            return (
                <React.Fragment key={index}>
                    <div className=" text-start row-table"
                        id={`holder-${item.id}`}>
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                id={idCheckbox}
                                onClick={e => onCheckBoxClick(e, item)} />
                        </div>

                        <div className='col-lg-2 div-td text-truncate' onClick={() => props.openChildRegistryModal(item)}>{item?.firstName.toUpperCase()}</div>
                        <div className='col-lg-2 div-td text-truncate' onClick={() => props.openChildRegistryModal(item)}>{item?.lastName.toUpperCase()}</div>
                        <div className='col-lg-2 div-td text-truncate' onClick={() => props.openChildRegistryModal(item)}>{item?.fiscalCode}</div>
                        <div className='col-lg-2 div-td text-truncate' onClick={() => props.openChildRegistryModal(item)}>{formatDateForDisplay(item?.birthDate)}</div>
                        <div className='col-lg-2 div-td text-truncate' onClick={() => props.openChildRegistryModal(item)}>{item?.location.toUpperCase()}</div>
                        <div className='col-lg-1 div-td text-truncate' onClick={() => props.openChildRegistryModal(item)}>{item?.province.toUpperCase()}</div>

                    </div>
                </React.Fragment>
            )
        });
    }

    return (
        <div className="col-lg-12">
            <div className="row pb-4 pt-2">
                <div className="col-lg-9">
                    <div className="btn-group" role="group" aria-label="action buttons">
                        <button type="button" disabled={checkedArcoRegistryHoldersValues.length === 0 || props.disableInsert} 
                        className="btn btn-outline-primary btn-empty px-4 btn-sm " onClick={() => deleteArcoArcoRegistryHoldersSubject()} >
                            <IDelete className={"padding-right-4px"} width="12" fill={` ${(checkedArcoRegistryHoldersValues.length === 0) ? '#FFFFFF' : '#128186'}`} />&nbsp; Elimina</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center header-table">
                        <div className='col-lg-1'>
                            <input type="checkbox"
                                id='checkboxAllIdHolders'
                                onClick={e => onCheckClick(e)}
                            />
                        </div>
                        <div className='col-lg-2' ><strong>Nome</strong></div>
                        <div className='col-lg-2' ><strong>Cognome</strong></div>
                        <div className='col-lg-2' ><strong>Codice Fiscale</strong> </div>
                        <div className='col-lg-2' ><strong>Data di nascita</strong> </div>
                        <div className='col-lg-2' ><strong>Comune</strong> </div>
                        <div className='col-lg-1' ><strong>Provincia</strong> </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 ps-0 pe-0">
                {renderRows()}
            </div>
            <div className="row pt-5">
                <div className="col-4">
                    <button onClick={handleShowModal}
                        disabled={props.disableInsert}
                        className="btn btn-primary btn-new-rel px-5"><IPlus className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; Collega anagrafica
                    </button>
                </div>
            </div>
            {showModal && <div>
                <ArcoRegistriesList
                    {...props}
                    modalShow={false}
                    showModalRegistriesList={showModal}
                    onHide={handleShowModal}
                    fromHolders={props.selectedItem}
                    fromRegistry={props.selectedItem}
                    onSelectedItem={addRegistry}
                />
            </div>}
        </div>
    )
}