import React from 'react';
import '../../../../styles/comurei-form.css';
import { ReactComponent as IAnagrafica } from "../../../../styles/images/svg/id-card.svg";
import * as actions from "../../../../actions";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustomB5";
import { BwmCheckbox } from "../../../shared/form/BwmCheckboxB5";
import { BwmInput } from "../../../shared/form/BwmInputB5";
import { BwmSelect } from "../../../shared/form/BwmSelectB5";
import { ProvinceField } from "../../application-cora/relationships/ProvinceFieldB5";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import { PopupError } from "../../../shared/PopupError";
import * as Constants from "../../../../config/Constants";
import * as Utility from "../../../shared/Utility"
import '../../../../styles/subject-crs.css';
import { CreateCommunicationModal } from "../communications/CreateCommunicationModal";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer';
import { saveSid, validateSid } from '../../../shared/SidCertsHandler';
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { isEmpty, isNotEmpty } from '../../../shared/Utility';
import { connect } from "react-redux";
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear';
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert';
import ErrorListAlert from "../../../shared/form/ErrorListAlert";
import {Formik, Field, Form} from 'formik';
import * as Yup from "yup";


class CrsForm extends React.Component {

    constructor(props) {
        super(props);
        this.createCommunicationRefData = React.createRef();
        this.nameApplication = Constants.APPLICATION_CRS;
        this.state = {
            subject: {
                id: this.props.subjectItem?.id || null,
                subjectCode: this.props.subjectItem?.subjectCode || "0" + this.props.newSubjectCode,
                fiscalCode: this.props.subjectItem?.fiscalCode || "",
                companyName: this.props.subjectItem?.companyName || "",
                address: this.props.subjectItem?.address || "",
                location: this.props.subjectItem?.location || "",
                province: this.props.subjectItem?.province || "",
                flagOicrTrust: this.props.subjectItem?.flagOicrTrust || false,
                idFiscalOicrTrust: this.props.subjectItem?.idFiscalOicrTrust || '',
                countryCode: this.props.subjectItem?.countryCode || "IT",
                country: this.props.subjectItem?.country || "ITALIA (REPUBBLICA ITALIANA)",
                communicationTypeCode: this.props.subjectItem?.communicationTypeCode || "1",
                referenceYear: this.props.subjectItem?.referenceYear || new Date().getFullYear() - 1,
                lastCommunication: this.props.subjectItem?.lastCommunication || ""
            },
            previousFiscalCode: this.props.subjectItem?.fiscalCode,//copia del codice fiscale pre-modifica.
            coraSubjectSid: {},
            errorSubjectCode: { isValid: true, msg: "" },
            errorFiscalCode: { isValid: true, msg: "" },
            errorCompanyName: { isValid: true, msg: "" },
            errorAddress: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            errorIdFiscalOicrTrust: { isValid: true, msg: "" },
            errorCountry: { isValid: true, msg: "" },
            errorReferenceYear: { isValid: true, msg: "" },
            errorLastCommunication: { isValid: true, msg: "" },
            errorSidEmail: { isValid: true, msg: "" },
            certificateFiles: [],
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: '',
            isCertSidOpened: false,
            showCreateCommunication: true
        }
    }

    initialValues = {
        id: this.props.subjectItem?.id || null,
        subjectCode: this.props.subjectItem?.subjectCode || "0" + this.props.newSubjectCode,
        fiscalCode: this.props.subjectItem?.fiscalCode || "",
        companyName: this.props.subjectItem?.companyName || "",
        address: this.props.subjectItem?.address || "",
        location: this.props.subjectItem?.location || "",
        province: this.props.subjectItem?.province || "",
        flagOicrTrust: this.props.subjectItem?.flagOicrTrust || false,
        idFiscalOicrTrust: this.props.subjectItem?.idFiscalOicrTrust || '',
        countryCode: this.props.subjectItem?.countryCode || "IT",
        country: this.props.subjectItem?.country || "ITALIA (REPUBBLICA ITALIANA)",
        communicationTypeCode: this.props.subjectItem?.communicationTypeCode || "1",
        referenceYear: this.props.subjectItem?.referenceYear || new Date().getFullYear() - 1,
        lastCommunication: this.props.subjectItem?.lastCommunication || ""
    }

    validationSchema = Yup.object().shape({
        subjectCode: Yup.string().required('Campo obbligatorio'),
        companyName: Yup.string().required('Campo obbligatorio'),
        fiscalCode: Yup.string().required('Campo obbligatorio'),
        address: Yup.string().required('Campo obbligatorio'),
        location: Yup.string().required('Campo obbligatorio'),
        province: Yup.string().required('Campo obbligatorio'),
        flagOicrTrust: Yup.boolean(),
        idFiscalOicrTrust: Yup.string().when('flagOicrTrust', {
          is: true, // When flagOicrTrust is true (checked)
          then: schema => schema.required('Campo obbligatorio'), // Make idFiscalOicrTrust required
          otherwise: schema => schema.notRequired(), // Otherwise, it's optional
        }),
        countryCode: Yup.string().required('Campo obbligatorio'),
        country: Yup.string().required('Campo obbligatorio'),
        communicationTypeCode: Yup.string().required('Campo obbligatorio'),
        referenceYear: Yup.string().required('Campo obbligatorio'),
        lastCommunication: Yup.string(),
    })

    formErrorsTabMapping = [
        { 
            errorKey: 'subjectCode',
            errorLabel: 'Codice'
        },
        { 
            errorKey: 'fiscalCode',
            errorLabel: 'Codice Fiscale'
        },
        { 
            errorKey: 'companyName',
            errorLabel: 'Ragione Sociale'
        },
        { 
            errorKey: 'address',
            errorLabel: 'Indirizzo'
        },
        { 
            errorKey: 'location',
            errorLabel: 'Sede Legale (Località)'
        },
        { 
            errorKey: 'province',
            errorLabel: 'Provincia'
        },
        { 
            errorKey: 'idFiscalOicrTrust',
            errorLabel: 'Id Fiscal OICR/TRUST'
        },
        { 
            errorKey: 'country',
            errorLabel: 'Paese'
        },
        { 
            errorKey: 'countryCode',
            errorLabel: 'Codice Paese'
        },
        { 
            errorKey: 'referenceYear',
            errorLabel: 'Anno di riferimento'
        },
        { 
            errorKey: 'lastCommunication',
            errorLabel: 'Ultima comunicazione'
        }        
    ]

    extractErrors= (errorObj, fields) => {
        const result = [];
      
        // Helper function to access nested fields using dot notation
        const getNestedField = (obj, fieldPath) => {
          return fieldPath.split('.').reduce((o, key) => (o && o[key] !== undefined) ? o[key] : null, obj);
        };
      
        // Iterate over each field and extract errors
        fields.forEach(({ errorKey, errorLabel }) => {
          const error = getNestedField(errorObj, errorKey);
          if (error) {
            result.push({ errorKey, errorLabel, errorMessage: error });
          }
        });
      
        return result;
      };

    handleChangeValue = (val, field, setValues, values) => {
        const form = Object.assign({}, values)
        Utility.updateObjProp(form, val, field);
        setValues(form)
    };

    updatePecConfiguration = (coraSubjectSid) => {
        this.setState({ ...this.state, coraSubjectSid: coraSubjectSid });
    }

    componentDidMount() {
        let coraSubjectSid = null;
        if (isNotEmpty(this.props.subjectItem?.coraSubjectSid)) {
            coraSubjectSid = this.props.subjectItem?.coraSubjectSid;
            this.setState({
                coraSubjectSid: coraSubjectSid,
            });
        }
        if (isNotEmpty(this.props.auth)) {
            if (isEmpty(coraSubjectSid?.email)) {
                this.setState({ defaultEmail: this.props.auth?.user?.login })
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let coraSubjectSid = null;
        if (isNotEmpty(nextProps.subjectItem?.coraSubjectSid)) {
            coraSubjectSid = nextProps.subjectItem?.coraSubjectSid;
            this.setState({
                coraSubjectSid: coraSubjectSid,
            });
        }

    }

    handlerChangeFiscalCode = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                fiscalCode: e.target.value.toUpperCase()
            },
            errorFiscalCode: { isValid: true, msg: "" },
            previousFiscalCode: this.state.subject?.fiscalCode
        });
    };

    checkSubjectIsPresent = (fiscalCode) => {
        if (this.props.showModal === true) {
            const axiosInstance = axiosService.getInstance();
            axiosInstance.get(`/api/crs/custom/crs-subjects-fiscal-code/${fiscalCode}`)
                .then(res => {
                    if (isNotEmpty(res)) {
                        this.props.handleCloseModal();
                        PopupError(
                            {
                                text: "Soggetto già presente in elenco"
                            }
                        );
                    }
                });
        }
    };

    handlerChangeCompanyName = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                companyName: e.target.value.toUpperCase()
            },
            errorCompanyName: { isValid: true, msg: "" },
        });
    };

    handlerChangeAddress = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                address: e.target.value.toUpperCase()
            },
            errorAddress: { isValid: true, msg: "" },
        });
    };

    handlerChangeOicrTrust = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                flagOicrTrust: e.target.checked,
            }
        });
    };

    handlerChangeFiscalOicrTrust = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                idFiscalOicrTrust: e.target.value.toUpperCase().trim()
            },
            errorIdFiscalOicrTrust: { isValid: true, msg: "" }
        });
    };

    handleFiscalCode = (fiscalCode, setValues, values) => {
        if (fiscalCode) {
            actions.getBaseRegistryByFiscalCode(fiscalCode).then(
                (baseRegistry) => {
                    if (baseRegistry) {
                        setValues({
                            ...values,
                            companyName: baseRegistry?.companyName || '',
                            location: baseRegistry?.location?.location || '',
                            province: baseRegistry?.location?.province || ''
                        })
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
            actions.getCoraSubjectSidByFiscalCode(fiscalCode, "crs").then(
                (coraSubjectSid) => {
                    if (coraSubjectSid?.id) {
                        this.setState({
                            coraSubjectSid: coraSubjectSid,
                            errorCertificates: { isValid: true, msg: "" },
                            errorPassword: { isValid: true, msg: "" },
                            errorSidNumber: { isValid: true, msg: "" },
                            errorEmail: { isValid: true, msg: "" }
                        });
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
        }
    };

    handleInputAutocompile = (newLocation, setValues, values) => {
        newLocation = newLocation.toUpperCase()
        if (newLocation) {
            actions.getLocation(newLocation).then(
                (loc) => {
                    if (loc) {
                        setValues({
                            ...values,
                            location: loc.location,
                            province: loc.province,
                        });
                    } else {
                        setValues({
                            ...values,
                            location: newLocation
                        });
                    }
                },
                (errors) => {
                    setValues({
                        ...values,
                        location: ''
                    })
                })
        } else {
            setValues({
                ...values,
                location: ''
            })           
        }
    };

    handleInputAutocompileCountry = (e, setValues, values) => {
        let newCountry = e.target.value;
        setValues({
            ...values,
            countryCode: newCountry.toUpperCase()
        });
        let countriesFilter = this.props.countries.filter(country => {
            return country.name === newCountry
        })
        if (countriesFilter.length === 1) {
            setValues({
                ...values,
                countryCode: countriesFilter[0].name,
                country: countriesFilter[0].key
            });
        }        
        if (countriesFilter.length === 0) {
            setValues({
                ...values,
                countryCode: newCountry.toUpperCase(),
                country: ''
            });
        }
    };

    setStateFunction = (value) => {
        this.setState(value)
    }

    handleProvinceChange = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                province: e.target.value.toUpperCase().trim()
            },
            errorProvince: { isValid: true, msg: "" }
        });
    };

    handleCommunicationTypeClick = (e) => {
        this.setState({
            showCreateCommunication: false,
            subject: {
                ...this.state.subject,
                communicationTypeCode: e.target.value
            }
        });
    };

    handlerChangeReferenceYear = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                referenceYear: e.target.value
            }
        });
    };

    handlerChangeLastCommunication = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                lastCommunication: e.target.value.toUpperCase()
            }
        });
    };

    handleValidation = () => {
        let isValid = true;
        if (!this.state.subject.companyName) {
            this.setState({
                errorCompanyName: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.fiscalCode) {
            this.setState({
                errorFiscalCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.address) {
            this.setState({
                errorAddress: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.location) {
            this.setState({
                errorLocation: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.province) {
            this.setState({
                errorProvince: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.referenceYear) {
            this.setState({
                errorReferenceYear: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (this.state.subject.flagOicrTrust && !this.state.subject.idFiscalOicrTrust) {
            this.setState({
                errorIdFiscalOicrTrust: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.configurationSidValidation()) {
            isValid = false;
        }

        return isValid;
    };

    handleFormSubmit = (values, sendComm) => {
        this.setState((prevState) => ({
            subject: {
                ...values
            }
        }))
        this.updateAndClose()
    };

    handleFormUpdateAll = async (validateForm, values, sendComm) => {
        const formErrors = await validateForm(values);

        // Check if there are any errors
        if (!(Object.keys(formErrors).length === 0)) return;        

        this.setState((prevState) => ({
            subject: {
                ...values
            }
        }))
        this.updateAll(sendComm)
    };

    updateAll = (sendComm) => {
        if (sendComm === true) {
            //questa casistica non può più avverarsi
            const lastYear = new Date().getFullYear() - 1;
            if (this.state.subject.referenceYear < lastYear) {
                PopupAlertCommunicationYear(this.state.subject.referenceYear, lastYear
                    , () => this.saveCrsSubject(sendComm)
                    , () => {
                        this.setState((prevState) => ({
                            subject: {
                                ...prevState.subject,
                                referenceYear: lastYear
                            }
                        }), () => this.saveCrsSubject(sendComm));
                    })
                return;
            }
        }
        this.saveCrsSubject(sendComm);


    };

    updateAndClose = (sendComm) => {
        this.updateAll(sendComm);
        debugger
        this.props.handleCloseModal();
    }

    saveCrsSubject = (sendComm) => {
        saveSid(this.state.certificateFiles?.files
            , this.state.coraSubjectSid
            , this.state.subject?.fiscalCode
            , "crs"
            , (res) => this.addCrsSubject(this.state.subject, sendComm, res)
            , (errors) => {
                PopupError({ ...this.props, text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR })
                this.resetError();
                this.resetFields();
            })

    }

    configurationSidValidation = () => {

        return validateSid(
            this.state.coraSubjectSid.sidNumber
            , this.state.coraSubjectSid.email
            , (state) => this.setState(state)
            , this.state.errorSidNumber
            , this.state.errorEmail
            , "errorSidNumber"
            , "errorEmail"
        )

    };

    handleChangeStartDate = (date) => {
        if (date) {
            this.state.coraSubjectSid.startDate = date;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.startDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeExpirationDate = (val) => {
        if (val) {
            this.state.coraSubjectSid.expirationDate = val;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.expirationDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeEncryptionBits = (val) => {
        this.state.coraSubjectSid.encryptionBits = val;
        this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
    }

    /* Validation onChange */
    handleChangeSid = (val, error, errorMsg) => {
        this.state.errorInConfigSid = '';
        let valueInput = val.target.value;
        if (isEmpty(valueInput)) {
            error.isValid = false;
            error.msg = errorMsg;
        } else {
            error.isValid = true;
            error.msg = '';
        }
        let newValue = valueInput.toUpperCase().trim();
        if (val.target.name === "password") {
            this.setState(prevState => ({
                coraSubjectSid: {
                    ...prevState.coraSubjectSid,
                    password: newValue,
                },
                error
            }));
        } else if (val.target.name === "sidNumber") {
            this.setState(prevState => ({
                coraSubjectSid: {
                    ...prevState.coraSubjectSid,
                    id: null,
                    sidNumber: newValue,
                },
                error
            }));
        } else if (val.target.name === "email") {
            this.setState(prevState => ({
                coraSubjectSid: {
                    ...prevState.coraSubjectSid,
                    email: newValue,
                },
                error
            }));
        }


    };


    handlerChangeFlagPec = (e) => {
        let checked = e.target.checked
        this.setState(prevState => ({

            coraSubjectSid: {
                ...prevState.coraSubjectSid,
                flagPec: checked
            }
        }));
    }

    addCrsSubject = (newSubject, sendComm, sid) => {
        const shouldVerifyOnComurei = isEmpty(newSubject.id) || (this.state.previousFiscalCode !== newSubject.fiscalCode);
        newSubject.coraSubjectSid = sid;
        actions.addCrsSubject(newSubject).then(
            (response) => {
                this.setState({
                    subject: response
                });

                if (shouldVerifyOnComurei) {
                    actions.verifyCrsSubjectOnComurei(newSubject.fiscalCode).then(
                        (res) => {
                            if (res === false) {
                                ComureiSubjectNotPresentAlert({ fiscalCode: newSubject.fiscalCode, denomination: newSubject.companyName });
                            } else {
                                PopupSuccess({ text: "Soggetto salvato correttamente" })
                            }
                        },
                        (errors) => {
                            console.log(errors);
                            PopupSuccess({ text: "Soggetto salvato correttamente" })
                        }
                    )
                } else if (sendComm) {
                    if (document.getElementById("subject-modal")) {
                        document.getElementById("subject-modal").style.display = "none";
                    }
                    this.createCommunicationRefData.current.clickButtonSend();
                } else {
                    PopupSuccess({ text: "Soggetto salvato correttamente" })
                }
                this.props.getSubjects()
            },
            (errors) => {

                if (errors.status === 511) {
                    PopupError(
                        {
                            text: errors.status === 511 ?
                                'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
                                :
                                Constants.APPLICATION_GENERIC_ERROR
                        }
                    );
                } else {
                    PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
                }
            }
        );
    };

    setCertificateFilesCustom = (val) => {
        /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
        this.state.isCertSidOpened = true;

        if (isNotEmpty(val))
            this.state.certificateFiles = val
    };



    resetError = () => {
        this.setState({
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: ''
        });
    };

    resetFields = () => {
        this.setState({
            coraSubjectSid: {}
        })
    };

    render() {
        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={(values) => this.handleFormSubmit(values, false)}
            >
                {({handleChange, handleBlur, values, errors, touched, setValues, submitForm, validateForm}) => (
                    <Form>
                        {errors && typeof errors === 'object' && Object.keys(errors).length > 0 && (
                            <ErrorListAlert errors={this.extractErrors(errors, this.formErrorsTabMapping)} />
                        )}            
                        <div className="row-detail bg-gray pe-3 pb-4 ps-3">
                            <div className="row crs" id={`anagrafica-${this.props.subject?.id || 0}`}>
                                <div className={"col-6"}>
                                    <div className="soggetto-crs big">
                                        {/* <div className='row'>
                                            <h5 className="ms mb-4"><IAnagrafica fill="#000" width="25" />Anagrafica</h5>
                                        </div> */}
                                        <div className='row'>
                                            <div className='col-md-2 me-2'>
                                                {/* <label>Codice</label> */}
                                                <Field name="subjectCode">
                                                    {({field}) => (                                                   
                                                        <BwmInput 
                                                            {...field}
                                                            name='subjectCode'
                                                            className='form-control'
                                                            label='Codice'
                                                            value={values.subjectCode}
                                                            maxLength="16"
                                                            disabled="disabled"
                                                            error={errors.subjectCode}
                                                            touched={touched.subjectCode}                                                            
                                                        />
                                                    )}
                                                </Field>                                                  
                                            </div>
                                            <div className='col-md-3 mb-2 me-2'>
                                                <Field name="fiscalCode">
                                                    {({field}) => (                                                
                                                        <BwmInput 
                                                            {...field}
                                                            name='fiscalCode'
                                                            label={!values.flagOicrTrust ? 'Codice Fiscale' : 'C. Fiscale del Comunicante'}
                                                            className='form-control'
                                                            value={values.fiscalCode}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            error={errors.fiscalCode}
                                                            touched={touched.fiscalCode}
                                                            maxLength="11"
                                                            onBlur={e => this.handleFiscalCode(e.target.value, setValues, values)}
                                                            />
                                                    )}
                                                </Field>        
                                            </div>
                                            
                                            <div className='col-md-3 me-2'>
                                            <Field name="address">
                                            {({field}) => (       
                                                <BwmInput 
                                                    {...field}
                                                    name='address'
                                                    label='Indirizzo'
                                                    className='form-control'
                                                    value={values.address}
                                                    onChange={(e) => {
                                                        const { name, value } = e.target;
                                                        handleChange({
                                                            target: {
                                                                name,
                                                                value: value.toUpperCase(),
                                                            },
                                                        });
                                                    }}
                                                    error={errors.address}
                                                    touched={touched.address}
                                                    maxLength="100"
                                                    />
                                                )}
                                                </Field>   
                                            </div>
                                            <div className='col-md-3 me-2'>
                                                <Field name="location">
                                                    {({ field }) => (
                                                        <AutoCompileCustom
                                                            label='Sede Legale (Località)'
                                                            id="location"
                                                            handleInputAutocompile={e => this.handleInputAutocompile(e, setValues, values)}
                                                            filtro={this.props.locations}
                                                            value={values.location || ""}
                                                            error={errors.location}
                                                            touched={touched.location}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-2 col-lg-2 me-2">
                                            <Field name="location">
                                                {({ field }) => (
                                                <ProvinceField 
                                                    province={values.province || ""}
                                                    placeholder="Prov. EE per Estero"
                                                    label="EE per Estero"
                                                    onChange={(e) => {
                                                        const { name, value } = e.target;
                                                        handleChange({
                                                            target: {
                                                                name,
                                                                value: value.toUpperCase().trim(),
                                                            },
                                                        });
                                                    }}
                                                    error={errors.province}
                                                    touched={touched.province} 
                                                    />
                                                )}
                                                </Field>                                                    
                                            </div>
                                            <div className='col-md-9 mb-2'>
                                                <Field name="countryCode">
                                                    {({ field }) => (                                                
                                                        <BwmSelect
                                                            options={this.props.countries}
                                                            name='country'
                                                            id="country"
                                                            label='Paese'
                                                            className='form-control'
                                                            onChange={e => this.handleInputAutocompileCountry(e, setValues, values)}
                                                            value={values.countryCode || ""}
                                                            error={errors.countryCode}
                                                            touched={touched.countryCode}
                                                            showCode={true}
                                                            nameKey={true}
                                                        />
                                                    )}
                                                </Field>                                                
                                            </div>
                                            <div className='col-md-9 me-2'>
                                                <Field name="companyName">
                                                    {({field}) => (                                                   
                                                        <BwmInput 
                                                            {...field}
                                                            name='companyName'
                                                            label={!values.flagOicrTrust ? 'Ragione Sociale' : 'Denominazione OICR o TRUST'}
                                                            className='form-control'
                                                            value={values.companyName}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            error={errors.companyName}
                                                            touched={touched.companyName}
                                                            maxLength="70"
                                                        />
                                                    )}
                                                </Field>   
                                            </div>
                                            <div className='col-md-2 mt-3'>
                                                <Field name="flagOicrTrust">
                                                    {({ field }) => (
                                                        <BwmCheckbox
                                                            name={`flagOicrTrust`}
                                                            label='OICR o TRUST'
                                                            className='form-control'
                                                            onChange={(e) => {
                                                                // Custom logic here if necessary
                                                                this.handleChangeValue(e.target.checked, "flagOicrTrust", setValues, values);
                                                                field.onChange(e); // Still call Formik's onChange to update the value
                                                            }}
                                                            checked={field.value}
                                                            error={errors.flagOicrTrust}
                                                            touched={touched.flagOicrTrust}
                                                        />
                                                    )}
                                                </Field>                                                
                                            </div>
                                            <div className="col-7">
                                                <div className="input-group flex-column h-100 justify-content-around">
                                                    {/* Radio Buttons Section */}
                                                    <div className="row mb-3 mt-3">
                                                    <div className="col-auto">
                                                        <div className="inputRadio me-3 me-2">
                                                            <input
                                                                hidden={true}
                                                                className="form-check-input"
                                                                type="radio"
                                                                name={`communicationTypeCode`}
                                                                checked={this.state.subject.communicationTypeCode === '1'}
                                                                id={`ct1`}
                                                                value={'1'}
                                                                onChange={this.handleCommunicationTypeClick}
                                                            />
                                                            <label className="form-check-label" htmlFor={`ct1`}>
                                                                <img
                                                                width="20"
                                                                className="me-2"
                                                                src={this.state.subject.communicationTypeCode === '1' ? radioSel : radioUnsel}
                                                                />
                                                                NUOVI DATI
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto me-3">
                                                        <div className="inputRadio">
                                                        <input
                                                            hidden={true}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`communicationTypeCode`}
                                                            checked={this.state.subject.communicationTypeCode === '2'}
                                                            id={`ct2`}
                                                            value={'2'}
                                                            disabled={true}
                                                            onChange={this.handleCommunicationTypeClick}
                                                        />
                                                        <label className="form-check-label" htmlFor={`ct2`}>
                                                            <img
                                                            width="20"
                                                            className="me-2"
                                                            src={this.state.subject.communicationTypeCode === '2' ? radioSel : radioUnsel}
                                                            />
                                                            SOSTITUTIVA
                                                        </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="inputRadio">
                                                        <input
                                                            hidden={true}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={`communicationTypeCode`}
                                                            checked={this.state.subject.communicationTypeCode === '3'}
                                                            id={`ct3`}
                                                            value={'3'}
                                                            disabled={true}
                                                            onChange={this.handleCommunicationTypeClick}
                                                        />
                                                        <label className="form-check-label" htmlFor={`ct3`}>
                                                            <img
                                                            width="20"
                                                            className="me-2"
                                                            src={this.state.subject.communicationTypeCode === '3' ? radioSel : radioUnsel}
                                                            />
                                                            ANNULLAMENTO
                                                        </label>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    {/* Fields Section */}
                                                    <div className='row'>
                                                    <div className='col-md-6 mb-3'>
                                                        <div className="form-group modal-80w">
                                                        <Field name="referenceYear">
                                                            {({ field }) => (
                                                            <BwmInput 
                                                                {...field}
                                                                name='referenceYear'
                                                                label='Anno di riferimento'
                                                                className='form-control'
                                                                value={values.referenceYear}
                                                                error={errors.referenceYear}
                                                                touched={touched.referenceYear}
                                                                maxLength="4"
                                                            />
                                                            )}
                                                        </Field>    
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6 mb-3'>
                                                        <div className="form-group modal-80w">
                                                        <Field name="lastCommunication">
                                                            {({ field }) => (
                                                            <BwmInput 
                                                                {...field}
                                                                name='lastCommunication'
                                                                label='Ultima comunicazione'
                                                                className='form-control borde-bottom-0'
                                                                value={this.state.subject.lastCommunication}
                                                                error={errors.lastCommunication}
                                                                touched={touched.lastCommunication}
                                                                maxLength="100"
                                                                readOnly={true}
                                                            />
                                                            )}
                                                        </Field>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4 m-2 oicrContainer'>
                                                <div className={`oicr ${values.flagOicrTrust && 'show'}`}>
                                                    <span>Id Fiscal OICR/TRUST</span>
                                                    <div className='form-group' data-tip="ID OICR senza p. giuridica rilasciato da Banca d'Italia (5 caratteri) oppure ID Fiscale TRUST (16 caratteri)">
                                                        <div className='input-group'>
                                                            <Field name="idFiscalOicrTrust">
                                                                {({ field }) => (          
                                                                    <BwmInput
                                                                        name="idFiscalOicrTrust"
                                                                        value={values.idFiscalOicrTrust}
                                                                        type="text"
                                                                        onChange={(e) => {
                                                                            const { name, value } = e.target;
                                                                            handleChange({
                                                                                target: {
                                                                                    name,
                                                                                    value: value.toUpperCase().trim(),
                                                                                },
                                                                            });
                                                                        }}
                                                                        error={errors.idFiscalOicrTrust}
                                                                        touched={touched.idFiscalOicrTrust} 
                                                                    />
                                                                )}
                                                            </Field>      
                                                        </div>
                                                    </div>
                                                    {!this.state.errorIdFiscalOicrTrust.isValid &&
                                                        <div className="text-danger">{this.state.errorIdFiscalOicrTrust.msg}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-6"}>
                                    <div className={"row"}>
                                        <div className="soggetto-crs auto">
                                            <SidContactFormContainer  {...this} nameApplication={this.nameApplication} />
                                            {
                                                !this.props.showModal &&
                                                <div className="panel-info align-items-end">
                                                    <h5>Codice soggetto: {this.state.subject?.id}</h5>
                                                    {
                                                        this.state.coraSubjectSid &&
                                                        <h5>Codice SID: {this.state.coraSubjectSid?.id}</h5>

                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                                <button
                                    type="button" 
                                    className="btn btn-outline-primary btn-empty px-4 btn-sm"
                                    onClick={this.props.handleCloseModal}><IUndo className={"padding-right-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                                </button>
                                <CreateCommunicationModal
                                    {...this.props}
                                    ref={this.createCommunicationRefData}
                                    subjectId={this.state.subject?.id}
                                    emailAddress={this.state.subject?.coraSubjectSid?.email}
                                    generateEmpty={true}
                                    classCustom={"display-none"}
                                    closeSubjectModal={this.props.handleCloseModal}
                                    subject={this.state.subject}
                                />
                                <button 
                                    type="button" 
                                    onClick={() => this.handleFormUpdateAll(validateForm, values, true)}
                                    disabled={this.props.disableCreateCommunication}
                                    className="btn btn-primary btn-new-rel px-5"><ITextFile className={"padding-right-4px svg"} width="16" fill={`#FFFFFF`} />&nbsp; CREA COMUNICAZIONE VUOTA
                                </button>
                                <button
                                    type="submit" className="btn btn-primary btn-new-rel px-5"><ISave className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                                </button>
                            </div>
                        </div>
                    </Form>
                )
            }
            </Formik>    
        )
    }

}
function mapStateToProps(state) {

    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(CrsForm);
