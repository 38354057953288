import React, { useEffect, useState } from "react";

import { DraggableList } from "../form/lists/DraggableList";
import { TableUtils } from "./TableUtils";
import { SelectableElement } from "../form/lists/SelectableElement";
import { usePopper } from 'react-popper';

export function HeaderFilterDropdown({
    headers = []
    , hiddenColumnsProps = []
    , show = false
    , onHide = () => { }
    , onCleanCache = () => { }
}) {

    const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnsProps);
    const [headersState, setHeadersState] = useState([]);
    const [showColumnFilter, setShowColumnFilter] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: 'arrow', options: {} }],
    });

    useEffect(() => {
        setHeadersState(headers)
    }, [headers]);

    const toggleColumn = (column) => {
        let _hiddenColumns
        if (TableUtils.isHiddenColumn(hiddenColumns, column)) {
            _hiddenColumns = hiddenColumns.filter(v => v.fieldName !== column.fieldName)
            setHiddenColumns(_hiddenColumns);
        } else {
            _hiddenColumns = hiddenColumns.concat(column)
            setHiddenColumns(_hiddenColumns);
        }
        onHide(_hiddenColumns, headersState)
    };

    const updateHeadersOrder = (reordered = []) => {
        const _headersState = TableUtils.updateHeadersOrder(headersState, reordered)
        setHeadersState(_headersState);
        onHide(hiddenColumns, _headersState)
    }

    return (
        <div>
            <button
                type="button"
                ref={setReferenceElement}
                className="btn btn-transparent btn-xs"
                onClick={() => setShowColumnFilter(!showColumnFilter)}
            >
                <i className="thx-table q-icon" style={{marginRight: '5px'}}/>
                <span>Vista</span>
            </button>

            {showColumnFilter && (
                <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popper">
                    <DraggableList
                        elements={headersState}
                        listItemComponent={(element) => {
                            return <SelectableHeader
                                key={`header-filter-${element}`}
                                header={element}
                                onChange={toggleColumn}
                                hiddenColumns={hiddenColumns}
                            />
                        }}
                        onReorder={(reordered) => {
                            updateHeadersOrder(reordered)
                        }}
                    />
                    <div className="d-flex mb-2">
                        <div className="col-12 col-md-6 px-1">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => setShowColumnFilter(!showColumnFilter)}
                            >
                                <span>Chiudi</span>
                            </button>
                        </div>
                        <div className="col-12 col-md-6 pe-2">
                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={onCleanCache}
                            >
                                <span>Ripristina</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

function SelectableHeader({
    header,
    hiddenColumns,
    onChange
}) {

    return <SelectableElement
        value={header}
        showIcon="true"
        onChange={() => onChange(header)}
        defaultChecked={!TableUtils.isHiddenColumn(hiddenColumns, header)}
        label={header.displayedName}
    />

}