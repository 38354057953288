import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function ErrorListAlert(props) {
  const [show, setShow] = useState(true);
  const errors = props.errors;

  // Effetto per riaprire l'alert ogni volta che cambiano gli errori
  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      setShow(true); // Riapre l'alert quando arrivano nuovi errori
    }
  }, [errors]);

  // Funzione per ottenere il valore più profondo di un oggetto
  function getInnermostValue(obj) {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        return getInnermostValue(obj[key]);
      } else {
        return obj[key];
      }
    }
  }

  return (
    show && (
      <Alert className="ms-3 me-3" variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          <span>
            <FontAwesomeIcon className="me-4" icon={faExclamationCircle} />
            Dati Incompleti
          </span>
        </Alert.Heading>

        <div>
          {Object.entries(errors).map(([key, value]) => (
            <span key={key}>
              <b>{value.errorLabel}:</b> {value.errorMessage},
              <span className="me-2"></span>
            </span>
          ))}
        </div>
      </Alert>
    )
  );
}

export default ErrorListAlert;
