import React, { useEffect, useRef, useState } from 'react';
import { StarTooltip } from '../tooltips/Tooltip';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';


export const BwmInput = ({

  label,
  type,
  Icon,
  onIconClick = () => undefined,
  clickableIcon = true,
  iconTip,
  placeholder,

  className, onChange, name, value, onKeyPress, onFocus, maxLength, error, touched, pattern, step
  , onBlur = () => { }, readOnly, id, onInput, disabled, autoComplete,
  dataTip
  ,autofocus=false
}) => {

  //in certi casi, il cursore può saltare alla fine. questo stato riporta il cursore al posto giusto
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

//Focus su primo render, se richiesto
useEffect(()=>{
  if(autofocus){
    ref.current.focus();
  }
},[])

  useEffect(() => {
    const input = ref.current;
    if (input && ["text", "search", "URL", "tel", "password"].includes(input.type)) input.setSelectionRange(cursor || 0, cursor || 0);
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  const inputWrapperClass = `input-wrapper w-100 ${touched && error !== undefined ? 'has-error' : ''}`;

  return (
    <div className={inputWrapperClass}>
      <FloatingLabel controlId="floatingLabel" label={label}>
        <Form.Control ref={ref} id={id} type={type} name={name} onChange={handleChange} value={value} placeholder={label}
          onFocus={onFocus} maxLength={maxLength} pattern={pattern} step={step} readOnly={readOnly} 
          onBlur={onBlur} onInput={onInput} disabled={disabled}
          autoComplete={autoComplete} className={className} 
          isInvalid={touched ? error : undefined} 
        />
      </FloatingLabel>

      {/* {touched && error !== undefined &&
        <div className="text-danger">{error}</div>
      } */}

    </div>

  )
}
