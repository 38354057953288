import React from 'react';
import * as actions from "../../../../actions";
import { connect } from "react-redux";
import '../../../../styles/monthlyList.css'
import { CreateCommunicationModal } from "./CreateCommunicationModal";
import { PopupError } from "../../../shared/PopupError";
import { ReactComponent as IExclude } from '../../../../styles/images/svg/do-not-enter.svg';
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility';


class MonthlyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            allCheck: false,
            checkedItems: new Map(),
            isDisabled: true,
            endDateUpload: null,
            commTypeUpload: '',
        })
        this.handleCheckChangeCommunications = this.handleCheckChangeCommunications.bind(this);
        this.isPeriodValid = this.isPeriodValid.bind(this);
    }
    
    
    componentDidMount(){
        this.getMonthlyCommunications(this.props.subjectId);
    }

    getMonthlyCommunications = (subjectId) => {
        this.props.dispatch(actions.getCommunicationsToSendBySubjectId(subjectId, this.props.delayedCommunicationDate));
    };

    handleCheckChangeCommunications(event) {
        if (event !== undefined) {
            let checkedItems = this.state.checkedItems;
            checkedItems.set(event.target.value, event.target.checked);
            this.setState({checkedItems: checkedItems});
            if (!event.target.checked) {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.remove("bg-cobalt", "bold");
            } else {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.add("bg-cobalt", "bold");
            }
            for (const checkedItem of checkedItems) {
                if (checkedItem[1]) {
                    return;
                }
            }
            if (checkedItems.size > 0) {
                checkedItems.clear();
                this.resetCheckBox();
            }
            
        }
    }

    handleAllCheckChangeCommunications(event, communicationsToSend) {
        this.setState({ allCheck: event.target.checked });
        if (event.target.checked) {
            if (this.state.checkedItems.size > 0) {
                this.state.checkedItems.clear();
                this.resetCheckBox();
            } else {
                let items = new Map();
                Object.keys(communicationsToSend).map(key => {
                    communicationsToSend[key].map((relationship, index) => {
                        items.set(relationship.id, true)
                    })
                });
                let rowIds = document.querySelectorAll('[id^="row-"]');
                for (let i = 0; i < rowIds.length; i++) {
                    if (rowIds[i].localName === "div") {
                        rowIds[i].classList.add("bg-cobalt", "bold");
                    }
                }
                this.setState({ checkedItems: items })
            }
        } else {
            this.state.checkedItems.clear();
            let rowIds = document.querySelectorAll('[id^="row-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.remove("bg-cobalt", "bold");
                }
            }
        }
    };

    handlerDontSendCommunication = () => {
        for (const checkedItem of this.state.checkedItems) {
            if (checkedItem[1]) {
                actions.removeNextCommunication([parseInt(checkedItem[0])]).then(
                    () => {
                        this.getMonthlyCommunications(this.props.subjectId);
                    },
                    (errors) => PopupError({ ...this.props, text: errors })
                )
            }
        }
        this.resetCheckBox();
    };

    /**
     * 
     * @param {string} year 
     * @param {string} month 
     * @returns {boolean} true se precedente a mese anno corrente, se no false
     */
    isPeriodValid = (year, month) => {

        const now = isNotEmpty(this.props.delayedCommunicationDate)? new Date(this.props.delayedCommunicationDate):new Date();
        const currYear = now.getFullYear();
        const currMonth = now.getMonth()+1;
        const intYear = parseInt(year);
        const intMonth = parseInt(month);
        if (intYear === currYear) {
            if (intMonth >= currMonth) {
                return false
            }
        } else if (intYear > currYear) {
            return false
        }
        return true
    }

    resetCheckBox = () => {
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }

        this.setState({ checkedItems: new Map() });
        this.setState({ allCheck: false });
        document.getElementsByName('relationshipCheck').forEach(el => {
            if (el.checked) {
                el.checked = false
            }
        })
    }

    renderRowHeader(communicationsToSend) {
        return (
            <React.Fragment>
                <div className='col w-5 text-center div-td'>
                    <input type="checkbox" checked={this.state.allCheck} onChange={(e) => this.handleAllCheckChangeCommunications(e, communicationsToSend)} />
                </div>
                <div className='col w-8 div-td'>Periodo</div>
                <div className='col w-10 div-td'>Anagrafica</div>
                <div className='col w-10 div-td'>Codice fiscale</div>
                <div className='col w-15 div-td'>Ruolo</div>
                <div className='col w-8 div-td'>Data Inizio</div>
                <div className='col w-8 div-td'>Data Fine</div>
                <div className='col w-25 div-td'>Rapporto</div>
                <div className='col w-40 div-td'>Codice Univoco</div>
            </React.Fragment>
        )
    }

    renderRows(key, relationships =[]) {
        return relationships.map((relationship, index) => {
            let checked = this.state.checkedItems.get(relationship.id);
            return (
                <React.Fragment key={"rapporto-" + index}>
                    <div className="text-start row-table row-table-without-hover" id={`row-${index}`}>
                        <div className='col w-5 text-center div-td'>
                            <input type="checkbox" aria-label="Checkbox for following text input" id={`row-${index}`}
                                name="relationshipCheck"
                                value={relationship.id} checked={checked}
                                onChange={(e) => this.handleCheckChangeCommunications(e)}
                            />
                        </div>
                        <div className='col w-8 div-td text-truncate'>{key}</div>
                        <div
                            className='col w-10 div-td text-truncate'>{relationship.completeName}</div>
                        <div className='col w-10 div-td text-truncate'>{relationship.fiscalCode}</div>
                        <div
                            className='col w-15 div-td text-truncate'>{relationship.coraRole ? relationship.coraRole.code + " - " + relationship.coraRole.description : ""}</div>
                        <div
                            className='col w-8 div-td text-truncate'>{formatDateForDisplay(relationship.startDate)}</div>
                        <div
                            className='col w-8 div-td text-truncate'>{relationship.endDate ? formatDateForDisplay(relationship.endDate) : ""}</div>
                        <div
                            className='col w-25 div-td text-truncate'>{relationship.relationshipType.code} - {relationship.relationshipType.description}</div>
                        <div className='col w-40 div-td text-truncate'>{relationship.identifier}</div>
                    </div>
                </React.Fragment>
            )
        });
    }

    renderGroups(groupedRelationships, communicationTypes) {
        return Object.keys(groupedRelationships).map((key, index) => {
            let splitted = key.split("-");

            const year = splitted[0];
            const month = splitted[1];
            let yearAndMonth = year + "-" + month;
            let communicationTypeDesc = "";
            let communicationTypeCode = "";
            for (var i = 0; i < communicationTypes.length; i++) {
                if (communicationTypes[i].code === splitted[3]) {
                    communicationTypeDesc = communicationTypes[i].description;
                    communicationTypeCode = communicationTypes[i].code;
                    break;
                }
            }

            let dispatchType = splitted[2];
            let dispatchTypeDesc = "";
            if (dispatchType === "1") {
                dispatchTypeDesc = "Ordinario";
            } else {
                dispatchTypeDesc = "Straordinario";
            }

            return (
                <div key={"gruppo-" + index}>
                    <div className="monthly bg-blue">
                        <div className="me-5"><strong className="me-1">Periodo di
                            riferimento:</strong><span>{yearAndMonth}</span>
                        </div>
                        <div className="me-5"><strong className="me-1">Tipo
                            Comunicazione:</strong><span>{communicationTypeCode === "2" ? communicationTypeCode + " - AGGIORNAMENTO/CHIUSURA RAPPORTO TARDIVA" : communicationTypeCode + " - " + communicationTypeDesc}</span>
                        </div>
                        <div className="me-5"><strong className="me-1">Tipologia di
                            Invio:</strong><span>{dispatchTypeDesc}</span>
                        </div>

                        {(!this.isPeriodValid(year, month)) && <div className="me-5">
                            <strong className='period-error'>{`ATTENZIONE: il periodo ${yearAndMonth} può essere comunicato dal primo giorno del mese successivo`} </strong>
                        </div>}
                    </div>
                    <div className="monthly-row">{this.renderRows(key, groupedRelationships[key])}</div>
                </div>
            )
        });
    }


    render() {
        const { communicationsToSend, communicationTypes } = this.props;

        const hasNoSelectedItems = this.state.checkedItems.size === 0;
        return (
            <React.Fragment>
                
                <div className="row header-btn-relationship">
                    <div className="col-8">
                        <div className="btn-group" role="group" aria-label="action buttons">
                            <button type="button" disabled={hasNoSelectedItems} className={`btn  btn-empty px-4 btn-sm 
                            ${hasNoSelectedItems ? 'disabled' : ''}`} onClick={(e) => this.handlerDontSendCommunication()}>
                                <IExclude className={"padding-right-4px"} fill={` ${hasNoSelectedItems === 0 ? '#FFFFFF' : '#128186'}`} width="15" />&nbsp;
                                Escludi dalla comunicazione
                            </button>
                        </div>
                    </div>

                    <div className="col-4">
                        <CreateCommunicationModal
                            type="MONTHLY"
                            subjectId={this.props.subjectId}
                            subject={this.props.subject}
                            reloadList={this.getMonthlyCommunications}
                            isDisabled={Object.keys(communicationsToSend).length <= 0}
                            delayedCommunicationDate={this.props.delayedCommunicationDate}
                        />
                    </div>
                </div>
                <div className="text-start header-table">
                    {this.renderRowHeader(communicationsToSend)}
                </div>
                {this.renderGroups(communicationsToSend, communicationTypes)}

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        subject: state.subject.data,
        communicationsToSend: state.communicationsToSend.data,
        communicationTypes: state.communicationTypes.data,
        relationshipTypes: state.relationshipTypes.data,
        relationshipLocations: state.locations.data,
        currencies: state.currencies.data,
        roles: state.roles.data,
        delayedCommunicationDate: state.delayedCommunicationDate
    }
}

export default connect(mapStateToProps)(MonthlyList);


