import React from "react";
import Modal from "react-bootstrap/Modal";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import Row from "react-bootstrap/Row";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as IExport } from "../../../../styles/images/svgs/regular/file-export.svg";
import { BwmInput } from "../../../shared/form/BwmInput";
import { PopupError } from "../../../shared/PopupError";
import { isEmpty } from "../../../shared/Utility";

export class CrsSubjectsExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
      responseModal: false,
      responseKo: "",
      responseOk: "",
      emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login,
      emailModified: false,
      errorEmail: { isValid: true, msg: "" },
    };
  }

  componentWillReceiveProps() {
    this.setState({
      emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login,
    });
  }

  closeModal = () => {
    this.setState({ showModal: false });
    this.setState({
      emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login,
    });
  };

  closeResponseModal = () => {
    this.setState({ responseModal: false });
    this.setState({
      emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login,
    });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };


  handlerEmail = (e) => {
    this.setState({ errorEmail: { isValid: true, msg: "" } });
    this.setState({ emailAddress: e.target.value });
    this.setState({ emailModified: true });
  };

  handlerValidation = () => {
    if (isEmpty(this.state.emailAddress)) {
      this.setState({
        errorEmail: { isValid: false, msg: "Campo obbligatorio" },
      });
      return false;
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.state.emailAddress
      )
    ) {
      this.setState({
        errorEmail: { isValid: false, msg: "Email non formalmente corretta" },
      });
      return false;
    }
    return true;
  };

  exportCrsSubjects = () => {
    if (this.handlerValidation()) {
      this.setState({ loading: true });
      const axiosInstance = axiosService.getInstance();
      let subjectsIds = this.props.checkedSubjects
        ? this.props.checkedSubjects?.map((r) => r.id)
        : this.props.checkedSubjects?.map((r) => r.id);
      axiosInstance({
        data: subjectsIds,
        url: `/api/crs/custom/crs-subjects/export/${this.state.emailAddress}`,
        method: "POST",
      })
        .then(
          (response) => {
            if (response) {
              if (response?.status === 200) {
                this.closeModal();
                this.setState({ responseOk: "OK", responseModal: true });
              } else {
                this.closeModal();
                this.setState({
                  responseKo:
                    "Si è verificato un errore durante l'esportazione dei dati.",
                });
              }
            }
          },
          (err) => {
            this.closeModal();
            PopupError({
              text: "Si è verificato un errore durante l'esportazione dei dati.",
            });
          }
        )
        .then(() => this.setState({ loading: false }));
    }
  };

  render() {
    let email =
      isEmpty(this.state.emailAddress) && !this.state.emailModified
        ? this.props.checkedSubjects[0]?.customer?.user?.login
        : this.state.emailAddress;
    return (
      <React.Fragment>
        {this.state.loading ? <PageSpinner /> : ""}
        <button
          type="button"
          disabled={this.props.disabled}
          className={`btn btn-outline-primary btn-empty  btn-sm  ${
            this.props.disabled ? "disabled" : ""
          }`}
          data-tip={"Esporta i soggetti selezionati"}
          onClick={this.openModal}
        >
          <IExport
            className={"padding-right-4px"}
            width="16"
            fill={` ${this.props.disabled ? "#FFFFFF" : "#128186"}`}
          />
          &nbsp; Esporta
        </button>
        <Modal
          backdrop="static"
          show={this.state.showModal}
          onHide={this.closeModal}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>ESPORTA SOGGETTI</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <p>
                Attraverso questa funzione è possibile scaricare tutti i dati
                inerenti ai soggetti selezionati.
              </p>
            </div>
            <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
              <div className="tab-content col-md-12 col-lg-12">
                <div className="form-row">
                  <div className="col-md-8 col-lg-8 subDetailComurei">
                    <div className="row">
                      <div className="col-md-11 col-lg-11">
                        <BwmInput
                          id="emailAddress"
                          type="email"
                          className="form-control"
                          label="Indirizzo email a cui inviare il file di export"
                          //name="emailAddress"
                          value={email}
                          maxLength="100"
                          onChange={(e) => this.handlerEmail(e)}
                          error={this.state.errorEmail}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty
                text="ANNULLA"
                classCustom="float-start"
                handlerOnClick={this.closeModal}
              />
              <button
                onClick={this.exportCrsSubjects}
                type="button"
                className="btn btn-primary btn-new-rel px-5"
              >
                ESPORTA
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.responseModal && (
          <Modal
            backdrop="static"
            show={this.state.responseModal}
            onHide={this.closeResponseModal}
            dialogClassName="modal-60w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="bar"></div>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>INIZIO PROCEDURA ESPORTAZIONE SOGGETTI</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.responseOk ? (
                <React.Fragment>
                  Esportazione dei soggetti avviata: riceverai i file al tuo
                  indirizzo email.
                </React.Fragment>
              ) : (
                <div className="popup communication">
                  <Row>
                    <React.Fragment>
                      <p className={"color-red"}>
                        Errore nell'esportazione dei soggetti{" "}
                        {this.state.responseKo}
                      </p>
                    </React.Fragment>
                  </Row>
                </div>
              )}
              <div className="d-flex justify-content-between align-content-end mt-5">
                <BtnEmpty
                  text="ANNULLA"
                  classCustom="float-start"
                  handlerOnClick={this.closeResponseModal}
                />
                <button
                  onClick={() => this.closeResponseModal()}
                  type="button"
                  className="btn btn-primary btn-new-rel px-5"
                >
                  OK
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
