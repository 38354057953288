import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg';


import '../../../../styles/alertComm.css';
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import axiosService from "../../../../services/axios-service";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";

import { CommunicationModal } from './CommunicationModal';
import { downloadDgn, downloadErrorReport, downloadPdf, downloadPecTz, downloadTxt } from '../CommunicationDownloadUtils';

export class CreateCommunicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            sentCommunications: []
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
        document.getElementById("history").click();
    };

    openModal = () => {
        this.setState({ showModal: true });
    };


    onClickConfirm = () => {
        if (this.props.onClickCreateCommunicationBefore) {
            this.props.onClickCreateCommunicationBefore().then(() => {
                this.confirmCommunications();
            });
        } else {
            this.confirmCommunications();
        }

    }

    confirmCommunications = () => {
        const { subjectId, delayedCommunicationDate } = this.props;
        this.setState({
            loading: true
        });
        if (this.props.type === "MONTHLY") {
            actions.createMonthlyCommunications(subjectId,delayedCommunicationDate).then(
                (sentCommunications) => {
                    if (sentCommunications?.length > 0) {
                        this.setState({
                            loading: false,
                            sentCommunications: sentCommunications
                        });
                        this.openModal();
                    } else {
                        this.setState({
                            loading: false
                        });
                        PopupError({ ...this.props, text: "Errore nell'elaborazione della comunicazione, errore non gestito" });
                    }
                },
                (errors) => {
                    this.setState({
                        loading: false
                    });
                    PopupError({ ...this.props, text: errors });
                })
        } else {

            const axiosInstance = axiosService.getInstance();
            axiosInstance({
                data: this.props.excludedRelationships,
                url: `/api/cora/custom/cora-communications/create/yearly/${subjectId}/${this.props.selectedYear}`,
                method: 'POST'
            }).then((response) => {
                if (response) {
                    let sentCommunications = response.data;
                    if (sentCommunications?.length > 0) {
                        this.setState({
                            loading: false,
                            sentCommunications: sentCommunications
                        });
                        this.openModal();
                    } else {
                        this.setState({
                            loading: false
                        });
                        PopupError({ ...this.props, text: "Errore nell'elaborazione della comunicazione, errore non gestito" });
                    }
                }
            }).catch(errors => {
                this.setState({
                    loading: false
                });
                PopupError({ ...this.props, text: "Errore nell'elaborazione della comunicazione" });
            });
        }
    };

    render() {

        return (
            <React.Fragment>
                {this.state.loading && <PageSpinner />}
                <div className={" text-end me-2"}>
                    <button onClick={e => this.onClickConfirm()} className="btn btn-primary btn-new-rel px-5" disabled={this.props.isDisabled}>
                        <ITextFile className={"padding-right-4px svg"} width="16" fill={` ${this.props.isDisabled ? '#FFFFFF' : '#FFFFFF'}`} />
                        &nbsp; CREA COMUNICAZIONE
                    </button>
                </div>
                <CommunicationModal
                    showModal={this.state.showModal}
                    closeModal={this.closeModal}
                    communications={this.state.sentCommunications}
                    coraSubject={this.props.subject}
                    downloadTxt={downloadTxt}
                    downloadPecTz={downloadPecTz}
                    downloadDgn={downloadDgn}
                    downloadPdf={downloadPdf}
                    downloadErrorReport={downloadErrorReport}
                    delayedCommunicationDate={this.props.delayedCommunicationDate}
                />

            </React.Fragment>

        )
    }
}


