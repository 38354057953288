import React from "react";
import { Row } from "react-bootstrap";

export function SelectableElement({  value = "", onChange = (value) => { } , defaultChecked = false, showIcon = false, label = ""}) {

    return (

        <div title={label} className={`d-flex align-items-center selectable-element w-100 ${showIcon ? '' : 'ms-2'}`}>
            {showIcon && <i className="thx-drag q-icon ms-1 me-2" />}
            <input
                type="checkbox"
                value={value}
                defaultChecked={defaultChecked}
                onClick={() => {
                    onChange(value)
                }}
            />
            <label className="ms-2 mb-0 ellipsis col me-3" htmlFor={label}>
                {label}
            </label>
        </div>
    )
}