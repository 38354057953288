import React from 'react';
import * as Constants from "../../../../config/Constants";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import { BwmInput } from "../../../shared/form/BwmInputB5";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import { BwmSelect } from "../../../shared/form/BwmSelectB5";
import { DateField } from "../../../shared/form/DateField";
import axiosService from "../../../../services/axios-service";
import * as actions from "../../../../actions";
import CodiceFiscale from "codice-fiscale-js";
import { isEmpty, isNotEmpty } from '../../../shared/Utility';

class HolderForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.relationship?.crsRelationshipHolder?.id || null,
            personType: this.props.relationship?.crsRelationshipHolder?.personType || Constants.PNF,
            fiscalIdentifier: this.props.relationship?.crsRelationshipHolder?.fiscalIdentifier || '',
            companyName: this.props.relationship?.crsRelationshipHolder?.companyName || '',
            completeAddress: this.props.relationship?.crsRelationshipHolder?.completeAddress || '',
            countryCode: this.props.relationship?.crsRelationshipHolder?.countryCode || 'IT',
            country: this.props.relationship?.crsRelationshipHolder?.country || "ITALIA (REPUBBLICA ITALIANA)",
            holderType: this.props.relationship?.crsRelationshipHolder?.holderType?.id || '',
            holderTypeCode: this.props.relationship?.crsRelationshipHolder?.holderType?.code || '',
            disabledHolderType: this.props.relationship?.crsRelationshipHolder?.personType === Constants.PF ? true : false,
            identifier: this.props.relationship?.crsRelationshipHolder?.identifier || '',
            lastName: this.props.relationship?.crsRelationshipHolder?.lastName || '',
            firstName: this.props.relationship?.crsRelationshipHolder?.firstName || '',
            birthDate: this.props.relationship?.crsRelationshipHolder?.birthDate ? new Date(this.props.relationship?.crsRelationshipHolder?.birthDate) : null,
            birthLocation: this.props.relationship?.crsRelationshipHolder?.birthLocation || '',
            birthProvince: this.props.relationship?.crsRelationshipHolder?.birthProvince || '',
            birthCountry: this.props.relationship?.crsRelationshipHolder?.birthCountry || "ITALIA (REPUBBLICA ITALIANA)",
            relationshipHolderTypes: [],
            crsHolderType: {},
            errorFiscalIdentifier: { isValid: true, msg: '' },
            errorCompanyName: { isValid: true, msg: '' },
            errorCompleteAddress: { isValid: true, msg: '' },
            errorCountryCode: { isValid: true, msg: "" },
            errorHolderType: { isValid: true, msg: "" },
            errorCountry: { isValid: true, msg: "" },
            errorIdentifier: { isValid: true, msg: "" },
            errorFirstName: { isValid: true, msg: "" },
            errorLastName: { isValid: true, msg: "" },
            errorBirthDate: { isValid: true, msg: "" },
            errorBirthLocation: { isValid: true, msg: "" },
            errorBirthProvince: { isValid: true, msg: "" },
            errorBirthCountry: { isValid: true, msg: "" }
        }
    }

    componentWillMount() {
        this.getRelationshipHolderTypes();
    }

    handleValidation = () => {
        let isValid = true;
        if (!this.state.fiscalIdentifier) {
            /**this.setState({
                errorFiscalIdentifier: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;**///NOTE: la cliente ha chiest di togliere il controllo sul codice fiscale -decomentare per applicarlo ancora
        } else {
            this.setState({
                errorFiscalIdentifier: { isValid: true, msg: "" }
            });
        }
        if (!this.state.completeAddress) {
            this.setState({
                errorCompleteAddress: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        } else {
            this.setState({
                errorCompleteAddress: { isValid: true, msg: "" }
            });
        }
        if (!this.state.countryCode) {
            this.setState({
                errorCountryCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        } else {
            this.setState({
                errorCountryCode: { isValid: true, msg: "" }
            });
        }
        if (!this.state.country) {
            this.setState({
                errorCountry: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        } else {
            this.setState({
                errorCountry: { isValid: true, msg: "" }
            });
        }

        if (this.state.personType === Constants.PNF) {
            if (!this.state.companyName) {
                this.setState({
                    errorCompanyName: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorCompanyName: { isValid: true, msg: "" }
                });
            }
            if (!this.state.holderType) {
                this.setState({
                    errorHolderType: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorHolderType: { isValid: true, msg: "" }
                });
            }
        } else {
            if (!this.state.firstName) {
                this.setState({
                    errorFirstName: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorFirstName: { isValid: true, msg: "" }
                });
            }
            if (!this.state.lastName) {
                this.setState({
                    errorLastName: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorLastName: { isValid: true, msg: "" }
                });
            }
            if (!this.state.birthDate) {
                this.setState({
                    errorBirthDate: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorBirthDate: { isValid: true, msg: "" }
                });
            }
            if (!this.state.birthLocation) {
                this.setState({
                    errorBirthLocation: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorBirthLocation: { isValid: true, msg: "" }
                });
            }
            if (!this.state.birthProvince) {
                this.setState({
                    errorBirthProvince: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorBirthProvince: { isValid: true, msg: "" }
                });
            }
            if (!this.state.birthCountry) {
                this.setState({
                    errorBirthCountry: { isValid: false, msg: "Campo obbligatorio" }
                });
                isValid = false;
            } else {
                this.setState({
                    errorBirthCountry: { isValid: true, msg: "" }
                });
            }
        }

        return isValid;
    };

    getRelationshipHolderTypes = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance.get('/api/crs/crs-holder-types')
            .then(res => {
                this.setState({ relationshipHolderTypes: res.data })
            });
    };

    handlePersonTypeClick = (e) => {
        if (e.target.value === Constants.PF) {
            this.setState({
                personType: e.target.value,
                holderType: '',
                crsHolderType: null,
                disabledHolderType: true
            })
            this.props.handlerTabHolder(true)
        } else {
            this.setState({
                personType: e.target.value,
                disabledHolderType: false
            })
        }
    }

    handlerChangeFiscalIdentifiere = (e) => {
        this.setState({ fiscalIdentifier: e.target.value.toUpperCase(), errorFiscalIdentifier: { isValid: true, msg: '' } })
        if (isEmpty(e.target.value)) {
            this.setState({
                ...this.state,
                companyName: '',
                lastName: '',
                firstName: '',
                birthDate: null,
                completeAddress: '',
                fiscalIdentifier: ''
            })
            return;
        }
        actions.getBaseRegistryByFiscalCode(e.target.value.toUpperCase()).then(
            (baseRegistry) => {
                if (baseRegistry) {
                    this.setState({
                        ...this.state,
                        companyName: baseRegistry?.companyName || '',
                        lastName: baseRegistry?.lastName || '',
                        firstName: baseRegistry?.firstName || '',
                        birthDate: new Date(baseRegistry?.birthDate) || null,
                        completeAddress: baseRegistry?.location?.location + "  (" + baseRegistry?.location?.province + ")" || ''
                    })
                }
            },
            (errors) => {
                if (this.state.personType === Constants.PF) {
                    try {
                        const cf = new CodiceFiscale(e.target.value.toUpperCase()).toJSON();
                        if (cf) {

                            this.setState({
                                ...this.state,
                                birthDate: new Date(cf?.birthday) || null,
                                birthLocation: cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace.toUpperCase() : "",
                                birthProvince: cf?.birthplaceProvincia || ""
                            })

                        }
                    } catch (e) {
                        //console.log("codice fiscale errato");
                        /*this.setState({
                            errorFiscalIdentifier: { isValid: false, msg: "Codice fiscale non valido" }
                        });*///NOTE: la cliente ha chiesto di togliere il controllo sul codice fiscale, decommentare queste cose per farlo funzionare
                    }
                }
            }
        )
    }

    handlerChangeCompanyName = (e) => {
        this.setState({ companyName: e.target.value.toUpperCase(), errorCompanyName: { isValid: true, msg: '' } })
    };

    handlerCompleteAddress = (e) => {
        this.setState({ completeAddress: e.target.value.toUpperCase(), errorCompleteAddress: { isValid: true, msg: '' } })
    };


    handleInputAutocompileCountry = (e) => {
        let newCountry = e.target.value;
        this.setState({
            countryCode: newCountry.toUpperCase(),
            errorCountryCode: { isValid: true, msg: "" }
        });
        let countriesFilter = this.props.countries.filter(country => {
            return country.name === newCountry
        })
        if (countriesFilter.length === 1) {
            this.setState({
                countryCode: countriesFilter[0].name,
                country: countriesFilter[0].key
            });
        }
        if (countriesFilter.length === 0) {
            this.setState({
                countryCode: newCountry.toUpperCase(),
                country: ''
            })
        }
    };

    handlerChangeHolderType = (e) => {
        let code = e.target[e.target.selectedIndex].dataset.cod;
        this.setState({
            holderType: e.target.value,
            holderTypeCode: code,
            crsHolderType: { id: parseInt(e.target.value) },
            errorHolderType: { isValid: true, msg: "" }
        })

        this.props.handlerTabHolder(e.target.value !== '1')
    }

    handlerChangeIdentifier = (e) => {
        this.setState({ identifier: e.target.value.toUpperCase() })
    }

    handlerChangeLastName = (e) => {
        this.setState({
            lastName: e.target.value.toUpperCase(),
            errorLastName: { isValid: true, msg: "" }
        });
    };

    handlerChangeFirstName = (e) => {
        this.setState({
            firstName: e.target.value.toUpperCase(),
            errorFirstName: { isValid: true, msg: "" }
        });
    };

    handlerChangeBirthDate = (date) => {
        this.setState({
            birthDate: date,
            errorBirthDate: { isValid: true, msg: "" }
        });
    };

    handleInputAutocompileBirthLocation = (newLocation, id, suggestionIndex) => {
        this.setState({
            birthLocation: newLocation.toUpperCase(),
            errorBirthLocation: { isValid: true, msg: "" }
        });
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    this.setState({
                        birthLocation: loc.location,
                        birthProvince: loc.province,
                        birthCountry: "ITALIA (REPUBBLICA ITALIANA)",
                        errorBirthLocation: { isValid: true, msg: "" },
                        errorBirthProvince: { isValid: true, msg: "" },
                        errorBirthCountry: { isValid: true, msg: "" }
                    });
                } else {
                    this.setState({
                        birthLocation: newLocation.toUpperCase(),
                        errorBirthLocation: { isValid: true, msg: "" }
                    });
                }
            })
    };

    handleProvinceChange = (e) => {
        this.setState({
            birthProvince: e.target.value.toUpperCase(),
            errorBirthProvince: { isValid: true, msg: "" }
        });
    };




    getHolderType = () => {
        if (isNotEmpty(this.state.crsHolderType?.id)) {
            return this.state.crsHolderType;
        } else {
            return this.props.relationship?.crsRelationshipHolder?.holderType;
        }
    }

    getPersonType = () => {
        if (isNotEmpty(this.state.personType)) {
            return this.state.personType;
        } else {
            return this.props.relationship?.crsRelationshipHolder?.personType;
        }
    }


    getData() {
        if (this.handleValidation()) {
            let ht = null;
            if (isNotEmpty(this.state.crsHolderType?.id)) {
                ht = this.state.crsHolderType;
            } else {
                ht = this.props.relationship?.crsRelationshipHolder?.holderType;
            }

            let holder = {
                id: this.state.id,
                fiscalIdentifier: this.state.fiscalIdentifier,
                companyName: this.state.companyName,
                completeAddress: this.state.completeAddress,
                countryCode: this.state.countryCode,
                country: this.state.country,
                personType: this.state.personType === Constants.PNF ? "LEGAL" : "FISICAL",
                identifier: this.state.identifier,
                lastName: this.state.lastName,
                firstName: this.state.firstName,
                birthDate: new Date(this.state.birthDate),
                birthLocation: this.state.birthLocation,
                birthProvince: this.state.birthProvince,
                birthCountry: this.state.birthCountry,
                holderType: ht

            };
            return holder;
        }
    }

    render() {
        const id = this.props.index || '0'
        return (
            <div className="row">
                <div className="col-2 pe-0">
                    <div className="form-check">
                        <label>Tipo</label>
                        <div className="input-group mt-2 d-flex flex-column">
                            <div className="inputRadio mt-1 mb-4">
                                <input hidden={true} className="form-check-input" type="radio" name={`pType${id}`}
                                    id={`pType1${id}`} value={Constants.PNF} onClick={this.handlePersonTypeClick} />
                                <label className="form-check-label" htmlFor={`pType1${id}`}>
                                    <img alt={""} width="20" className="me-3" src={this.state.personType === Constants.PNF ? radioSel : radioUnsel} />Organization
                                </label>
                            </div>
                            <div className="inputRadio">
                                <input hidden={true} className="form-check-input" type="radio" name={`pType${id}`}
                                    id={`pType2${id}`} value={Constants.PF} onClick={this.handlePersonTypeClick} />
                                <label className="form-check-label" htmlFor={`pType2${id}`} >
                                    <img alt={""} width="20" className="me-3" src={this.state.personType === Constants.PF ? radioSel : radioUnsel} />Individual
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"mt-3 col-10" + (this.state.personType === Constants.PF ? "" : " d-none")}>
                        <BwmInput name='identifier'
                            label='Identificativo rapporto'
                            className='form-control borde-bottom-0'
                            value={this.state.identifier}
                            readOnly={true}
                            maxLength="16" />
                    </div>
                </div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-md-3 me-2 mb-2">
                            <div className='form-group'>
                                <div className='input-group'>
                                    <BwmInput name='fiscalIdentifier'
                                        label='TIN (Id Fiscale)'
                                        className='form-control'
                                        value={this.state.fiscalIdentifier}
                                        onChange={e => this.handlerChangeFiscalIdentifiere(e)}
                                        error={this.state.errorFiscalIdentifier}
                                        maxLength="16" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <BwmSelect
                                options={this.state.relationshipHolderTypes}
                                name='holderType'
                                label='Tipologia titolare'
                                className='form-control'
                                onChange={this.handlerChangeHolderType}
                                value={this.state.holderType}
                                error={this.state.errorHolderType}
                                showCode={true}
                                disabled={this.state.disabledHolderType}
                            />
                        </div>

                    </div>
                    <div id="infoHolderPnf" className={"row align-items-center " + (this.state.personType === Constants.PNF ? "" : " d-none")}>
                        <div className="col-md-3 me-2 mb-2">
                            <BwmInput name='companyName'
                                label='Denominazione'
                                className='form-control'
                                value={this.state.companyName}
                                onChange={e => this.handlerChangeCompanyName(e)}
                                error={this.state.errorCompanyName}
                                maxLength="60"
                            />
                        </div>
                        <div className="col-md-6 me-2 mb-2">
                            <BwmInput name='identifier'
                                label='Identificativo rapporto'
                                className='form-control borde-bottom-0'
                                value={this.state.identifier}
                                readOnly={true}
                                maxLength="16" />
                        </div>
                    </div>
                    <div id="infoPf" className={"row align-items-center " + (this.state.personType === Constants.PF ? "" : " d-none")}>
                        <div className="col-md-3 me-2 mb-2">
                            <BwmInput name='lastName'
                                label='Cognome'
                                className='form-control'
                                value={this.state.lastName}
                                onChange={e => this.handlerChangeLastName(e)}
                                error={this.state.errorLastName}
                                maxLength="26"
                            />
                        </div>
                        <div className="col-md-3 me-2 mb-2">
                            <BwmInput name='firstName'
                                label='Nome'
                                className='form-control'
                                value={this.state.firstName}
                                onChange={e => this.handlerChangeFirstName(e)}
                                error={this.state.errorFirstName}
                                maxLength="25"
                            />
                        </div>
                        <div className="col-2 me-2 mb-2">
                            <DateField 
                                // label={"Data di Nascita"}
                                placeholder="Data di Nascita"
                                date={this.state.birthDate}
                                onChange={date => this.handlerChangeBirthDate(date)}
                                error={this.state.errorBirthDate}
                                name={"birthDate"} />
                        </div>
                        <div className={"col-2 me-2 mb-2"}>
                            <AutoCompileCustom
                                label='Comune di Nascita'
                                id="birthLocation"
                                handleInputAutocompile={this.handleInputAutocompileBirthLocation}
                                filtro={this.props.locations}
                                value={this.state.birthLocation}
                                error={this.state.errorBirthLocation}
                            />
                        </div>
                        <div className="col-1 me-2 mb-2">
                            <div className='form-group'>
                                {/* <label>Prov.</label> */}
                                <div className='input-group'>
                                    <input type={"text"}
                                        placeholder="Prov."
                                        className={"form-control"}
                                        onChange={e => this.handleProvinceChange(e)}
                                        error={this.state.errorBirthProvince}
                                        value={this.state.birthProvince} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-3 me-2 mb-2">
                            <BwmInput name='completeAddress'
                                label="Indirizzo (via, numero, cap e città)"
                                className='form-control'
                                value={this.state.completeAddress}
                                onChange={e => this.handlerCompleteAddress(e)}
                                error={this.state.errorCompleteAddress}
                            />
                        </div>
                        <div className='col-6 me-2 mb-2'>
                            <BwmSelect
                                options={this.props.countries}
                                name='country'
                                id="country"
                                label='Paese'
                                className='form-control'
                                onChange={e => this.handleInputAutocompileCountry(e)}
                                value={this.state.countryCode || ""}
                                error={this.state.errorCountry}
                                showCode={true}
                                nameKey={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default HolderForm;
