import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Constants from "../../../config/Constants"
import elenco from "../../../styles/images/elenco.png";
import { ReactComponent as IDelete } from "../../../styles/images/svg/trash-solid.svg";
import BlueSubjectList from "./BlueSubjectList";
import BlueSubjectModal from './BlueSubjectModal';
import * as actions from "../../../actions";
import { PopupDelete } from '../../shared/PopupDelete';
import { PopupError } from '../../shared/PopupError';
import getText from './labels';
import BlueLanguageSelector from './BlueLanguageSelector';
import { PageSpinner } from "../../shared/spinner/PageSpinner";
import { PopupSuccess } from "../../shared/PopupSuccess";
import { CheckRemainingSubjects, IsLicenseValid } from '../../shared/auth/license/LicenseChecker';
import { LicenseExpiredPopup } from '../application-cora/LicenseExpiredPopup';
import { SubjectTotalAndCallToAction } from '../../shared/SubjectTotalAndCallToAction';
import { Col, Row } from 'react-bootstrap';

export default function BlueSubject(props) {
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);
    const [isLoading, setLoading] = useState(false);
    const [expired, setExpired] = useState(false);
    const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
    const [subjectSummary, setSubjectSummary] = useState(undefined);
    const [customerApplication, setCustomerApplication] = useState();

    useEffect(() => {
        getSubjects();
        getLocations();
    }, []);

    const isExpired = () => {
        if (auth?.isAuth) {
            return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_BLUE);
        }
    }
    useEffect(() => {
        setExpired(isExpired());
        const application = Constants.APPLICATIONS.find(a => a.description === Constants.APPLICATION_BLUE);
        const customerApplication = auth.customer?.customerApplications?.find(ca => ca?.application?.id === application.id);
        setCustomerApplication(customerApplication)
    }, [auth])

    useEffect(() => {
        if (expired) {
            LicenseExpiredPopup({language:lang})
        }

    }, [expired]);

    const getSubjects = () => {
        dispatch(actions.getAllBlueSubjects());
        CheckRemainingSubjects(Constants.APPLICATION_BLUE, false, (subjectSummary) => {
            setDisableNewSubjectButtons(subjectSummary?.available < 1)
            setSubjectSummary(subjectSummary);
        })
    }

    const getLocations = () => {
        dispatch(actions.getAllLocations());
    };

    const openPopupDelete = () => {
        PopupDelete({
            title: labels.DELETESUBJECTSPOPUPTITLE,
            cancelText: labels.CANCEL,
            confirmText: labels.CONFIRM,
            handleClickConfirm: deleteBlueSubject
        });
    }

    const deleteBlueSubject = () => {
        setLoading(true);
        let ids = [];
        for (let subject in selectedSubjects) {
            ids.push(selectedSubjects[subject].id);
        }

        actions.deleteBlueSubjects(ids).then((res) => {
            PopupSuccess({
                title: labels.SUCCESSTITLE,
                text: labels.SUCCESSTITLE,
                ok: labels.SUCCESSOK
            });
            setSelectedSubjects([]);
            getSubjects();
        },
            (err) => {
                PopupError({
                    text: labels.GENERICERROR,
                    title: labels.WARNING,
                    request: labels.CONTACTSUPPORT,
                    close: labels.CLOSE
                })
            }).then(() => {
                setLoading(false);
            })
    }

    const dowloadLocalDocument = (fileName) => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + "/docs/" + fileName;
        link.setAttribute("download", fileName);
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <React.Fragment>
            {isLoading && <PageSpinner text={labels.SPINNERTITLE} />}
            <div className="table-custom mt-3">
                <div className="tabs single">
                    <Row>
                        <Col className='col-6'>
                            <SubjectTotalAndCallToAction total={subjectSummary?.total} 
                            remaining={subjectSummary?.available} 
                            buyLink={Constants.BLUE_BUY_LINK} 
                            used={subjectSummary?.used}
                            licenseType={customerApplication?.licenseType}
                            customerApplication={customerApplication}
                         />

                        </Col>
                        <Col className='col-6'>
                            <BlueLanguageSelector />
                        </Col>
                    </Row>
                    <div className="table-custom mt-1">
                        <div className="tab-content tab-rel">
                            <div className="row header-btn-relationship">
                                <div className="col-8 ps-0">
                                    <div className="btn-group" role="group" aria-label="action buttons">
                                        <button type="button" disabled={selectedSubjects.length === 0} className={`btn btn-outline-primary btn-empty btn-sm `} onClick={() => openPopupDelete()} >
                                            <IDelete className={"padding-right-4px"} width="12" fill={` ${'#128186'}`} />&nbsp; {labels.SUBJECTDELETE}</button>
                                        <button type="button" className={`btn ms-2 btn-new-rel btn-sm`} onClick={() => dowloadLocalDocument("BLUE-Ricerca-Massiva.pdf")} >{labels.MASSIVEINSTRUCTION}</button>
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <BlueSubjectModal key="newBlueSubjectModal"
                                        licenseExpired={expired || disableNewSubjectButtons}
                                        getSubjects={getSubjects} />
                                </div>
                            </div>
                            <BlueSubjectList
                                licenseExpired={expired}
                                getSubjects={getSubjects}
                                selectedSubjects={selectedSubjects}
                                setSelectedSubjects={setSelectedSubjects}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )


}