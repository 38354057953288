import React from 'react';
import * as actions from "../../../../actions"
import Modal from "react-bootstrap/Modal";
import { ReactComponent as IUpload } from "../../../../styles/images/svg/file-upload.svg";
import { ReactComponent as ICheck } from "../../../../styles/images/svg/check.svg";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { PopupError } from "../../../shared/PopupError";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import { CommunicationModal } from './CommunicationModal';
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants';
import { isEmpty } from '../../../shared/Utility';

export class ElaborateCommunicationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            communicationFile: '',
            errorCommunication: { isValid: true, msg: "" },
            loading: false,
            communication: undefined,
            sentCommunications: []
        }
    }

    closeModal = () => {
        this.setState({
            loading: false,
            communication: undefined,
            communicationFile: '',
            showModal: false,
            sentCommunications: []
        });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    handleUploadCommunication = (val) => {
        if (isEmpty(val)) {
            this.setState({ errorCommunication: { isValid: false, msg: "E' necessario inserire almeno un file" } });
        } else {
            
            const file = val.target.files[0];
            this.setState({ communicationFile: file })
            if (val.target.files.length !== 1) {
                this.setState({ errorCommunication: { isValid: false, msg: "La comunicazione deve essere singola" } });
            } else if (!this.checkFileName(file.name)) {
                this.setState({ errorCommunication: { isValid: false, msg: "Estensione file errato" } });
            } else if (file.size > 15 * 1000 * 1000) {
                this.setState({ errorCommunication: { isValid: false, msg: "File troppo grande per l'elaborazione (max 15MB). Si prega di compattarlo in formato ZIP o RAR e riprovare" } });
            } else {
                this.setState({ errorCommunication: { isValid: true, msg: "" } });
            }
        }

    };

    checkFileName = (name) => {

        const ext = name.split('.').pop().toUpperCase();
        return (["TXT","RAR","ZIP","7Z"].includes(ext) )
    };

    handleSendCommunication = () => {
        this.setState({ showModal: false });
        this.setState({ loading: true });
        const formData = new FormData();
        formData.append('dataFile', this.state.communicationFile);

        actions.elaborateCommunicationTxt(formData, this.props.subjectId).then(
            (communication) => {
                if (communication && communication[0]?.id) {
                    this.setState({
                        loading: false,
                        communication: communication[0],
                        communicationFile: '',
                        showModal: true
                    });
                    let sentCommunications = this.state.sentCommunications;
                    sentCommunications.push(communication[0]);
                    this.setState({
                        sentCommunications: sentCommunications
                    })

                } else {
                    this.setState({
                        loading: false
                    });
                    PopupError({ ...this.props, text: "Si è verificato un errore nell'elaborazione della ricevuta. Errore non gestito. " });
                }
            }, (errors) => {
                this.setState({
                    loading: false,
                    communication: undefined,
                    communicationFile: '',
                    showModal: false
                });
                PopupError({ ...this.props, text: errors || APPLICATION_GENERIC_ERROR });
            });

    };
   


    render() {
        return (
            <React.Fragment>
                {this.state.loading && <PageSpinner />}
                <button type="button" className={`btn btn-outline-primary  btn-empty px-4 btn-sm`} data-tip={"Carica comunicazione TXT"}
                    onClick={this.openModal} ><ITextFile className={"padding-right-4px svg"} width="16" fill={`#128186`} />&nbsp; Rielabora comunicazione TXT
                </button>
                {this.state.communication ?
                    <CommunicationModal
                        showModal={this.state.showModal}
                        closeModal={this.closeModal}
                        communications={[this.state.communication]}
                        coraSubject={this.state.communication.subject}
                        downloadTxt={this.props.downloadTxt}
                        downloadPecTz={this.props.downloadPecTz}
                        downloadDgn={this.props.downloadDgn}
                        downloadPdf={this.props.downloadPdf}
                        downloadErrorReport={this.props.downloadErrorReport}
                    />

                    :
                    <Modal
                        backdrop="static"
                        show={this.state.showModal}
                        onHide={this.closeModal}
                        dialogClassName="modal-40w"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <div className="bar"></div>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h2>ELABORA COMUNICAZIONE TXT</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex justify-content-between">
                                <div className="image-upload text-start">
                                    <label htmlFor={`communicationFile`}>
                                        <div className="certificateFiles">CARICA FILE COMUNICAZIONE <IUpload width="25"
                                            fill="#000" />
                                        </div>
                                    </label>
                                    <input
                                        id={`communicationFile`}
                                        type='file'
                                        name={`communicationFile`}
                                        multiple
                                        onChange={(e) => this.handleUploadCommunication(e)} 
                                        accept={'.txt,.zip,.rar.7z'}
                                        />
                                    <div className="certificatiLoaded text-start">
                                        {
                                            !this.state.errorCommunication.isValid ?
                                                <div className="certificatiLoaded-error">
                                                    {this.state.errorCommunication.msg}
                                                </div> :
                                                <div>{(this.state.communicationFile) ?
                                                    <div><ICheck width="12"
                                                        fill="#8e8e8e" />{this.state.communicationFile.name}
                                                    </div> : <div>---</div>}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-content-end mt-5">
                                <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
                                <button
                                    disabled={!this.state.errorCommunication.isValid || !this.state.communicationFile}
                                    onClick={this.handleSendCommunication}
                                    type="button"
                                    className="btn btn-primary rounded-pill btn-new-rel px-5"
                                >
                                    CONFERMA
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}
