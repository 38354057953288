import React from 'react';
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import * as Constants from "../../../../config/Constants";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { ReactComponent as IImport } from "../../../../styles/images/svgs/regular/file-import.svg";
import { PopupDelete } from "../../../shared/PopupDelete";
import { PopupError } from "../../../shared/PopupError";
import ArcoSubjectList from "./ArcoSubjectList";
import { ArcoSubjectModal } from "./ArcoSubjectModal";
import { ArcoSubjectImportDocumentModal } from "./ArcoSubjectImportDocumentModal";
import { ArcoSubjectExportModal } from "./ArcoSubjectExportModal";
import ArcoTablesModal from "./ArcoTablesModal";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { CheckRemainingSubjects, IsLicenseValid } from "../../../shared/auth/license/LicenseChecker";
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction';

class ArcoSubject extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            checkedSubjects: [],
            emailCustomer: "",
            showTableModal: false,
            showImportModal: false,
            isLicenseValid: false,
            subjectSummary: undefined
        }
    }

    componentWillMount() {
        this.getSubjectTypes();
        this.getSubjects();
        this.getLocations();
        this.getCountries();
        this.getCurrentUser();
        this.checkExpire();
    }

    getCurrentUser = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance.get(`/api/common/users/current`)
            .then(res => {
                if (res) {
                    this.setState({
                        emailCustomer: res.data.email
                    })
                }
            });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.auth?.user?.customer?.customerApplications !== this.props.auth?.user?.customer?.customerApplications) {
            this.checkExpire();
        }

    }

    checkExpire() {
        if (this.props?.auth?.user?.customer?.customerApplications) {
            let valid = IsLicenseValid(this.props?.auth?.user?.customer?.customerApplications, Constants.APPLICATION_ARCO);
            const application = Constants.APPLICATIONS.find(a => a.description === Constants.APPLICATION_ARCO);
            const customerApplication = this.props?.auth?.user?.customer?.customerApplications?.find(ca => ca?.application?.id === application.id);
            this.setState({ customerApplication: customerApplication, isLicenseValid: valid });
        }
    }

    getSubjectTypes = () => {
        this.props.dispatch(actions.getAllArcoSubjectTypes());
    };

    getSubjects = () => {
        this.props.dispatch(actions.getAllArcoSubjects());
        CheckRemainingSubjects(Constants.APPLICATION_ARCO, false, (subjectSummary) => {
            this.setState({ subjectSummary: subjectSummary });
        });
    };

    getLocations = () => {
        this.props.dispatch(actions.getAllLocations());
    };

    getCountries = () => {
        this.props.dispatch(actions.getAllCountries());
    };

    checkSubject = (subject) => {
        let newList = this.state.checkedSubjects;
        newList.push(subject);
        this.setState({ checkedSubjects: newList })
        if (newList.length === this.props.subjects.length) {
            this.checkAllSubjects();
            document.getElementById('checkboxAllId').checked = true;
        }
    };

    uncheckSubject = (subject) => {
        let newList = [];
        this.state.checkedSubjects.forEach(current => {
            if (current.id !== subject.id) {
                newList.push(current);
            }
        });
        this.setState({ checkedSubjects: newList })
    };

    checkAllSubjects = () => {
        let allSubjects = this.props.subjects;
        this.setState({
            checkedSubjects: allSubjects
        })
    };

    uncheckAllSubjects = () => {
        this.setState({
            checkedSubjects: []
        })
    };

    openPopupDelete = () => {
        PopupDelete({
            operazione: "Soggetti Selezionati",
            handleClickConfirm: this.deleteArcoSubject
        });
    };

    deleteArcoSubject = () => {

        this.state.checkedSubjects.forEach(subject => {
            this.setState({
                loading: true
            });
            actions.deleteArcoSubject(subject.id).then(//FIXME eliminare con lista, non uno alla volta
                () => {
                    this.setState({ checkedSubjects: [] })
                    this.getSubjects();
                    PopupSuccess();
                },
                (errors) => {
                    PopupError({ ...this.props, text: "Si è verificato un errore durante l'operazione" });
                })
                .then(() => {
                    this.setState({
                        loading: false
                    });
                });
            document.getElementById(`checkboxAllId`).checked = false;
        });

        let checkboxes = document.querySelectorAll('[id^="checkboxDetails"]');
        for (const checkBox of checkboxes) {
            checkBox.checked = false;
        }
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }

    };

    closeTableModal = () => {
        this.setState({
            showTableModal: false
        });
    }

    showPopupGestioneTabelle = (subject) => {
        this.setState({
            showTableModal: true,
            subject
        });
    }

    importARIW = () => {
        this.setState({
            showImportModal: true
        });
    }

    closeImportARIW = () => {
        this.setState({
            showImportModal: false
        });
    }


    render() {
        let disabled = !this.state.isLicenseValid;
        return (
            <React.Fragment>
                {this.state.loading && <PageSpinner />}
                <div className="table-custom mt-3">
                    <SubjectTotalAndCallToAction
                        total={this.state.subjectSummary?.total}
                        remaining={this.state.subjectSummary?.available}
                        used={this.state.subjectSummary?.used}
                        licenseType={this.state.customerApplication?.licenseType}
                        customerApplication={this.state.customerApplication}
                    />
                    <div className="tabs single">
                        <div className="table-custom mt-1">
                            <div className="tab-content tab-rel">
                                <div className="row header-btn-relationship">
                                    <div className="col-8 ps-0" >
                                        <div className="btn-group" role="group" aria-label="action buttons">
                                            <button type="button" disabled={(Object.keys(this.state.checkedSubjects).length === 0)} className={`btn btn-outline-primary btn-sm btn-empty ${(Object.keys(this.state.checkedSubjects).length === 0) ? 'disabled' : ''}`} onClick={() => this.openPopupDelete()} >
                                                <IDelete className={"padding-right-4px"} width="12" fill={` ${(Object.keys(this.state.checkedSubjects).length === 0) ? '#FFFFFF' : '#128186'}`} />&nbsp; Elimina</button>
                                        </div>
                                        <div className="btn-group" role="group" aria-label="action buttons">
                                            <ArcoSubjectExportModal emailCustomer={this.state.emailCustomer} disabled={(Object.keys(this.state.checkedSubjects).length === 0)} checkedSubjects={this.state.checkedSubjects} />
                                        </div>
                                        <div className="btn-group" role="group" aria-label="action buttons">
                                            <button type="button" disabled={disabled} className={`btn btn-outline-primary btn-empty btn-sm ${disabled && 'disabled'}`} onClick={() => this.importARIW()}>
                                                <IImport className={"padding-right-4px"} width="16" fill={` ${disabled ? '#FFFFFF' : '#128186'}`} />&nbsp; Importa</button>
                                        </div>
                                    </div>
                                    <div className="col-4 text-end pe-0">
                                        <ArcoSubjectModal
                                            locations={this.props.locations}
                                            countries={this.props.countries}
                                            getSubjects={this.getSubjects}
                                            emailCustomer={this.state.emailCustomer}
                                            subjectTypes={this.getSubjectTypes}
                                            showPopupGestioneTabelle={this.showPopupGestioneTabelle}
                                            disabled={disabled}
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                                <ArcoSubjectList
                                    subjects={this.props.subjects}
                                    locations={this.props.locations}
                                    getSubjects={this.getSubjects}
                                    checkSubject={this.checkSubject}
                                    uncheckSubject={this.uncheckSubject}
                                    checkAllSubjects={this.checkAllSubjects}
                                    uncheckAllSubjects={this.uncheckAllSubjects}
                                    showPopupGestioneTabelle={this.showPopupGestioneTabelle}
                                    countries={this.props.countries}
                                    user={this.props.auth.user}
                                    isLicenseValid={this.state.isLicenseValid}
                                    {...this.props}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.showTableModal &&
                        <ArcoTablesModal
                            showTableModal={this.state.showTableModal}
                            closeTableModal={this.closeTableModal}
                            subject={this.state.subject}
                            disableInsert={disabled}
                            {...this.props} />
                    }
                    {this.state.showImportModal &&
                        <ArcoSubjectImportDocumentModal
                            getSubjects={this.getSubjects}
                            show={this.state.showImportModal}
                            onHide={this.closeImportARIW} />}
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    let customLocations = state.locations?.data.map((location, index) => {
        return { name: location.location, key: index }
    });
    let customCountries = state.countries?.data.map((country, index) => {
        return { name: country.code, key: country.description }
    });

    return {
        auth: state.auth,
        locations: customLocations,
        countries: customCountries,
        subjects: state.arcoSubjects.data,
        subjectTypes: state.arcoSubjectTypes.data
    }
}

export default connect(mapStateToProps)(ArcoSubject);
