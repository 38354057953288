import React from 'react';
import { connect } from "react-redux";
import Tabs from "../../application-cora/relationships/Tabs";
import HolderForm from "./HolderForm";
import { BwmInput } from "../../../shared/form/BwmInputB5";
import { BwmSelect } from "../../../shared/form/BwmSelectB5";

import "../../../../styles/relationship-crs.css";
import ControllingPersonList from "./ControllingPersonList";
import ControllingPersonForm from "./ControllingPersonForm";
import axiosService from "../../../../services/axios-service";
import * as actions from "../../../../actions";
import PaymentForm from "./PaymentForm";
import { PopupError } from "../../../shared/PopupError";
import * as Constants from "../../../../config/Constants";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { PageSpinner } from '../../../shared/spinner/PageSpinner';
import { isEmpty, isNotEmpty } from '../../../shared/Utility';

class RelationshipForm extends React.Component {
    constructor(props) {
        super(props);
        this.holderRefData = React.createRef();
        this.balanceRefData = React.createRef();
        this.cpRefData = React.createRef();
        this.TabRef = React.createRef();
        this.state = {
            controllingPerson: {},
            showPersonForm: false,
            disabledTabControllingPerson: this.props.relationship?.crsRelationshipHolder?.holderType?.code === "CRS101" ? false : true,
            relationshipForm: {
                id: this.props.relationship?.id || null,
                progressive: '0' + this.props.relationship?.progressive || '',
                relationshipNumber: this.props.relationship?.relationshipNumber || '',
                crsRelationshipNumberType: { id: this.props.relationship?.crsRelationshipNumberType?.id || "" },
                balance: this.props.relationship?.balance || 0,
                currency: { id: this.props.relationship?.currency?.id || 58 },
                closed: this.props.relationship?.closed || false,
                sleepy: this.props.relationship?.sleepy || false,
                notDocumented: this.props.relationship?.notDocumented || false
            },
            relationshipNumbersTypes: [],
            currencies: [],
            errorRelationshipNumber: { isValid: true, msg: "" },
            errorCrsRelationshipNumberType: { isValid: true, msg: "" },
            relationshipSaved: {},
            isLoading:false
        }
    }

    componentWillMount() {

        if (!this.props.relationship) this.getLastProgressive();
        this.getRelationshipNumberTypes();
        this.getCountries();
        this.getLocations();
        this.getAllCurrency();
    }

    getLastProgressive = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance.get(`/api/crs/custom/crs-relationships/lastProgressive/${this.props.subject.id}`)
            .then(res => {
                let newCode = parseInt(res.data) + 1;
                this.setState({
                    ...this.state,
                    relationshipForm: {
                        ...this.state.relationshipForm,
                        progressive: newCode
                    }
                })
            });
    };

    getRelationshipNumberTypes = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance.get('/api/crs/crs-relationship-number-types')
            .then(res => {
                this.setState({ relationshipNumbersTypes: res.data })
            });
    };

    getCountries = () => {
        this.props.dispatch(actions.getAllCountries());
    };

    getLocations = () => {
        this.props.dispatch(actions.getAllLocations());
    };

    getAllCurrency() {
        if(!this.props.currencies?.length){
            this.props.dispatch(actions.getAllCurrency());
        }
    }

    handlerShowPersonForm = (cp) => {

        if (isNotEmpty(cp)) {
            this.setState({ controllingPerson: cp })
        }

        this.setState({ showPersonForm: !this.state.showPersonForm })

    };


    handlerChangeRelationshipNumber = (e) => {
        this.setState({
            relationshipForm: {
                ...this.state.relationshipForm,
                relationshipNumber: e.target.value.toUpperCase()
            },
            errorRelationshipNumber: { isValid: true, msg: "" },
        });
    };

    handlerChangeRelationshipNumberType = (e) => {
        this.setState({
            relationshipForm: {
                ...this.state.relationshipForm,
                crsRelationshipNumberType: { id: e.target.value, code: e.target[e.target.selectedIndex].dataset.cod }
            },
            errorCrsRelationshipNumberType: { isValid: true, msg: "" },
        });
    };

    handleValidation = () => {
        let isValid = true;

        if (!this.state.relationshipForm.relationshipNumber) {
            this.setState({
                errorRelationshipNumber: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }
        if (isEmpty(this.state.relationshipForm.crsRelationshipNumberType) || this.state.relationshipForm.crsRelationshipNumberType?.id === "") {
            this.setState({
                errorCrsRelationshipNumberType: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }
        return isValid;
    };

    handleTabsValidation = () => {
        let isRelatioshipValid = true;
        let isHolderValid = true;
        let isPaymentsValid = true;
        let isControllingPersonValid = true;

        if (!this.handleValidation()) {
            console.log("Relationship Form not valid")
            this.selectTab(0);
            isRelatioshipValid = false;
            return false;
        }
        if (!this.holderRefData.current.handleValidation()) {
            console.log("Holder Form not valid")
            this.selectTab(0);
            isHolderValid = false;
            return false;
        }

        if (this.state.showPersonForm) {
            if (document.getElementById("controlling-person-save-button")) {
                document.getElementById("controlling-person-save-button").click()
                this.selectTab(1);
                isControllingPersonValid = false;
                return false;
            }
        } else {
            if (isNotEmpty(this.holderRefData.current.getPersonType()) && this.holderRefData.current.getPersonType() === Constants.PNF) {
                let holderType = this.holderRefData.current.getHolderType();
                let controllingPerson = this.cpRefData.current.countControllingPerson() === 0;
                if (holderType?.id === 1 && controllingPerson) {
                    console.log("Controlling Person Form not valid")
                    this.cpRefData.current.setErrorControllingPerson();
                    this.selectTab(1);
                    isControllingPersonValid = false;
                    return false;
                }
            }
        }

        if (this.state.relationshipForm?.balance === null || this.state.relationshipForm?.balance === undefined || (this.state.relationshipForm?.balance === 0 && !this.balanceRefData.current.handleValidation())) {
            console.log("Amount Form not valid")
            this.selectTab(2);
            isPaymentsValid = false;
            return false;
        }

        return isRelatioshipValid & isControllingPersonValid & isHolderValid & isPaymentsValid;
    };



    handleFormSubmit = (event, sendComm, closeAll) => {
        event.preventDefault();
        if (this.handleTabsValidation()) {
            this.addCrsRelationshipHolder(false, null, closeAll);
        }
    };

    handleFormSubmitHolder = (tab) => {
        if (this.handleValidation() && this.holderRefData.current.handleValidation()) {
            this.addCrsRelationshipHolder(true, tab, false);
        }
    };


    handlerTabHolder = (disabled) => {
        this.setState({ disabledTabControllingPerson: disabled })
    }

    addCrsRelationshipHolder = (enableTab, tab, closeAll) => {
        let newRelationshipHolder = this.holderRefData.current.getData();
        if (newRelationshipHolder?.personType === Constants.PF)
            newRelationshipHolder.holderType = null;

        if (this.props.relationship){
            newRelationshipHolder.crsRelationship = this.props.relationship?.id;
        }
        this.setState({isLoading:true});
        actions.addCrsRelationshipHolder(newRelationshipHolder).then(
            (response) => {
                newRelationshipHolder = response;
                this.addCrsRelationship(newRelationshipHolder, enableTab, tab, closeAll);
            },
            (errors) => {
                PopupError({ ...this.props, text: Constants.APPLICATION_GENERIC_ERROR });
            }
        ).then(()=>this.setState({isLoading:false}));
    };

    addCrsRelationship = (newRelationshipHolder, enableTab, tab, closeAll) => {
        let balances = this.balanceRefData.current.getData();
        if (balances) {
            this.setState({isLoading:true});
            let newRelationship = this.state.relationshipForm;
            newRelationship.id = this.state.relationshipSaved?.id || (this.state.relationshipForm?.id || null);
            const shouldCloseModal = !newRelationship.id;

            newRelationship.crsRelationshipNumberType = { id: parseInt(this.state.relationshipForm?.crsRelationshipNumberType?.id || this.props.relationship?.crsRelationshipNumberType?.id) };
            newRelationship.crsRelationshipHolder = newRelationshipHolder;
            let subject = { id: this.props.subject.id };
            newRelationship.crsSubject = subject;
            newRelationship.includeInNextCommunication = true;

            newRelationship.balance = balances.amountRel;
            newRelationship.closed = balances.closed;
            newRelationship.sleepy = balances.sleepy;
            newRelationship.notDocumented = balances.notDocumented;

            actions.addCrsRelationship(newRelationship).then(
                (response) => {
                    this.setState({
                        ...this.state,
                        relationshipSaved: response
                    })
                    this.refreshRelationship(enableTab, tab);
                    this.balanceRefData.current.savePayments();
                    this.balanceRefData.current.refreshPayments();
                    if (closeAll){
                        PopupSuccess({title:"Rapporto Salvato Correttamente"})
                    } 
                    if(shouldCloseModal){
                        this.closeDetailsOrModal();
                    }
                },
                (errors) => {
                    PopupError({ text: Constants.APPLICATION_GENERIC_ERROR });
                }
            ).then(()=>this.setState({isLoading:false}));
        }
    };

    refreshRelationship = (enableTab, tab) => {
        if (!enableTab) {
            if (this.props.closeModal)
                this.props.closeModal();
            this.props.getRelationships(this.props.subject.id);
        } else {
            this.props.getRelationships(this.props.subject.id);
            this.TabRef.current.setState({ activeTab: tab });
        }
    }

    refreshControllingPersonList = () => {
        this.cpRefData.current.getAllCrsControllingPeople();
    }

    closeDetailsOrModal = () => {
        if (this.props.handlerShowDetail)
            this.props.handlerShowDetail();
        else
            this.props.closeModal();
    }

    selectTab = (n) => {
        document.querySelectorAll('[id=tab-rel-crs]')[n].click();
    }



    render() {
        return (
            <div className="row-detail bg-gray pt-4 pe-3 pb-4 ps-3 d-flex">
                {this.state.isLoading && <PageSpinner/>}
                <div className="tab-content relationship col-md-12 col-lg-12">
                    <div className="panel w-all mt-3">
                        <div className="row">
                            <div className='col-md-1 me-2 mb-2'>
                                {/* <label>Progressivo</label> */}
                                <input name='progressive'
                                    className='form-control text-end'
                                    value={this.state.relationshipForm.progressive}
                                    placeholder="Progressivo"
                                    maxLength="16"
                                    disabled="disabled"
                                />
                            </div>
                            <div className='col-md-3 me-2 mb-2'>
                                <BwmInput name='relationshipNumber'
                                    label="Numero RAPPORTO (Conto)"
                                    className='form-control'
                                    value={this.state.relationshipForm.relationshipNumber}
                                    onChange={this.handlerChangeRelationshipNumber}
                                    error={this.state.errorRelationshipNumber}
                                />
                            </div>
                            <div className='col-md-6'>
                                <BwmSelect
                                    options={this.state.relationshipNumbersTypes}
                                    name='relationshipType'
                                    label='Tipo Numero'
                                    className='form-control'
                                    onChange={e => this.handlerChangeRelationshipNumberType(e)}
                                    error={this.state.errorCrsRelationshipNumberType}
                                    value={this.state.relationshipForm?.crsRelationshipNumberType?.id}
                                    showCode={true}
                                />
                            </div>
                        </div>
                    </div>
                    <Tabs ref={this.TabRef}>
                        <div
                            id="tab-rel-crs"
                            label="TITOLARE DEL RAPPORTO FINANZIARIO">
                            <HolderForm ref={this.holderRefData} {...this.props} handlerTabHolder={this.handlerTabHolder}
                            />
                        </div>
                        <div
                            id="tab-rel-crs" label="PERSONE CONTROLLANTI"
                            disabled={this.state.disabledTabControllingPerson}
                            handleFormSubmitHolder={this.handleFormSubmitHolder}
                        >
                            {this.state.showPersonForm ?
                                <ControllingPersonForm refreshList={this.refreshControllingPersonList} controllingPerson={this.state.controllingPerson} relationship={this.state.relationshipSaved} {...this.props} handlerShowPersonForm={this.handlerShowPersonForm} />
                                :
                                <ControllingPersonList ref={this.cpRefData} {...this.props} relationship={this.props.relationship} relationshipId={this.state.relationshipSaved?.id} handlerShowPersonForm={this.handlerShowPersonForm} />
                            }

                        </div>
                        <div
                            id="tab-rel-crs"
                            label="SALDO E PAGAMENTI"
                            handleFormSubmitHolder={this.handleFormSubmitHolder}
                        >
                            <PaymentForm ref={this.balanceRefData} {...this.props} relationship={this.props.relationship} relationshipId={this.state.relationshipSaved?.id} />
                        </div>
                    </Tabs>
                    <div
                        className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                        <button
                            className="btn btn-outline-primary rounded-pill btn-empty px-4 btn-sm"
                            onClick={this.closeDetailsOrModal}><IUndo className={"padding-right-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                        </button>
                        <button onClick={e => this.handleFormSubmit(e, false, true)}
                            className="btn btn-primary rounded-pill btn-new-rel px-5"><ISave className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let customCountries = state.countries?.data.map((country, index) => {
        return { name: country.code, key: country.description }
    });
    let customLocations = state.locations?.data.map((location, index) => {
        return { name: location.location, key: index }
    });
    let customCurrencies = state.currencies?.data.map((currency, index) => {
        return { name: currency.isoCode + " - " + currency.description, id: currency.id }
    });

    return {
        countries: customCountries,
        locations: customLocations,
        currencies: customCurrencies,
    }
}

export default connect(mapStateToProps)(RelationshipForm)
