import React from 'react';
import * as actions from "../../../../actions";
import '../../../../styles/arcoTablesModal.css';
import '../../../../styles/comurei-form.css';
import { BwmInput } from "../../../shared/form/BwmInput";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PopupError } from "../../../shared/PopupError";
import * as Constants from "../../../../config/Constants";
import { Col } from 'react-bootstrap';
import { CloseButton } from './table-components/CloseButton';
import { ConfirmButton } from './table-components/ConfirmButton';

class ArcoRiskClasses extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         crDomains: this.props.crDomains,
         errorMinLowRisk: { isValid: true, msg: "" },
         errorMaxLowRisk: { isValid: true, msg: "" },
         errorMinMediumRisk: { isValid: true, msg: "" },
         errorMaxMediumRisk: { isValid: true, msg: "" },
         errorMinHighRisk: { isValid: true, msg: "" },
         errorMaxHighRisk: { isValid: true, msg: "" },
         errorMinBehaviour: { isValid: true, msg: "" },
         errorMedBehaviour: { isValid: true, msg: "" },
         errorMaxBehaviour: { isValid: true, msg: "" }

      }
   }


   chekRangeValue = () => {
      let isValid = true;
      if (this.state.crDomains.crDomainMinLow.value != 0) {
         this.setState({
            errorMinLowRisk: { isValid: false, msg: "Il valore deve essere pari a 0" },
         });
         isValid = false;
      }
      if (this.state.crDomains.crDomainMaxHigh.value != 99) {
         this.setState({
            errorMaxHighRisk: { isValid: false, msg: "Il valore deve essere pari a 99" },
         });
         isValid = false;
      }
      if (this.state.crDomains.crDomainMinLow.value >= this.state.crDomains.crDomainMinHigh.value) {
         this.setState({
            errorMinLowRisk: { isValid: false, msg: "Range incongruente" },
            errorMaxLowRisk: { isValid: false, msg: "Range incongruente" }
         });
         isValid = false;
      }
      if (this.state.crDomains.crDomainMediumLow.value >= this.state.crDomains.crDomainMediumHigh.value || this.state.crDomains.crDomainMediumLow.value <= this.state.crDomains.crDomainMinHigh.value) {
         this.setState({
            errorMinMediumRisk: { isValid: false, msg: "Range incongruente" },
            errorMaxMediumRisk: { isValid: false, msg: "Range incongruente" }
         });
         isValid = false;
      }
      if (this.state.crDomains.crDomainMaxLow.value >= this.state.crDomains.crDomainMaxHigh.value || this.state.crDomains.crDomainMaxLow.value <= this.state.crDomains.crDomainMediumHigh.value) {
         this.setState({
            errorMinHighRisk: { isValid: false, msg: "Range incongruente" },
            errorMaxHighRisk: { isValid: false, msg: "Range incongruente" }
         });
         isValid = false;
      }

      if (this.state.crDomains.crDomainMediumLow.value - this.state.crDomains.crDomainMinHigh.value > 1) {
         isValid = false;
         this.setState({
            errorMinMediumRisk: { isValid: false, msg: "Range incongruente" },
            errorMaxLowRisk: { isValid: false, msg: "Range incongruente" }
         });
      }

      if (this.state.crDomains.crDomainMaxLow.value - this.state.crDomains.crDomainMediumHigh.value > 1) {
         isValid = false;
         this.setState({
            errorMaxMediumRisk: { isValid: false, msg: "Range incongruente" },
            errorMinHighRisk: { isValid: false, msg: "Range incongruente" }
         });
      }



      return isValid;
   }

   changeMinLowRisk = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crDomainMinLow: {
               ...this.state.crDomains.crDomainMinLow,
               value: parseInt(e.target.value)
            }
         },
         errorMinLowRisk: { isValid: true, msg: "" },
      });
   };
   changeMaxLowRisk = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crDomainMinHigh: {
               ...this.state.crDomains.crDomainMinHigh,
               value: parseInt(e.target.value)
            }
         },
         errorMaxLowRisk: { isValid: true, msg: "" },
      });
   };
   changeMinMediumRisk = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crDomainMediumLow: {
               ...this.state.crDomains.crDomainMediumLow,
               value: parseInt(e.target.value)
            }
         },
         errorMinMediumRisk: { isValid: true, msg: "" },
      });
   };
   changeMaxMediumRisk = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crDomainMediumHigh: {
               ...this.state.crDomains.crDomainMediumHigh,
               value: parseInt(e.target.value)
            }
         },
         errorMaxMediumRisk: { isValid: true, msg: "" },
      });
   };
   changeMinHighRisk = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crDomainMaxLow: {
               ...this.state.crDomains.crDomainMaxLow,
               value: parseInt(e.target.value)
            }
         },
         errorMinHighRisk: { isValid: true, msg: "" },
      });
   };
   changeMaxHighRisk = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crDomainMaxHigh: {
               ...this.state.crDomains.crDomainMaxHigh,
               value: parseInt(e.target.value)
            }
         },
         errorMaxHighRisk: { isValid: true, msg: "" },
      });
   };

   changeMinRiskBehaviour = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crBehaviourMin: {
               ...this.state.crDomains.crBehaviourMin,
               value: e.target.value?.toUpperCase()
            }
         },
         errorMinBehaviour: { isValid: true, msg: "" },
      })
   }

   changeMedRiskBehaviour = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crBehaviourMed: {
               ...this.state.crDomains.crBehaviourMed,
               value: e.target.value?.toUpperCase()
            }
         },
         errorMedBehaviour: { isValid: true, msg: "" },
      })
   }
   changeMaxRiskBehaviour = (e) => {
      this.setState({
         crDomains: {
            ...this.state.crDomains,
            crBehaviourMax: {
               ...this.state.crDomains.crBehaviourMax,
               value: e.target.value?.toUpperCase()
            }
         },
         errorMaxBehaviour: { isValid: true, msg: "" },
      })
   }
   updateRiskClass = () => {


      if (!this.riskClassValidation()) return;

      let arrayToSave = [this.state.crDomains.crDomainMinLow,
      this.state.crDomains.crDomainMinHigh,
      this.state.crDomains.crDomainMediumLow,
      this.state.crDomains.crDomainMediumHigh,
      this.state.crDomains.crDomainMaxLow,
      this.state.crDomains.crDomainMaxHigh,
      this.state.crDomains.crBehaviourMin,
      this.state.crDomains.crBehaviourMed,
      this.state.crDomains.crBehaviourMax,];
      actions.updateCrArcoDomains(this.props.subject.id, arrayToSave).then(res => {
         PopupSuccess({ text: "Salvataggio effettuato" });
      }).catch(errors => {
         PopupError({ ...this.props, text: Constants.APPLICATION_GENERIC_ERROR });
      });

   };

   riskClassValidation = () => {
      let isValid = true;
      if (!this.state.crDomains.crDomainMinLow.value) {
         this.setState({
            errorMinLowRisk: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crDomainMinHigh.value) {
         this.setState({
            errorMaxLowRisk: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crDomainMediumLow.value) {
         this.setState({
            errorMinMediumRisk: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crDomainMediumHigh.value) {
         this.setState({
            errorMaxMediumRisk: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crDomainMaxLow.value) {
         this.setState({
            errorMinHighRisk: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crDomainMaxHigh.value) {
         this.setState({
            errorMaxHighRisk: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crBehaviourMin?.value) {
         this.setState({
            errorMinBehaviour: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crBehaviourMed?.value) {
         this.setState({
            errorMedBehaviour: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (!this.state.crDomains.crBehaviourMax?.value) {
         this.setState({
            errorMaxBehaviour: { isValid: false, msg: "Campo obbligatorio" }
         });
         isValid = false;
      }
      if (isValid) {
         isValid = this.chekRangeValue();
      }

      return isValid;
   };

   render() {
      return (
         <div >
            <div className='d-flex flex-column justify-content-center' style={{ height: '388px' }}>

               <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                     <div className="lowRisk"><b>&nbsp;Rischio BASSO</b></div>
                  </div>
                  <div className="col-md-1">
                     <BwmInput name='minLowRisk'
                        label="MINIMO"
                        value={this.state?.crDomains?.crDomainMinLow?.value}
                        onChange={e => this.changeMinLowRisk(e)}
                        error={this.state.errorMinLowRisk}
                        className='form-control text-end'
                        maxLength="2"
                        type="number"
                     />
                  </div>
                  <div className="col-md-1">
                     <BwmInput name='maxLowRisk'
                        label="MASSIMO"
                        value={this.state?.crDomains?.crDomainMinHigh?.value}
                        onChange={e => this.changeMaxLowRisk(e)}
                        error={this.state.errorMaxLowRisk}
                        className='form-control text-end'
                        maxLength="2"
                        type="number" />
                  </div>
                  <div className="col-md-6">
                     <BwmInput
                        name='behaviourLowRisk'
                        label="COMPORTAMENTO"
                        value={this.state?.crDomains?.crBehaviourMin?.value}
                        onChange={e => this.changeMinRiskBehaviour(e)}
                        error={this.state.errorMinBehaviour}
                        className='form-control'
                     />
                  </div>

               </div>
               <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                     <div className="mediumRisk"><b>&nbsp;Rischio MEDIO</b></div>
                  </div>
                  <div className="col-md-1">
                     <BwmInput name='minMediumRisk'
                        label=""
                        value={this.state?.crDomains?.crDomainMediumLow?.value}
                        onChange={e => this.changeMinMediumRisk(e)}
                        error={this.state.errorMinMediumRisk}
                        className='form-control text-end'
                        maxLength="2"
                        type="number" />
                  </div>
                  <div className="col-md-1">
                     <BwmInput name='maxMediumRisk'
                        label=""
                        value={this.state?.crDomains?.crDomainMediumHigh?.value}
                        onChange={e => this.changeMaxMediumRisk(e)}
                        error={this.state.errorMaxMediumRisk}
                        className='form-control text-end'
                        maxLength="2"
                        type="number" />
                  </div>
                  <div className="col-md-6">
                     <BwmInput
                        name='behaviourMedRisk'
                        value={this.state?.crDomains?.crBehaviourMed?.value}
                        onChange={e => this.changeMedRiskBehaviour(e)}
                        error={this.state.errorMedBehaviour}
                        className='form-control'
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                     <div className="highRisk"><b>&nbsp;Rischio ALTO</b></div>
                  </div>
                  <div className="col-md-1">
                     <BwmInput name='minHighRisk'
                        label=""
                        value={this.state?.crDomains?.crDomainMaxLow?.value}
                        onChange={e => this.changeMinHighRisk(e)}
                        error={this.state.errorMinHighRisk}
                        className='form-control text-end'
                        maxLength="2"
                        type="number" />
                  </div>
                  <div className="col-md-1">
                     <BwmInput name='maxHighRisk'
                        label=""
                        value={this.state?.crDomains?.crDomainMaxHigh?.value}
                        onChange={e => this.changeMaxHighRisk(e)}
                        error={this.state.errorMaxHighRisk}
                        className='form-control text-end'
                        maxLength="2"
                        type="number" />
                  </div>
                  <div className="col-md-6">
                     <BwmInput
                        name='behaviourMaxRisk'
                        value={this.state?.crDomains?.crBehaviourMax?.value}
                        onChange={e => this.changeMaxRiskBehaviour(e)}
                        error={this.state.errorMaxBehaviour}
                        className='form-control'
                     />
                  </div>
               </div>
            </div>

            <div className="form-row mt-2 align-item-center">
               <Col lg={2}>
                  <CloseButton
                     onClick={this.props.onClose}
                  />
               </Col>
               <Col lg={8} className={"text-center"}>
                  <ConfirmButton
                     onClick={this.updateRiskClass}
                  />
               </Col>
            </div>
            {/* <div className="row mt-5">
               <div className="col-md-3">
                  
                  <button
                     className="btn btn-outline-primary btn-empty px-4 btn-sm " classCustom="float-start"
                     onClick={this.props.closeTableModal}><IUndo className={"padding-right-4px svg"} width="16" fill={"#128186"} />&nbsp; CHIUDI
                  </button>
               </div>
               <div className="col-md-6"></div>
               <div className="col-md-3">
                  <button onClick={e => this.updateRiskClass()}
                     className="btn btn-primary btn-new-rel px-5" classCustom="float-end"><ISave className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                  </button>
               </div>

            </div> */}
         </div>);
   }
}

export default ArcoRiskClasses