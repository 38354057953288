import React from 'react';
import Modal from "react-bootstrap/Modal";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import CorproForm from "./CorproForm";

export class CorproSubjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    closeModal = () =>{
        this.setState({showModal: false});
    };

    openModal = () =>{
        this.setState({showModal: true});
    };

    render() {
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary rounded-pill btn-new-rel px-5 btn-sm"
                        onClick={this.openModal} disabled={this.props?.licenseExpired}><IPlus className={"padding-right-4px"} width="14" fill={ '#FFFFFF'}/>&nbsp; Nuovo soggetto
                </button>
                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="modal-subject"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>Nuovo soggetto</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CorproForm {...this.props} closeModal={this.closeModal}/>
                    </Modal.Body>
                </Modal>


            </React.Fragment>
        )
    }
}
