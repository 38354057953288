import React from 'react';
import   { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("it", it);

export class ProvinceField extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {isValid:true, msg:''}
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.error !== undefined){
            this.setState({error: nextProps.error});
        }
    }

   render(){
        const {name, province, onChange, onFocus, disabled, placeholder, hint} = this.props;
       return (
           <React.Fragment>
               <div className='form-group'>
                   {/* <label>Prov.</label> */}
                   <div className='input-group col-6 ps-0'>
                       <input type={"text"} className={"form-control"}
                              name={name}
                              onFocus={onFocus}
                              onChange={onChange}
                              value={province}
                              disabled={disabled}
                              placeholder={placeholder}
                              />
                   </div>
                   <div className="provInfo">
                       {hint || "*(EE per Estero)"}
                   </div>
                   {  !this.state.error.isValid
                       ? <div className="text-danger">{this.state.error.msg}</div>
                       : ''}
               </div>
           </React.Fragment>
       )
   }
}



