import React, {useEffect, useState} from "react";
import {baseSearchObject, fieldTypes, TableUtils} from "../../../shared/tables/TableUtils";
import {StarTable} from "../../../shared/tables/StarTable";
import {searchCrsSubjects} from "../../../../actions";
import imgShowBlu from "../../../../styles/images/show_blu.png";
import { isNotEmpty } from "../../../shared/Utility";
import { useHistory } from "react-router-dom";


export function CrsSubjectList(props) {
    const [subjects, setSubjects] = useState();
    const [show] = useState(false);
    const [rows, setRows] = useState([]);
    const [searchObject, setSearchObject] = useState({
        ...baseSearchObject,
        subjectCode: null,
        fiscalCode: null,
        companyName: null,
        idFiscalOicrTrust: null,
        referenceYear: null,
        id: null,
    })

    const [id, setId] = useState(null);
    const tableUtils = TableUtils;
    const tableName = "crs-subject"
    const history = useHistory();

    const headers = [
        tableUtils.composeHeader({
            fieldName: "subjectCode",
            displayedName: "Codice",
        }),
        tableUtils.composeHeader({
            fieldName: "fiscalCode",
            displayedName: "Codice Fiscale",
        }),
        tableUtils.composeHeader({
            fieldName: "companyName",
            displayedName: "Soggetto obbligato"
        }),
        tableUtils.composeHeader({
            fieldName: "idFiscalOicrTrust",
            displayedName: "OICR"
        }),
        tableUtils.composeHeader({
            fieldName: "referenceYear",
            displayedName: "Anno Riferimento"
        }),
        tableUtils.composeHeader({
            fieldName: "expiration",
            type: fieldTypes.DATE,
            displayedName: "Scad. Certificati"
        }),
        tableUtils.composeHeader({
            fieldName: "actions",
            sortable: false,
            searchable: false,
            maxWidth: '100px',
            additionalClass: 'justify-content-center',
            displayedName: "Dettaglio"
        })
    ];

    const prepareRows = (newSubjects) => {
        return newSubjects.map(c => {
            return tableUtils.composeRow({
                    id: c.id,
                    cellData: {
                        subjectCode: tableUtils.composeCell({
                            fieldName: "subjectCode",
                            fieldValue: c.subjectCode
                        }),
                        fiscalCode: tableUtils.composeCell({
                            fieldName: "fiscalCode",
                            fieldValue: c.fiscalCode
                        }),
                        companyName: tableUtils.composeCell({
                            fieldName: "companyName",
                            fieldValue: c.companyName
                        }),
                        idFiscalOicrTrust: tableUtils.composeCell({
                            fieldName: "idFiscalOicrTrust",
                            fieldValue: c.idFiscalOicrTrust
                        }),
                        referenceYear: tableUtils.composeCell({
                            fieldName: "referenceYear",
                            fieldValue: c.referenceYear
                        }),
                        expiration: tableUtils.composeCell({
                            fieldName: "expiration",
                            fieldValue: c.expiration
                        }),
                        actions: tableUtils.composeCell({
                            fieldName: "actions",
                            additionalClass: 'd-flex justify-content-center p-0',
                            component: () => {
                                return <div className="d-flex justify-content-center p-0">
                                    <button className='btn btn-outline-primary btn-table'
                                            onClick={() => props.openDetail(c.id)}>
                                        <img src={imgShowBlu} alt={"Mostra dettagli"}/>
                                    </button>
                                </div>
                            }
                        }),
                    },
                }
            )


        })

    }

    const onClickRow = (subject) => {
        setId(subject.id)
        const path = props.licenseExpired ? "#" : `/app/crs/relationship/${subject.id}`
        history.push(path)
    }

    const showDetail = (index) => {
        if (index !== this.state.indexShow){
            this.setState({
                show: true,
                indexShow: index
            })
        } else {
            this.setState({
                show: !this.state.show,
                indexShow: index
            })
        }
    };

    useEffect(() => {
        let newSubjects = subjects ? subjects : props.subjects;
        setRows(prepareRows(newSubjects));
    }, [subjects])

    const renderTable = () => {
        return (
            <StarTable
                headerColums={headers}
                rows={rows}
                isLoading={show}
                tableConfigurationKey={tableName}
                searchObjectPrototype={searchObject}
                setSearchObject={setSearchObject}
                searchCallBack={(searchObject) => searchCrsSubjects(searchObject)}
                onExecutedSearch={content => setSubjects(content)}
                usePagination={true}
                withCheckBoxes={true}
                onClickRow={onClickRow}
                actionsComponent={props.actionsComponent}
            />

        )
    }

    return (
        <React.Fragment >
            {renderTable()}
        </React.Fragment>
    )
}

export default CrsSubjectList
