import React from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {ArcoSubjectForm} from "./ArcoSubjectForm";
import imgShowBlu from "../../../../styles/images/show_blu.png";
import {ReactComponent as ISort} from "../../../../styles/images/svgs/regular/sort.svg";
import { Link } from "react-router-dom";
import * as styles from "../../../../config/styles";
import '../../../../styles/dac7NewStyles.css';


class ArcoSubjectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            indexShow: 0,
            subjects: undefined,
            sortCompanyName: 1,
            sortFiscalCode: 1,
            sortSubject: 1,
            sortCode: 1,
            sortYear: 1
        }
    }

    onCheckClick = (event) => {
        if (event.target.checked)
            this.checkAll();
        else
            this.uncheckAll();
    };

    checkAll = () => {
        document.getElementById('checkboxAllId').checked = true;
        this.props.subjects.map(subject => {
            const checkboxDetailsId = `checkboxDetails${subject.id}`;
            const idCheckbox = `checkboxDetails${subject.id}`;
            if(document.getElementById(checkboxDetailsId)){
                document.getElementById(checkboxDetailsId).checked = true;
            }
            if(document.getElementById(idCheckbox)){
                document.getElementById(idCheckbox).checked = true;
            }
            return undefined
        });
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.add("bg-cobalt","bold");
            }
        }
        this.props.checkAllSubjects();

    };

    uncheckAll = () => {
        document.getElementById('checkboxAllId').checked = false;
        this.props.subjects.map(subject => {
            const checkboxDetailsId = `checkboxDetails${subject.id}`;
            const idCheckbox = `checkboxDetails${subject.id}`;
            if (document.getElementById(checkboxDetailsId)){
                document.getElementById(checkboxDetailsId).checked = false;
            }
            if (document.getElementById(idCheckbox)){
                document.getElementById(idCheckbox).checked = false;
            }
            return undefined
        });
        this.props.uncheckAllSubjects();
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt","bold");
            }
        }
    };

    onCheckBoxClick = (event, subject) => {
        if (event.target.checked) {
            this.props.checkSubject(subject);
            let element = document.getElementById("row-"+ subject.id);
            if(element)
                element.classList.add("bg-cobalt","bold");
        } else {
            this.props.uncheckSubject(subject);
            document.getElementById('checkboxAllId').checked = false;
            let element = document.getElementById("row-"+ subject.id);
            if(element)
                element.classList.remove("bg-cobalt","bold");
        }

    };

    showDetail = (index) => {
        if (index !== this.state.indexShow){
            this.setState({
                show: true,
                indexShow: index
            })
        } else {
            this.setState({
                show: !this.state.show,
                indexShow: index
            })
        }
    };

    showRelations = (subjectId) => {
        window.open(`/app/arco/registrations/${subjectId}`, "_self");
    };

    renderRows(subjects) {
        return subjects.map((subject, index) => {
            const idCheckbox = `checkboxDetails${subject.id}`;
            return (
                <React.Fragment key={index}>
                    <div className={'text-start row-table' + (this.state.show && this.state.indexShow === index ? ' bg-blue bold' : '')}
                        id={`row-${subject.id}`}>
                        <div className='col w-5 text-center div-td'>
                            <input type="checkbox"
                                   id={idCheckbox}
                                   onClick={e => this.onCheckBoxClick(e, subject)}/>
                        </div>
                        <Link className={`col w-20 ${styles.rowLink}`} to={`/app/arco/registrations/${subject.id}`}>{subject.fiscalCode}</Link>
                        <Link className={`col w-40 ${styles.rowLink}`}
                             to={`/app/arco/registrations/${subject.id}`}><span className="notranslate">{subject.companyName}</span></Link>
                        <Link className={`col w-15 ${styles.rowLink}`} to={`/app/arco/registrations/${subject.id}`}>
                            {subject.arcoSubjectType.type.toUpperCase()}
                        </Link>
                        <Link className={`col w-15 ${styles.rowLink}`} to={`/app/arco/registrations/${subject.id}`}>
                            {subject.location}
                        </Link>
                        <div className={`col w-8 ${styles.rowLink} text-center`}>
                            <img alt={'Mostrea dettagli soggetto'} src={imgShowBlu} onClick={() => this.showDetail(index)}/>
                        </div>
                    </div>
                    <TransitionGroup component={null}>
                        {this.state.show && this.state.indexShow === index &&
                            <CSSTransition
                                in={this.state.show}
                                timeout={300}
                                classNames="visible"
                                unmountOnExit>
                                <div className="row-detail bg-blue">
                                    <div className="col-md-12 col-lg-12 text-start p-0">
                                        {<ArcoSubjectForm
                                            {...this.props}
                                            showDetail={this.showDetail}
                                            subjectItem={subject}
                                            index={index}
                                            countries={this.props.countries}
                                            user={this.props.user}
                                            handleCloseModal={this.showDetail}
                                            isLicenseValid={this.props.isLicenseValid}
                                        />}
                                    </div>
                                </div>
                            </CSSTransition>
                            }
                    </TransitionGroup>
                </React.Fragment>
            )
        });
    };

    sortBy = (val) => {
        if(val){
            let subjects = (this.state.subjects && this.state.subjects.length > 0) ? this.state.subjects : this.props.subjects;
            let subjectsOrdered = subjects;
            switch(val) {
                case "companyName":
                    // Sort by companyName
                    subjectsOrdered =  subjects.sort((a,b) => (a.companyName > b.companyName) ? this.state.sortCompanyName : ((b.companyName > a.companyName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortCompanyName : -this.state.sortCompanyName});
                    break;
                case "fiscalCode":
                    // Sort by fiscalCode
                    subjectsOrdered =  subjects.sort((a,b) => (a.fiscalCode > b.fiscalCode) ? this.state.sortFiscalCode : ((b.fiscalCode > a.fiscalCode) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortFiscalCode : -this.state.sortFiscalCode});
                    break;
                case "subject":
                    // Sort by subject_type
                    subjectsOrdered =  subjects.sort((a,b) => (a.subjectType > b.subjectType) ? this.state.sortSubject : ((b.subjectType > a.subjectType) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortSubject : -this.state.sortSubject});
                    break;
                case "year" :
                    // Sort by location
                    subjectsOrdered =  subjects.sort((a,b) => (a.location > b.location) ? this.state.sortYear : ((b.location > a.location) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        subjectsOrdered.reverse();
                    }
                    this.setState({subjects: subjectsOrdered, sortYear : -this.state.sortYear});
                    break;
                default:
                    return;
            }
        }
    }

    render() {
        let subjectsData = (this.state.subjects) ? this.state.subjects : this.props.subjects;
        return (
            <React.Fragment>
                <div className="text-start header-table">
                    <div className='col w-5 text-center div-td'>
                        <input type="checkbox"
                               id='checkboxAllId'
                               onClick={this.onCheckClick}/>
                    </div>
                    <div className='col w-20 div-td'><strong>Codice Fiscale</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("fiscalCode")}/></div>
                    <div className='col w-40 div-td'><strong>Soggetto</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("companyName")}/></div>
                    <div className='col w-15 div-td'><strong>Tipologia</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("subject")}/></div>
                    <div className='col w-15 div-td'><strong>Località</strong> <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("year")}/></div>
                    <div className='col w-8 div-td' style={{textAlign:'center'}}><strong>Dettaglio</strong></div>
                </div>
                {this.renderRows(subjectsData)}
                
                {this.props.subjectItem?.arcoSubjectType || (this.props?.subjectTypes ? this.props?.subjectTypes[0] : {}) && <ArcoSubjectForm
                                            {...this.props}
                                            showDetail={this.showDetail}
                                            countries={this.props.countries}
                                            user={this.props.user}
                                            handleCloseModal={this.showDetail}
                                            isLicenseValid={this.props.isLicenseValid}
                                        />}
            </React.Fragment>
        )
    }
}

export default ArcoSubjectList
