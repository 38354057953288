import { isApplicationExpired } from "../../actions";
import { APPLICATION_COMUREI, CART_LINK, getEcommerceProductId, getEcommerceSubjectProductId } from "../../config/Constants";
import { formatDateForDisplay } from "./Utility";

export function SubjectTotalAndCallToAction({
    total = 0,
    used = 0,
    remaining = 0,
    licenseType = "",
    customerApplication
}) {
    /**
     * Se la licenza è in demo, indirizzo l'utente verso la pagina di carrello con prodotto base + il numero di soggetti usati
     * se DEMO
     Abbonanmento in DEMO valido fino al xxxxx  ACQUISTA
     se licenza
     Abbonamento valido fino al xxxxxxxxxxxxx
     Abbonamento SCADUTO il xxxxxxx  RINNOVA
     */


    const renderAddSubjectsButton = (customerApplication, remaining) => {
        if (remaining >= 0) {

            return (
                <a href={CART_LINK(0
                    , getEcommerceSubjectProductId(customerApplication?.application.name)
                    , 1)}
                    target='_blank' rel="noopener noreferrer" className='text-light'>
                    <button type="button" className="btn btn-empty  btn-sm" >
                        AGGIUNGI
                    </button>
                </a>
            )

        } else {
            return (
                <a href={CART_LINK(0
                    , getEcommerceSubjectProductId(customerApplication?.application.name)
                    , remaining*-1)}
                    target='_blank' rel="noopener noreferrer" className='text-light'>
                    <button type="button" className="btn btn-danger  btn-sm" >
                        AGGIUNGI MANCANTI
                    </button>
                </a>
            )
        }
    }

    const renderRinnovoSection = (customerApplication,remaining,used,licenseType) => {
        if (customerApplication?.application.name !== APPLICATION_COMUREI) {
            return (
                <>
                    <span className='ms-2 me-2 mt-2' style={{ fontWeight: "bold" }}>
                        Abbonamento
                        {
                            licenseType === "DEMO" &&
                            <> in <strong style={{ color: "red" }}>DEMO</strong> </>

                        }
                        {
                            isApplicationExpired(customerApplication)
                                ?
                                <> &nbsp;<strong style={{ color: "red" }}>SCADUTO</strong> il <strong>{formatDateForDisplay(customerApplication?.expiration)}</strong></>
                                :
                                <>&nbsp;valido fino al <strong>{formatDateForDisplay(customerApplication?.expiration)}</strong></>

                        }
                    </span>
                    {
                        (licenseType === "DEMO" || isApplicationExpired(customerApplication)) &&

                        <a href={CART_LINK(getEcommerceProductId(customerApplication?.application.name), getEcommerceSubjectProductId(customerApplication?.application.name), used)} target='_blank' rel="noopener noreferrer" className='text-light'>
                            <button type="button" className="btn btn-danger  btn-sm" >
                                {(licenseType === "DEMO" && "ACQUISTA") || (isApplicationExpired(customerApplication) && "RINNOVA")}
                            </button>
                        </a>
                    }
                    {
                        remaining < 0 &&
                        <strong style={{ color: "red" }}>
                            Acquista i soggetti mancanti ({remaining*-1})
                        </strong>
                    }
                </>
            )
        } else {
            return "";
        }
    }


    if (customerApplication) {
        return (
            <div className="d-flex flex-wrap align-items-center">
                <h3 className="me-2 mb-0 mb-2 mt-1 text-custom-ElencoSoggeto-p">
                    Elenco soggetti 
                    <span className="m-2 border-custom p-2  d-inline-block">
                        <a className="me-2 text-custom-ElencoSoggetto">Acquistati</a>
                        <span className="me-2 text-custom-ElencoSoggetto-n">{total}</span>
                        <a className="text-custom-ElencoSoggetto me-2">Disponibili</a>
                        <span className="text-custom-ElencoSoggetto-n me-2">{remaining}</span>
                    </span>
                </h3>

                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-3">
                    {licenseType !== "DEMO" && !isApplicationExpired(customerApplication) &&
                        renderAddSubjectsButton(customerApplication, remaining)
                    }
                    {renderRinnovoSection(customerApplication, remaining, used, licenseType)}
                </div>
            </div>)
    } else {
        return ""
    }


}