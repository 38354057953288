import React, { useEffect, useState } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { DateField } from "../../../shared/form/DateField";
import { BwmInput } from "../../../shared/form/BwmInput";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { useSelector } from 'react-redux';
import { PopupError } from "../../../shared/PopupError";
import * as actions from "../../../../actions";
import { PopupSuccess } from "../../../shared/PopupSuccess";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import * as Constants from "../../../../config/Constants";
import { base64ToArrayBuffer, downloadFile } from '../../../shared/Utility';
import { ISave, IUndo } from '../../../../styles/icons';

registerLocale("it", it);

export function ArcoRegistryPrintForm(props) {

    const TIPO_SOGG_COMPROORO= "36";

    const arcoCollaboratorsFromStore = useSelector(state => state.arcoCollaborators.data);
    const [loading, setLoading] = useState(false)
    const arcoSubject = useSelector(state => state.arcoSubject.data);


    const setArcoCollaborators = () => {
        let newObjects = {};
        let newObjectLists = [];

        arcoCollaboratorsFromStore.map(collaborator => {
            if (collaborator.disabled === true) {
                newObjects = {
                    id: collaborator.id,
                    description: collaborator.name,
                }
                newObjectLists.push(newObjects)
            }
        })
        return newObjectLists;
    };
    const arcoCollaborators = setArcoCollaborators();



    const [fascicolo, setFascicolo] = useState(false);
    const hasSignatories = props.selectedPrintItem?.arcoRegistrySignatories && props.selectedPrintItem?.arcoRegistrySignatories?.length > 0;
    const [personaFisica, setPersonaFisica] = useState(!hasSignatories && props.selectedPrintItem?.personType === Constants.PF);
    const [personaFisicaEnabled, setPersonaFisicaEnabled] = useState(personaFisica)
    const [personaFisicaProcuratore, setPersonaFisicaProcuratore] = useState(hasSignatories && props.selectedPrintItem?.personType === Constants.PF);
    const [personaFisicaProcuratoreEnabled, setpersonaFisicaProcuratoreEnabled] = useState(personaFisicaProcuratore);

    const [personaGiuridica, setPersonaGiuridica] = useState(hasSignatories && props.selectedPrintItem?.personType === Constants.PNF);
    const [personaGiuridicaEnabled, setpersonaGiuridicaEnabled] = useState(personaGiuridica);
    const [schedaAnalisi, setSchedaAnalisi] = useState(true);
    const shouldPrivacyBeEnabled = () => {
        if (props.selectedPrintItem?.personType === Constants.PNF && !hasSignatories) {
            return false;
        }
        return true;
    }
    const [privacy, setPrivacy] = useState(shouldPrivacyBeEnabled());
    const [antiterrorismo, setAntiterrorismo] = useState(true);
    const [dataStampa, setDataStampa] = useState(props.arcoRegistriesOperations?.profilingDate ? new Date(props.arcoRegistriesOperations?.profilingDate) : new Date());
    const [natura, setNatura] = useState(props.arcoRegistriesOperations?.nature);
    const [scopo, setScopo] = useState(props.arcoRegistriesOperations?.purpose);
    const [stampaCollaboratore, setStampaCollaboratore] = useState();
    const [collaboratore, setCollaboratore] = useState(arcoCollaboratorsFromStore[0]);

    const [schedaNumerata, setSchedaNumerata] = useState(arcoSubject?.arcoSubjectType?.code === TIPO_SOGG_COMPROORO );

    const [dataStampaError, setDataStampaError] = useState({ isValid: true, msg: "" });
    const [naturaError, setNaturaError] = useState({ isValid: true, msg: "" });
    const [scopoError, setScopoError] = useState({ isValid: true, msg: "" });


    const [formData, updateFormData] = useState({
        arcoOperationId: props?.arcoRegistriesOperations?.id,
        arcoCollaboratorId: collaboratore?.id,
        arcoRegistryId: props?.selectedPrintItem?.id,
        includeDossier: fascicolo,
        includePhysicalPersonDeclaration: personaFisica,
        includeRiskAnalysis: schedaAnalisi,
        includePhysicalPersonAndAttorneyDeclaration: personaFisicaProcuratore,
        includeAntiterrorismCheck: antiterrorismo,
        includePrivacyPolicy: privacy,
        includeLegalPersonDeclaration: personaGiuridica,
        includeSchedaNumerata: schedaNumerata,
        operationNature: natura,
        operationPurpose: scopo,
        printDate: dataStampa
    }
    );

    useEffect(() => {
        updateFormData({ ...formData, arcoRegistryId: props.selectedPrintItem?.id });
    }, [props.selectedPrintItem]);

    const handlerChangeFascicolo = (e) => {
        setFascicolo(e.target.checked);
        updateFormData({
            ...formData,
            includeDossier: e.target.checked
        });
    };

    const handlerChangePersonaFisica = (e) => {
        setPersonaFisica(e.target.checked);
        updateFormData({
            ...formData,
            includePhysicalPersonDeclaration: e.target.checked
        });
    };

    const handlerChangeSchedaAnalisi = (e) => {
        setSchedaAnalisi(e.target.checked);
        updateFormData({
            ...formData,
            includeRiskAnalysis: e.target.checked
        });
    };

    const handlerChangePersonaFisicaProcuratore = (e) => {
        setPersonaFisicaProcuratore(e.target.checked);
        updateFormData({
            ...formData,
            includePhysicalPersonAndAttorneyDeclaration: e.target.checked
        });
    };

    const handlerChangePrivacy = (e) => {
        setPrivacy(e.target.checked);
        updateFormData({
            ...formData,
            includePrivacyPolicy: e.target.checked
        });
    };

    const handlerChangePersonaGiuridica = (e) => {
        setPersonaGiuridica(e.target.checked);
        updateFormData({
            ...formData,
            includeLegalPersonDeclaration: e.target.checked
        });
    };

    const handlerChangeAntiterrorismo = (e) => {
        setAntiterrorismo(e.target.checked);
        updateFormData({
            ...formData,
            includeAntiterrorismCheck: e.target.checked
        });
    };

    
    const handlerChangeSchedaNumerata = (e) => {
        setSchedaNumerata(e.target.checked);
        updateFormData({
            ...formData,
            includeSchedaNumerata: e.target.checked
        });
    };


    const handlerChangeDataStampa = (date) => {
        setDataStampa(date);
        setDataStampaError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            printDate: date
        });
    };

    const handlerChangeNatura = (e) => {
        let value = e.target.value.toUpperCase();
        setNatura(value);
        setNaturaError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            operationNature: value
        });
    };

    const handlerChangeScopo = (e) => {
        let value = e.target.value.toUpperCase();
        setScopo(value);
        setScopoError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            operationPurpose: value
        });
    };

    const handlerChangeStampaCollaboratore = (e) => {
        setStampaCollaboratore(e.target.checked);
        updateFormData({
            ...formData,
            includeCollaborator: e.target.checked
        });
    };

    const handlerChangeCollaboratore = (e) => {
        const collaboratoreId = e.target[e.target.selectedIndex].value;
        setCollaboratore(collaboratoreId);
        updateFormData({
            ...formData,
            arcoCollaboratorId: collaboratoreId
        });
    };

    const getSurnameOrDenomination = (item) => {
        return Constants.PF === item.personType ? (item.lastName + ' ' + item.firstName) : item.denomination
    }
    const print = () => {
        setLoading(true);
        if (!props.arcoRegistriesOperations?.id) {
            PopupError({ ...props, text: "Impossibile scaricare: Operazione non salvata" });
            setLoading(false);
        } else if (!antiterrorismo && !fascicolo && !personaFisica && !personaFisicaProcuratore
            && !privacy && !schedaAnalisi && !personaGiuridica && !schedaNumerata) {
            PopupError({ ...props, text: "Selezionare almeno una opzione" });
            setLoading(false);
        } else {
            actions.printArcoOperationDocument(formData).then((response) => {
                if (response) {
                    downloadFile(base64ToArrayBuffer(response),
                        "DOCUMENTI - " + props.arcoRegistriesOperations.description.substring(0, 20) + " "
                        + getSurnameOrDenomination(props?.selectedPrintItem).substring(0, 30) + ".zip");
                    PopupSuccess({ text: "File scaricato" });
                } else {
                    PopupError({ ...props, text: "File non trovato" });
                }
            }, (err) => {
                PopupError({ ...props, text: "File non trovato" });
            }).then(() => {
                props.onHide();
                setLoading(false);
            });
        }

    };


    return (
        <div className="row-detail bg-blue py-4 px-3  d-flex row-form big">
            {loading && <PageSpinner />}
            <div>
                <div className="soggetto-crs">
                    <form id={`stampa-`}>
                        <div className='row'>
                            <h3 className="col-12 text-center">STAMPA DOCUMENTI DEL SOGGETTO</h3>
                        </div>

                        <div className="row col-12">
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="fascicolo"
                                    label='FASCICOLO'
                                    className='form-control'
                                    value={fascicolo}
                                    onClick={handlerChangeFascicolo}
                                ></BwmCheckbox>
                            </div>
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="rischio"
                                    label='SCHEDA ANALISI DEL RISCHIO'
                                    className='form-control'
                                    value={schedaAnalisi}
                                    onClick={handlerChangeSchedaAnalisi}
                                ></BwmCheckbox>
                            </div>
                        </div>
                        <div className="row col-12">
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="pf"
                                    label='DICHIARAZIONE PERSONA FISICA'
                                    className='form-control'
                                    value={personaFisica}
                                    onClick={handlerChangePersonaFisica}
                                    disabled={!personaFisicaEnabled}
                                ></BwmCheckbox>
                            </div>
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="privacy"
                                    label='INFORMATIVA PRIVACY'
                                    className='form-control'
                                    value={privacy}
                                    onClick={handlerChangePrivacy}
                                ></BwmCheckbox>
                            </div>
                        </div>
                        <div className="row col-12">
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="pf e procuratore"
                                    label='DICHIARAZIONE PERSONA FISICA E PROCURATORE'
                                    className='form-control'
                                    value={personaFisicaProcuratore}
                                    onClick={handlerChangePersonaFisicaProcuratore}
                                    disabled={!personaFisicaProcuratoreEnabled}
                                ></BwmCheckbox>
                            </div>
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="antiterrorismo"
                                    label='CONTROLLO LISTE ANTITERRORISMO'
                                    className='form-control'
                                    value={antiterrorismo}
                                    onClick={handlerChangeAntiterrorismo}
                                ></BwmCheckbox>
                            </div>
                        </div>
                        <div className="row col-12 pb-3">
                            <div className="col-md-6 col-lg-6 pt-1 pb-1">
                                <BwmCheckbox
                                    name="pg"
                                    label='DICHIARAZIONE PERSONA GIURIDICA'
                                    className='form-control'
                                    value={personaGiuridica}
                                    onClick={handlerChangePersonaGiuridica}
                                    disabled={!personaGiuridicaEnabled}
                                ></BwmCheckbox>
                            </div>
                            {arcoSubject?.arcoSubjectType?.code === TIPO_SOGG_COMPROORO 
                                &&
                                <div className="col-md-6 col-lg-6 pt-1 ">
                                    <BwmCheckbox
                                        name="schedanumerata"
                                        label='Scheda Numerata CV RICEVUTA'
                                        className='form-control'
                                        value={schedaNumerata}
                                        onClick={handlerChangeSchedaNumerata}
                                    ></BwmCheckbox>
                                </div>

                            }

                        </div>
                        <div className='row col-12 mt-3'>
                            <h6 className="col-12"><b>solo per questa stampa:</b></h6>
                        </div>
                        <div className='row col-12 mt-1'>
                            <div className="col-lg-2">
                                <DateField
                                    label="Data STAMPA"
                                    onChange={date => handlerChangeDataStampa(date)}
                                    error={dataStampaError}
                                    date={dataStampa}
                                />
                            </div>
                            <div className='col-md-3'>
                                <BwmInput name='natura'
                                    label='NATURA OPERAZIONI'
                                    className='form-control'
                                    value={natura}
                                    onChange={e => handlerChangeNatura(e)}
                                    error={naturaError}
                                />
                            </div>
                            <div className='col-md-3'>
                                <BwmInput name='scopo'
                                    label='SCOPO OPERAZIONI'
                                    className='form-control'
                                    value={scopo}
                                    onChange={e => handlerChangeScopo(e)}
                                    error={scopoError}
                                />
                            </div>
                        </div>
                        <div className="row col-12 mt-3">
                            <div className="col-lg-3 mt-4">
                                <BwmCheckbox
                                    name="collaboratore"
                                    label='STAMPA COLLABORATORE'
                                    className='form-control'
                                    value={stampaCollaboratore}
                                    onClick={handlerChangeStampaCollaboratore}
                                ></BwmCheckbox>
                            </div>
                            <div className="col-3">
                                {stampaCollaboratore &&
                                    <div className="inputSelect">
                                        <BwmSelect
                                            options={arcoCollaborators}
                                            name='collaboratori'
                                            label='COLLABORATORE'
                                            className='form-control'
                                            value={collaboratore}
                                            onChange={handlerChangeCollaboratore}
                                        />
                                    </div>}
                            </div>
                        </div>
                    </form>

                    <div
                        className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                        <button
                            className="btn btn-outline-primary btn-empty px-4 btn-sm"
                            onClick={props.onHide}><IUndo className={"padding-right-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                        </button>

                        <button onClick={print}
                            className="btn btn-primary btn-new-rel px-5"><ISave className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )

}