import React, {useState} from 'react';
import {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import {ModalCreateRelationship} from "./ModalCreateRelationship";
import {ReactComponent as IPlus} from "../../../../../styles/images/svgs/regular/plus.svg";


registerLocale("it", it);


const CreateRelationshipForm = props => {
    const [modalShow, setModalShow] = useState(false);

    const refreshList = () => {
        setModalShow(false);
        //props.getRelationships(props.subjectId);
    };

    const openModal = (val) => {
        [].forEach.call(document.querySelectorAll('.row-table'), function (el) {
            if(el.classList[2] === "bg-blue")
                el.click();
        });
        setModalShow(val);
    };
    return (

        <React.Fragment>
            <button type="button" className="btn btn-new-rel px-5 btn-sm" 
                    onClick={() => openModal(true)}><IPlus className={"padding-right-4px"} width="14" fill={`#128186`}/>&nbsp; NUOVO RAPPORTO
            </button>
            <ModalCreateRelationship
                show={modalShow}
                onHide={refreshList}
                subject={props.subject}
                subjectId={props.subjectId}
                relationshipLocations={props.relationshipLocations}
                relationshipProvinces={props.relationshipProvinces}
                currencies={props.currencies}
                roles={props.roles}
                communicationTypes={props.communicationTypes}
                getRelationships={props.getRelationships}
            />
        </React.Fragment>

    )
};


export default CreateRelationshipForm
