import React, { useState, useEffect } from 'react';
import { ReactComponent as IAnagrafica } from "../../../styles/images/svg/id-card.svg";
import { BwmInput } from "../../shared/form/BwmInputB5";
import * as Constants from "../../../config/Constants"
import AutoCompileCustom from "../../shared/form/AutoCompileCustomB5";
import { ProvinceField } from "../application-cora/relationships/ProvinceFieldB5";
import ErrorListAlert from "../../shared/form/ErrorListAlert";
import radioUnsel from "../../../styles/images/radiobutton-unselected.png";
import radioSel from "../../../styles/images/radiobutton-selected.png";
import { ReactComponent as ISave } from "../../../styles/images/svgs/regular/save.svg";
import * as actions from "../../../actions";
import { PageSpinner } from "../../shared/spinner/PageSpinner";
import { PopupError } from "../../shared/PopupError";
import { useSelector } from 'react-redux';
import getText from './labels';
import { Formik, Form, Field } from 'formik';
import FormCheck from 'react-bootstrap/FormCheck'
import * as Yup from "yup";

export default function BlueSubjectForm(props) {

    const lang = useSelector(state => state.blueLanguage.language)
    const labels = getText(lang);
    const customer = useSelector(state => state.auth.customer);
    const locations = useSelector(state => state.locations.customLocations);
    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState(props.subject);
    const [personType, setPersonType] = useState(subject?.personType || Constants.PNF);

    const initialValues = {
        denomination: subject?.denomination || '',
        fiscalCode: subject?.fiscalCode || '',
        address: subject?.address || '',
        location: subject?.location || '',
        province: subject?.province || '',
        postalCode: subject?.postalCode || '',
        email: subject?.email || '',
        personType: subject?.personType || Constants.PNF
    }

    const formErrorsTabMapping = [
        { 
            errorKey: 'fiscalCode',
            errorLabel: 'Codice Fiscale'
        },
        { 
            errorKey: 'denomination',
            errorLabel: personType === Constants.PNF? 'Ragione Sociale' : 'Cognome e Nome'
        },
        { 
            errorKey: 'address',
            errorLabel: 'Indirizzo'
        },
        { 
            errorKey: 'location',
            errorLabel: 'Comune'
        },
        { 
            errorKey: 'province',
            errorLabel: 'Provincia'
        },
        { 
            errorKey: 'postalCode',
            errorLabel: 'CAP'
        },
        { 
            errorKey: 'email',
            errorLabel: 'Indirizzo E-Mail'
        }
    ]

    const extractErrors= (errorObj, fields) => {
        const result = [];
      
        // Helper function to access nested fields using dot notation
        const getNestedField = (obj, fieldPath) => {
          return fieldPath.split('.').reduce((o, key) => (o && o[key] !== undefined) ? o[key] : null, obj);
        };
      
        // Iterate over each field and extract errors
        fields.forEach(({ errorKey, errorLabel }) => {
          const error = getNestedField(errorObj, errorKey);
          if (error) {
            result.push({ errorKey, errorLabel, errorMessage: error });
          }
        });
      
        return result;
      };



    const [denomination, setDenomination] = useState(subject?.denomination || '');
    const [fiscalCode, setFiscalCode] = useState(subject?.fiscalCode || '');
    const [address, setAddress] = useState(subject?.address || '');
    const [province, setProvince] = useState(subject?.province || '');
    const [location, setLocation] = useState(subject?.location || '');
    const [postalCode, setPostalCode] = useState(subject?.postalCode || '');
    const [email, setEmail] = useState(subject?.email || '');
    

    const valid = { isValid: true, msg: "" };
    const notValid = { isValid: false, msg: labels.REQUIREDFIELD };
    const [errorDenomination, setErrorDenomination] = useState(valid);
    const [errorFiscalCode, setErrorFiscalCode] = useState(valid);
    const [errorAddress, setErrorAddress] = useState(valid);
    const [errorProvince, setErrorProvince] = useState(valid);
    const [errorLocation, setErrorLocation] = useState(valid);
    const [errorPostalCode, setErrorPostalCode] = useState(valid);
    const [errorEmail, setErrorEmail] = useState(valid);

    const validationSchema = Yup.object().shape({
        denomination: Yup.string().required('Campo Obbligatorio'),
        fiscalCode: Yup.string().required('Campo Obbligatorio'),
        address: Yup.string().required('Campo Obbligatorio'),
        province: Yup.string().required('Campo Obbligatorio'),
        location: Yup.string().required('Campo Obbligatorio'),
        postalCode: Yup.string().required('Campo Obbligatorio'),
        email: Yup.string().email('Formato email non valido').required('Campo Obbligatorio'),
        fiscalCode: Yup.string().required('Campo Obbligatorio'),
        personType: Yup.string().required('Selezionare il tipo di persona')
    })


    useEffect(() => {
        if (!subject) {
            setSubject({
                ...subject,
                personType: personType,
                customer: customer

            });
        }
    })

    const handleFiscalCode = (val, setValues, values) => {
        if (val) {
            actions.getBaseRegistryByFiscalCode(val).then(
                (baseRegistry) => {
                    if (baseRegistry) {
                        let newLocation = values.location || baseRegistry.location?.location || '';
                        let newDenomination = values.denomination || baseRegistry.companyName || '';
                        let newProvince = values.province || baseRegistry.location?.province || '';
                        let newPostalCode = values.postalCode || baseRegistry.location?.postalCode || '';
                        setValues({
                            ...values,
                            denomination: newDenomination,
                            location: newLocation,
                            province: newProvince,
                            postalCode: newPostalCode
                          })

                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
        }
    }

    const handleInputAutocompile = (newLocation, setValues, values) => {
        newLocation = newLocation.toUpperCase()
        const locationObject = locations?.find((e) => {
            return e.name === newLocation;
        });
        if (locationObject) {
            setValues({
                ...values,
                location: locationObject.name?.toUpperCase() || '',
                province: locationObject.province?.toUpperCase() || values.province || '',
                postalCode: locationObject.postalCode || values.postalCode || ''
              });
        } else {
            setValues({
                ...values,
                location: newLocation
            });
        }
    }

    const handlePersonTypeClick = (e) => {
        setPersonType(e.target.value);
        setSubject({
            ...subject,
            personType: e.target.value
        });
    }


    const validateFormData = () => {
        let isValid = true
        if (!denomination) {
            setErrorDenomination(notValid)
            isValid = false;
        }
        if (!email) {
            setErrorEmail(notValid);
            isValid = false;
        }
        if (!fiscalCode) {
            setErrorFiscalCode(notValid);
            isValid = false;
        }
        if (!location) {
            setErrorLocation(notValid);
            isValid = false;
        }
        return isValid;
    }

    const saveSubject = () => {
        if (validateFormData()) {
            setLoading(true);
            actions.saveBlueSubject(subject).then(
                (res) => {
                    props.handleCloseModal();
                    props.getSubjects();
                },
                (err) => {

                    PopupError({
                        text: err.status === 511 ?
                            labels.MAXSUBJECTSREACHED
                            :
                            labels.GENERICERROR,
                        title: labels.WARNING,
                        request: labels.CONTACTSUPPORT,
                        close: labels.CLOSE
                    })
                })
                .then(() => {
                    setLoading(false);
                })
        }
    }

    const updateAll = (values) => {
        setSubject({
            ...subject,
            ...values
        });
        saveSubject()
    }
        


    return (
        <React.Fragment>
            <div className="row-detail bg-grey pt-2 pe-3 pb-2 ps-3">
                {loading && <PageSpinner text={labels.SPINNERTITLE} /> }
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => updateAll(values)}
                >
                    {({handleChange, handleBlur, values, errors, touched, setValues, submitForm, validateForm}) => (
                        <Form>
                            {errors && typeof errors === 'object' && Object.keys(errors).length > 0 && (
                                <ErrorListAlert errors={extractErrors(errors, formErrorsTabMapping)} />
                            )}
                            <div className={"col-lg-12"}>
                                <div className="soggetto-crs big pe-4">
                                    
                                    <div className="col-lg-12">
                                        <div className="row align-items-center">
                                            <div className="col-lg-1 mb-2 me-2">
                                                <div className="form-check">
                                                    <div className="form-switch mt-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`personTypeSwitch-${subject?.id || 0}`}
                                                            checked={personType === Constants.PF} // Assume PF is for Physical Person
                                                            onChange={() =>
                                                                handlePersonTypeClick({
                                                                    target: {
                                                                        value:
                                                                            personType === Constants.PNF
                                                                                ? Constants.PF
                                                                                : Constants.PNF,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`personTypeSwitch-${subject?.id || 0}`}
                                                        >
                                                            {personType === Constants.PNF
                                                                ? labels.LEGALPERSON
                                                                : labels.PHYSICALPERSON}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 me-2 mb-2">
                                                <Field name="fiscalCode">
                                                    {({ field }) => (
                                                        <BwmInput
                                                            {...field}
                                                            name="fiscalCode"
                                                            label={labels.FISCALCODE}
                                                            placeholder={labels.FISCALCODE}
                                                            className="form-control"
                                                            value={values.fiscalCode}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                handleFiscalCode(
                                                                    e.target.value,
                                                                    setValues,
                                                                    values
                                                                );
                                                                handleBlur(e);
                                                            }}
                                                            error={errors.fiscalCode}
                                                            touched={touched.fiscalCode}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-lg-6 me-2 mb-2">
                                                <Field name="denomination">
                                                    {({ field }) => (
                                                        <BwmInput
                                                            {...field}
                                                            name="denomination"
                                                            label={
                                                                personType === Constants.PF
                                                                    ? labels.FIRSTLASTNAME
                                                                    : labels.DENOMINATION
                                                            }
                                                            placeholder={
                                                                personType === Constants.PF
                                                                    ? labels.FIRSTLASTNAME
                                                                    : labels.DENOMINATION
                                                            }
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            value={values.denomination}
                                                            error={errors.denomination}
                                                            touched={touched.denomination}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-lg-2 me-2 mb-2">
                                                <Field name="address">
                                                    {({ field }) => (
                                                        <BwmInput
                                                            {...field}
                                                            name="address"
                                                            label={labels.ADDRESS}
                                                            placeholder={labels.ADDRESS}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            value={values.address}
                                                            error={errors.address}
                                                            touched={touched.address}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-lg-2 me-2 mb-3">
                                                <Field name="location">
                                                    {({ field }) => (
                                                        <AutoCompileCustom
                                                            {...field}
                                                            placeholder={labels.LOCATION}
                                                            label={labels.LOCATION}
                                                            id="location"
                                                            handleInputAutocompile={(e) =>
                                                                handleInputAutocompile(
                                                                    e,
                                                                    setValues,
                                                                    values
                                                                )
                                                            }
                                                            filtro={locations}
                                                            value={values.location}
                                                            error={errors.location}
                                                            touched={touched.location}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-lg-1 me-2 mb-3">
                                                <Field name="province">
                                                    {({ field }) => (
                                                        <ProvinceField
                                                            {...field}
                                                            placeholder={labels.PROVINCE}
                                                            hint={labels.OUTSIDEITALY}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            province={values.province}
                                                            error={errors.province}
                                                            touched={touched.province}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-lg-1 col-lg-1 me-2 mb-3">
                                                <Field name="postalCode">
                                                    {({ field }) => (
                                                        <BwmInput
                                                            {...field}
                                                            name="postalCode"
                                                            placeholder={labels.POSTALCODE}
                                                            label={labels.POSTALCODE}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            value={values.postalCode}
                                                            error={errors.postalCode}
                                                            touched={touched.postalCode}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-lg-3 me-2 mb-3">
                                                <Field name="email">
                                                    {({ field }) => (
                                                        <BwmInput
                                                            {...field}
                                                            name="email"
                                                            placeholder={labels.EMAILADDRESS}
                                                            label={labels.EMAILADDRESS}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                handleChange({
                                                                    target: {
                                                                        name,
                                                                        value: value.toUpperCase(),
                                                                    },
                                                                });
                                                            }}
                                                            value={values.email}
                                                            error={errors.email}
                                                            touched={touched.email}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="form-row mt-2 form-relationship-button justify-content-end">
                                    <button className="btn btn-primary rounded-pill btn-new-rel px-5">
                                        <ISave
                                            className={"padding-right-4px svg"}
                                            width="16"
                                            fill={"#FFFFFF"}
                                        />
                                        &nbsp; {labels.CONFIRM}
                                    </button>
                                </div>
                            </div>

                        </Form>
                    )}     
                </Formik>           
            </div>
        </React.Fragment>
    )
}
