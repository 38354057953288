import React, { useState, useEffect } from 'react';
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import '../../../../styles/arcoTablesModal.css';
import { BwmInput } from "../../../shared/form/BwmInput";
import Tabs from "../../application-cora/relationships/Tabs";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as IPrint } from "../../../../styles/images/svgs/regular/print.svg";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import { ReactComponent as IMinus } from "../../../../styles/images/svgs/regular/minus.svg";
import { ReactComponent as IClip } from "../../../../styles/images/svgs/regular/paperclip.svg";
import { ReactComponent as IExport } from "../../../../styles/images/svgs/regular/file-export.svg";
import Autosuggest from 'react-autosuggest';
import { DateField } from "../../../shared/form/DateField";
import { ArcoRegistriesList } from "./ArcoRegistriesList"
import { ArcoRegistrationContractModal } from "./ArcoRegistrationContractModal"
import { ArcoRegistrationProfessionalsModal } from "./ArcoRegistrationProfessionalsModal"
import { ArcoRegistryPrintModal } from "./ArcoRegistryPrintModal"
import * as Constants from "../../../../config/Constants"
import axiosService from "../../../../services/axios-service";
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { ArcoRegistryModal } from './ArcoRegistryModal';
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay, isNotEmpty } from '../../../shared/Utility';
import { isValid } from 'date-fns';
import AutoCompileCustom from '../../../shared/form/AutoCompileCustom';


registerLocale("it", it);

const ARCO_PURPOSE_OPTIONS = [
    {
        name: "Investimento",
        key: 0
    }, {
        name: "Disinvestimento",
        key: 1
    }, {
        name: "Esigenza Personale",
        key: 2
    }, {
        name: "Esigenze di famiglia",
        key: 3
    }, {
        name: "Esigenze aziendali",
        key: 4
    }, {
        name: "Compravendita Immobiliare",
        key: 5
    },
]

const ARCO_NATURE_OPTIONS = [
    {
        name: "Compravendita",
        key: 0
    }, {
        name: "Presazione Professionale",
        key: 1
    }, {
        name: "Incarico",
        key: 2
    }, {
        name: "Mandato",
        key: 3
    }
]

export function ArcoRelationshipNewOperation(props) {
    const dispatch = useDispatch();
    const arcoSubject = useSelector(state => state.arcoSubject.data);
    const customer = useSelector(state => state.auth.customer);
    const domains = useSelector(state => state.arcoDomains.domains);
    const professionistsData = useSelector(state => state.arcoProfessionists.data);
    const [currentOperation, setCurrentOperation] = useState(props.currentOperation);
    const setPropsProfessionalsList = () => {
        let clientList = [];
        if (currentOperation?.arcoProfessionals) {
            clientList = (currentOperation?.arcoProfessionals);
        }
        return clientList;
    };
    const [professionists, setProfessionists] = useState(setPropsProfessionalsList());
    const b1 = currentOperation?.b1;
    const b2 = currentOperation?.b2;
    const b3 = currentOperation?.b3;
    const b4 = currentOperation?.b4;
    const b5 = currentOperation?.b5;
    const b6 = currentOperation?.b6;
    const b7 = currentOperation?.b7;
    const b8 = currentOperation?.b8;

    const [showModal, setShowModal] = useState(false);
    const [showRegistryModal, setShowRegistryModal] = useState(false);
    const [registryModalElement, setRegistryModalElement] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);

    const [popupType, setPopupType] = useState();
    const [selectedPrintItem, setSelectedPrintItem] = useState();
    const [printPopupList, setPrintPopupList] = useState();
    const [printIndex, setPrintIndex] = useState(0)
    const [showModalContract, setShowModalContract] = useState(false);
    const [selectedContract, setSelectedContract] = useState();
    const [arcoCausalOperationsFromStore, setArcoCausalOperationsFromStore] = useState([]);

    const [checkedArcoContractsValues, setCheckedArcoContractsValues] = useState([]);
    const [checkedArcoClientsValues, setCheckedArcoClientsValues] = useState([]);
    const [checkedArcoCounterpartValues, setCheckedArcoCounterpartValues] = useState([]);
    const [checkedArcoProfessionistsValues, setCheckedArcoProfessionistsValues] = useState([]);

    const id = (currentOperation?.id);
    const [numero, setNumero] = useState(currentOperation?.number);
    const [natura, setNatura] = useState(currentOperation ? currentOperation?.nature : arcoSubject.operationNature || "");
    const [scopo, setScopo] = useState(currentOperation ? currentOperation?.purpose : arcoSubject.operationPurpose || "");
    const [descrizione, setDescrizione] = useState(currentOperation ? currentOperation?.description : arcoSubject.operationDescription || "");
    const [dataProfilatura, setDataProfilatura] = useState(currentOperation ? new Date(currentOperation?.profilingDate) : () => {
        let date = new Date();
        date.setHours(12, 0, 0, 0);
        return date
    });

    const [goldPrice, setGoldPrice] = useState(currentOperation ? currentOperation?.goldPrice : "");
    const [silverPrice, setSilverPrice] = useState(currentOperation ? currentOperation?.silverPrice : "");

    const [naturaError, setNaturaError] = useState({ isValid: true, msg: "" });
    const [scopoError, setScopoError] = useState({ isValid: true, msg: "" });
    const [descrizioneError, setDescrizioneError] = useState({ isValid: true, msg: "" });
    const [dataProfilaturaError, setDataProfilaturaError] = useState({ isValid: true, msg: "" });
    const [detailId, setDetailId] = useState(null);
    const searchObject = useSelector(state => state.registrySearchData);
    const operationSearchObject = useSelector(state => state.operationSearchData)

    const hideProfessionalsModal = () => {
        if (!handleValidation()) return;
        setShowModal(!showModal);
    }

    const setPropsClientList = () => {
        let clientList = [];
        if (currentOperation?.arcoRegistriesAsCustomers) {
            clientList = (currentOperation?.arcoRegistriesAsCustomers);
        }
        return clientList;
    };
    const [clients, setClients] = useState(setPropsClientList());

    const setPropsCountropartsList = () => {
        let countropartsList = [];
        if (currentOperation?.arcoRegistriesAsProfessionals) {
            countropartsList = (currentOperation?.arcoRegistriesAsProfessionals);
        }
        return countropartsList;
    };
    const [counterpart, setCounterpart] = useState(setPropsCountropartsList());

    const setPropsContractsList = () => {
        let contractsList = [];
        if (currentOperation?.arcoContracts) {
            let contracts = currentOperation?.arcoContracts;
            contracts.forEach(contract => {
                delete contract.arcoOperation;
            });
            contractsList = contracts;
        }
        return contractsList;
    };
    const [contracts, setContracts] = useState(setPropsContractsList());

    const tipologiaList = () => {
        let tipologiaList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B1") {
                tipologiaList.push(arcoDomain);
            }
        });
        return tipologiaList;
    };
    const [tipologiaLista, setTipologiaLista] = useState(tipologiaList());
    const tipologiaDef = () => {
        let tipologia;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B1") {
                if (b1 === arcoDomain.description) {
                    tipologia = arcoDomain;
                }
                if (!b1 && arcoDomain.def) {
                    tipologia = arcoDomain;
                }
            }
        });
        if (tipologia === undefined || tipologia === null || tipologia === "") {
            tipologia = tipologiaLista[0];
        }
        return tipologia;
    };
    const [tipologia, setTipologia] = useState(tipologiaDef());

    const modalitaSvolgimentoList = () => {
        let modalitaSvolgimentoList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B2") {
                modalitaSvolgimentoList.push(arcoDomain)
            }
        });
        return modalitaSvolgimentoList;
    };
    const [modalitaSvolgimentoLista, setModalitaSvolgimentoLista] = useState(modalitaSvolgimentoList());
    const modalitaSvolgimentoDef = () => {
        let modalitaSvolgimento;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B2") {
                if (b2 === arcoDomain.description) {
                    modalitaSvolgimento = arcoDomain;
                }
                if (!b2 && arcoDomain.def) {
                    modalitaSvolgimento = arcoDomain;
                }
            }
        });
        if (modalitaSvolgimento === undefined || modalitaSvolgimento === null || modalitaSvolgimento === "") {
            modalitaSvolgimento = modalitaSvolgimentoLista[0];
        }
        return modalitaSvolgimento;
    };
    const [modalitaSvolgimento, setModalitaSvolgimento] = useState(modalitaSvolgimentoDef());

    const ammontareList = () => {
        let ammontareList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B3") {
                ammontareList.push(arcoDomain)
            }

        });
        return ammontareList;
    };
    const [ammontareLista, setAmmontareLista] = useState(ammontareList());
    const ammontareDef = () => {
        let ammontare;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B3") {
                if (b3 === arcoDomain.description) {
                    ammontare = arcoDomain;
                }
                if (!b3 && arcoDomain.def) {
                    ammontare = arcoDomain;
                }
            }
        });
        if (ammontare === undefined || ammontare === null || ammontare === "") {
            ammontare = ammontareLista[0];
        }
        return ammontare;
    };
    const [ammontare, setAmmontare] = useState(ammontareDef());

    const frequenzaDurataList = () => {
        let frequenzaDurataList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B4") {
                frequenzaDurataList.push(arcoDomain)
            }

        });
        return frequenzaDurataList;
    };
    const [frequenzaDurataLista, setFrequenzaDurataLista] = useState(frequenzaDurataList());
    const frequenzaDurataDef = () => {
        let frequenzaDurata;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B4") {
                if (b4 === arcoDomain.description) {
                    frequenzaDurata = arcoDomain;
                }
                if (!b4 && arcoDomain.def) {
                    frequenzaDurata = arcoDomain;
                }
            }
        });
        if (frequenzaDurata === undefined || frequenzaDurata === null || frequenzaDurata === "") {
            frequenzaDurata = frequenzaDurataLista[0];
        }
        return frequenzaDurata;
    };
    const [frequenzaDurata, setFrequenzaDurata] = useState(frequenzaDurataDef());

    const ragionevolezzaList = () => {
        let ragionevolezzaList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B5") {
                ragionevolezzaList.push(arcoDomain)
            }
        });
        return ragionevolezzaList;
    };
    const [ragionevolezzaLista, setRagionevolezzaLista] = useState(ragionevolezzaList());
    const ragionevolezzaDef = () => {
        let ragionevolezza;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B5") {
                if (b5 === arcoDomain.description) {
                    ragionevolezza = arcoDomain;
                }
                if (!b5 && arcoDomain.def) {
                    ragionevolezza = arcoDomain;
                }
            }
        });
        if (ragionevolezza === undefined || ragionevolezza === null || ragionevolezza === "") {
            ragionevolezza = ragionevolezzaLista[0];
        }
        return ragionevolezza;
    };
    const [ragionevolezza, setRagionevolezza] = useState(ragionevolezzaDef());

    const areaGeograficaClienteList = () => {
        let areaGeograficaClienteList = [];

        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B6") {
                areaGeograficaClienteList.push(arcoDomain);
            }
        });
        return areaGeograficaClienteList;
    };
    const [areaGeograficaClienteLista, setAreaGeograficaClienteLista] = useState(areaGeograficaClienteList());
    const areaGeograficaClienteDef = () => {
        let areaGeograficaCliente;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B6") {
                if (b6 === arcoDomain.description) {
                    areaGeograficaCliente = arcoDomain;
                }
                if (!b6 && arcoDomain.def) {
                    areaGeograficaCliente = arcoDomain;
                }
            }
        });
        if (areaGeograficaCliente === undefined || areaGeograficaCliente === null || areaGeograficaCliente === "") {
            areaGeograficaCliente = areaGeograficaClienteLista[0];
        }
        return areaGeograficaCliente;
    };
    const [areaGeograficaCliente, setAreaGeograficaCliente] = useState(areaGeograficaClienteDef());

    const areaGeograficaControparteList = () => {
        let areaGeograficaControparteList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B7") {
                areaGeograficaControparteList.push(arcoDomain)
            }
        });
        return areaGeograficaControparteList;
    };
    const [areaGeograficaControparteLista, setAreaGeograficaControparteLista] = useState(areaGeograficaControparteList());
    const areaGeograficaControparteDef = () => {
        let areaGeograficaControparte;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B7") {
                if (b7 === arcoDomain.description) {
                    areaGeograficaControparte = arcoDomain;
                }
                if (!b7 && arcoDomain.def) {
                    areaGeograficaControparte = arcoDomain;
                }
            }
        });
        if (areaGeograficaControparte === undefined || areaGeograficaControparte === null || areaGeograficaControparte === "") {
            areaGeograficaControparte = areaGeograficaControparteLista[0];
        }
        return areaGeograficaControparte;
    };
    const [areaGeograficaControparte, setAreaGeograficaControparte] = useState(areaGeograficaControparteDef());

    const personalizzataList = () => {
        let personalizzataList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B8") {
                personalizzataList.push(arcoDomain)
            }
        });
        return personalizzataList;
    };
    const [personalizzataLista, setPersonalizzataLista] = useState(personalizzataList());
    const personalizzataDef = () => {
        let personalizzata;
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "B8") {
                if (b8 === arcoDomain.description) {
                    personalizzata = arcoDomain;
                }
                if (!b8 && arcoDomain.def) {
                    personalizzata = arcoDomain;
                }
            }
        });
        if (personalizzata === undefined || personalizzata === null || personalizzata === "") {
            personalizzata = personalizzataLista[0];
        }
        return personalizzata;
    };
    const [personalizzata, setPersonalizzata] = useState(personalizzataDef());

    const [formData, updateFormData] = useState({
        id: id,
        arcoRegistriesAsCustomers: clients,
        arcoRegistriesAsProfessionals: counterpart,
        arcoProfessionals: professionists,
        arcoContracts: contracts,
        b1: tipologia.description,
        b2: modalitaSvolgimento.description,
        b3: ammontare.description,
        b4: frequenzaDurata.description,
        b5: ragionevolezza.description,
        b6: areaGeograficaCliente.description,
        b7: areaGeograficaControparte.description,
        b8: personalizzata.description,
        b1Value: tipologia.value,
        b2Value: modalitaSvolgimento.value,
        b3Value: ammontare.value,
        b4Value: frequenzaDurata.value,
        b5Value: ragionevolezza.value,
        b6Value: areaGeograficaCliente.value,
        b7Value: areaGeograficaControparte.value,
        b8Value: personalizzata.value,
        profilingDate: dataProfilatura,
        description: descrizione,
        nature: natura,
        number: numero,
        purpose: scopo,
        goldPrice: goldPrice,
        silverPrice: silverPrice,
        arcoSubject: arcoSubject,
        customer: customer || props?.currentOperation?.custumer

    }
    );

    const [loading, setLoading] = useState(false)

    const handlerChangeTipologia = (e) => {
        const tipologiaId = e.target[e.target.selectedIndex].label;
        let tipologiaValue;
        tipologiaLista.map((arcoDomain, index) => {
            if (arcoDomain.description === tipologiaId) {
                tipologiaValue = arcoDomain
            }
        });
        setTipologia(tipologiaId);
        updateFormData({
            ...formData,
            b1: tipologiaId,
            b1Value: tipologiaValue.value,
        });
    };

    const handlerChangeModalitaSvolgimento = (e) => {
        const modalitaSvolgimentoId = e.target[e.target.selectedIndex].label;
        let modalitaSvolgimentoValue;
        modalitaSvolgimentoLista.map((arcoDomain, index) => {
            if (arcoDomain.description === modalitaSvolgimentoId) {
                modalitaSvolgimentoValue = arcoDomain
            }
        });
        setModalitaSvolgimento(modalitaSvolgimentoId);
        updateFormData({
            ...formData,
            b2: modalitaSvolgimentoId,
            b2Value: modalitaSvolgimentoValue.value
        });
    };

    const handlerChangeAmmontare = (e) => {
        const ammontareId = e.target[e.target.selectedIndex].label;
        let ammontareValue;
        ammontareLista.map((arcoDomain, index) => {
            if (arcoDomain.description === ammontareId) {
                ammontareValue = arcoDomain
            }
        });
        setAmmontare(ammontareId);
        updateFormData({
            ...formData,
            b3: ammontareId,
            b3Value: ammontareValue.value
        });
    };

    const handlerChangeFrequenzaDurata = (e) => {
        const frequenzaDurataId = e.target[e.target.selectedIndex].label;
        let frequenzaDurataValue;
        frequenzaDurataLista.map((arcoDomain, index) => {
            if (arcoDomain.description === frequenzaDurataId) {
                frequenzaDurataValue = arcoDomain
            }
        });
        setFrequenzaDurata(frequenzaDurataId);
        updateFormData({
            ...formData,
            b4: frequenzaDurataId,
            b4Value: frequenzaDurataValue.value
        });
    };

    const handlerChangeRagionevolezza = (e) => {
        const ragionevolezzaId = e.target[e.target.selectedIndex].label;
        let ragionevolezzaValue;
        ragionevolezzaLista.map((arcoDomain, index) => {
            if (arcoDomain.description === ragionevolezzaId) {
                ragionevolezzaValue = arcoDomain
            }
        });
        setRagionevolezza(ragionevolezzaId);
        updateFormData({
            ...formData,
            b5: ragionevolezzaId,
            b5Value: ragionevolezzaValue.value
        });
    };
    const handlerChangeAreaGeograficaCliente = (e) => {
        const areaGeograficaClienteId = e.target[e.target.selectedIndex].label;
        let areaGeograficaClienteValue;
        areaGeograficaClienteLista.map((arcoDomain, index) => {
            if (arcoDomain.description === areaGeograficaClienteId) {
                areaGeograficaClienteValue = arcoDomain
            }
        });
        setAreaGeograficaCliente(areaGeograficaClienteId);
        updateFormData({
            ...formData,
            b6: areaGeograficaClienteId,
            b6Value: areaGeograficaClienteValue.value
        });
    };

    const handlerChangeAreaGeograficaControparte = (e) => {
        const areaGeograficaControparteId = e.target[e.target.selectedIndex].label;
        let areaGeograficaControparteValue;
        areaGeograficaControparteLista.map((arcoDomain, index) => {
            if (arcoDomain.description === areaGeograficaControparteId) {
                areaGeograficaControparteValue = arcoDomain
            }
        });
        setAreaGeograficaControparte(areaGeograficaControparteId);
        updateFormData({
            ...formData,
            b7: areaGeograficaControparteId,
            b7Value: areaGeograficaControparteValue.value
        });
    };

    const handlerChangePersonalizzata = (e) => {
        const personalizzataId = e.target[e.target.selectedIndex].label;
        let personalizzataValue;
        personalizzataLista.map((arcoDomain, index) => {
            if (arcoDomain.description === personalizzataId) {
                personalizzataValue = arcoDomain
            }
        });
        setPersonalizzata(personalizzataId);
        updateFormData({
            ...formData,
            b8: personalizzataId,
            b8Value: personalizzataValue.value
        });
    };

    useEffect(() => {
        if (arcoCausalOperationsFromStore.length == 0) {
            getAllArcoCausalOperations();
        }
    }, []);

    const getAllArcoCausalOperations = () => {
        const axiosInstance = axiosService.getInstance();
        if (arcoSubject.arcoSubjectType) {
            axiosInstance.get(`/api/arco/custom/arco-causal-operations/${arcoSubject?.arcoSubjectType.code}`)//TODO:usare redux
                .then(res => {
                    if (res) {
                        setArcoCausalOperationsFromStore(res.data);
                    }
                });
        }

    };

    const handleClose = () => {
        setDetailId(null);
        props.onHide();
    };

    const showArcoRegistryDetail = (element) => {
        if (!handleValidation()) return;
        setRegistryModalElement(element);
        setShowRegistryModal(!showRegistryModal);

    }
    const closeRegistryModal = (savedElement) => {//Solo aggiornamento
        if (savedElement) {

            let customerToSaveIndex = clients.findIndex(e => e.id === savedElement.id);
            let counterpartToSaveIndex = counterpart.findIndex(e => e.id === savedElement.id);
            if (customerToSaveIndex >= 0) {
                clients[customerToSaveIndex] = savedElement;
                setClients(clients);
            }
            if (counterpartToSaveIndex) {
                counterpart[counterpartToSaveIndex] = savedElement;
                setCounterpart(counterpart);
            }
            getAllArcoOperations();
        }
        setRegistryModalElement(null);
        setShowRegistryModal(!showRegistryModal);
    }

    const showPopUp = (type, id) => {
        if (!handleValidation()) return;
        setDetailId(id);
        setShowModal(!showModal);
        setPopupType(type);
    };



    const saveFromPrintPopUp = (type) => {
        if (!handleValidation()) return;
        actions.saveArcoOperation(formData).then((response) => {
            setCurrentOperation(response);
            setNumero(response.number);
            updateFormData({
                ...formData,
                id: response.id,
                number: response.number
            })
        }, (error) => {
            PopupError(
                {
                    ...props,
                    text: "Errore nel salvataggio"
                }
            );
        })
            .then(() => {
                onSelectedPrintItem(type);
                setShowPrintModal(!showPrintModal);
            });

    };

    const showPrintPopup = (type, index = 0) => {
        setPrintPopupList(type);
        if (!index) {
            saveFromPrintPopUp(type);
        } else {
            const selectedItem = onSelectedPrintItem(type, index)
            if (selectedItem) {
                setPrintIndex(index);
            } else {
                setPrintIndex(0);
                setShowPrintModal(!showPrintModal);
            }
        }
    };


    const showDetail = (arcoContract) => {
        setSelectedContract(arcoContract)
        setShowModalContract(!showModalContract);
    };


    const showPopUpContract = () => {
        setCurrentOperation(formData);
        setSelectedContract(null)
        setShowModalContract(!showModalContract);
    };

    const formatCurrency = ((amount, currencyCode) => {
        let body = {
            style: 'currency',
            currency: currencyCode || "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
        return amount.toLocaleString('de-DE', body);
    });

    const setClientList = (item) => {
        let newClients = [...clients]
        let hasItem = newClients.filter(el => el.id === item.id).length > 0;
        if (!hasItem) {
            newClients = newClients.concat(item);
        }
        setClients(newClients);

        saveAllAndKeepOpen({
            ...formData,
            arcoRegistriesAsCustomers: newClients
        });
    };

    const insertSelectedProfessionists = (selected = []) => {
        let newProf = [...professionists];
        selected.forEach(element => {
            if (!newProf.find(e => e.id === element.id)) {
                newProf.push(element);
            }
        });
        setProfessionists(newProf);

        saveAllAndKeepOpen({
            ...formData,
            arcoProfessionals: newProf
        });
    }


    const setContropartList = (item) => {
        let newCounterpart = [...counterpart]
        let hasItem = newCounterpart.filter(el => el.id === item.id).length > 0;
        if (!hasItem) {
            newCounterpart = newCounterpart.concat(item);
        }
        setCounterpart(newCounterpart);

        saveAllAndKeepOpen({
            ...formData,
            arcoRegistriesAsProfessionals: newCounterpart
        });
    };

    const onSelectedItem = (item) => {
        if (popupType === 'CLIENTS') {
            setClientList(item)
        } else if (popupType === 'COUNTERPARTS') {
            setContropartList(item)
        }

        setShowModal(!showModal);

    }
    const onSelectedPrintItem = (popupPrintType, index = 0) => {
        if (popupPrintType === 'CLIENTS') {
            setSelectedPrintItem(checkedArcoClientsValues[index]);
            return checkedArcoClientsValues[index]
        } else {
            setSelectedPrintItem(checkedArcoCounterpartValues[index]);
            return checkedArcoCounterpartValues[index];
        }
    }



    const getSurnameOrDenomination = (item) => {
        return Constants.PF === item.personType ? (item.lastName + ' ' + item.firstName) : item.denomination
    }

    const onCheckBoxContractClick = (event, arcoContract) => {
        if (event.target.checked) {
            checkArcoContract(arcoContract);
            let element = document.getElementById("contract-" + arcoContract.index);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            uncheckArcoContract(arcoContract);
            let element = document.getElementById("contract-" + arcoContract.index);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    const checkArcoContract = (arcoContract) => {
        setCheckedArcoContractsValues(checkedArcoContractsValues.concat(arcoContract));
    }

    const uncheckArcoContract = (arcoContract) => {
        let newList = [];
        checkedArcoContractsValues.map(current => {
            if (current.id !== arcoContract.id)
                newList.push(current);
        });
        setCheckedArcoContractsValues(newList);
    };

    const deleteArcoContractSubject = () => {
        let newArray = contracts;
        let isError = false;
        checkedArcoContractsValues.map(arcoContract => {
            if (arcoContract?.id) {
                actions.deleteArcoRelationsOfOperation(arcoSubject.id, currentOperation?.id, "CONTRACTS", arcoContract.id).then(
                    () => {
                        props.getArcoOperation();
                    },
                    (error) => {
                        isError = true;
                        PopupError({ ...props, text: error.title })
                    })
            }
            newArray = newArray.filter(elem => arcoContract.index !== elem.index);
            document.getElementById(`checkboxContractDetails${arcoContract.index}`).checked = false;
        });
        if (!isError) {
            setContracts(newArray);
            updateFormData({
                ...formData,
                arcoContracts: newArray
            });
            let rowIds = document.querySelectorAll('[id^="contract-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.remove("bg-cobalt", "bold");
                }
            }

        }
        setCheckedArcoContractsValues([]);
    };


    const onCheckBoxClientClick = (event, arcoClient) => {
        if (event.target.checked) {
            checkArcoClient(arcoClient);
            let element = document.getElementById("client-" + arcoClient.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            uncheckArcoClient(arcoClient);
            let element = document.getElementById("client-" + arcoClient.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    const checkArcoClient = (arcoClient) => {
        setCheckedArcoClientsValues(checkedArcoClientsValues.concat(arcoClient));
    }

    const uncheckArcoClient = (arcoClient) => {
        let newList = [];
        checkedArcoClientsValues.map(current => {
            if (current.id !== arcoClient.id)
                newList.push(current);
        });
        setCheckedArcoClientsValues(newList);
    };

    const deleteArcoClientSubject = () => {
        let newArray = clients;
        let isError = false;
        checkedArcoClientsValues.map(arcoClient => {
            if (!arcoClient?.isNew) {
                actions.deleteArcoRelationsOfOperation(arcoSubject.id, props?.currentOperation?.id, "CLIENTS", arcoClient.id).then(
                    () => {
                        props.getArcoOperation();
                    },
                    (error) => {
                        isError = true;
                        PopupError({ ...props, text: error.title })
                    })

            }
            newArray = newArray.filter(elem => arcoClient.id !== elem.id);
            document.getElementById(getRegistryCheckboxId(arcoClient.id)).checked = false;
        });
        if (!isError) {
            setClients(newArray);
            updateFormData({
                ...formData,
                arcoRegistriesAsCustomers: newArray
            });
        }
        let rowIds = document.querySelectorAll('[id^="client-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
        setCheckedArcoClientsValues([]);
    };


    const onCheckBoxContropartClick = (event, arcoContropart) => {
        if (event.target.checked) {
            checkArcoContropart(arcoContropart);
            let element = document.getElementById("counterpart-" + arcoContropart.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            uncheckArcoContropart(arcoContropart);
            let element = document.getElementById("counterpart-" + arcoContropart.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    const checkArcoContropart = (arcoContropart) => {
        setCheckedArcoCounterpartValues(checkedArcoCounterpartValues.concat(arcoContropart));
    }

    const uncheckArcoContropart = (arcoContropart) => {
        let newList = [];
        checkedArcoCounterpartValues.map(current => {
            if (current.id !== arcoContropart.id)
                newList.push(current);
        });
        setCheckedArcoCounterpartValues(newList);
    };


    const deleteArcoContropartOrProfessionalSubject = (type) => {
        if (type == 'COUNTERPARTS') {
            deleteArcoContropartSubject()
        } else if (type == 'PROFESSIONALS') {
            deleteArcoProfessionistSubject();
        }
    }

    const deleteArcoContropartSubject = () => {
        let newArray = counterpart;
        let isError = false;
        checkedArcoCounterpartValues.map(arcoCounterpart => {
            if (!arcoCounterpart?.isNew) {
                actions.deleteArcoRelationsOfOperation(arcoSubject.id, props?.currentOperation?.id, "COUNTERPARTS", arcoCounterpart.id).then(
                    () => {
                        props.getArcoOperation();
                    },
                    (error) => {
                        isError = true;
                        PopupError({ ...this.props, text: error.title })
                    })

            }
            newArray = newArray.filter(elem => arcoCounterpart.id !== elem.id);
            document.getElementById(getRegistryCheckboxId(arcoCounterpart.id)).checked = false;
        });
        if (!isError) {
            setCounterpart(newArray);
            updateFormData({
                ...formData,
                arcoRegistriesAsProfessionals: newArray
            });
        }
        let rowIds = document.querySelectorAll('[id^="counterpart-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
        setCheckedArcoCounterpartValues([]);
    };

    const onCheckBoxProfessionistClick = (event, arcoProfessionist) => {
        if (event.target.checked) {
            checkArcoProfessionist(arcoProfessionist);
            let element = document.getElementById("arcoProfessionist-" + arcoProfessionist.id);
            if (element)
                element.classList.add("bg-cobalt", "bold");
        } else {
            uncheckArcoProfessionist(arcoProfessionist);
            let element = document.getElementById("arcoProfessionist-" + arcoProfessionist.id);
            if (element)
                element.classList.remove("bg-cobalt", "bold");
        }
    };

    const checkArcoProfessionist = (arcoProfessionist) => {
        setCheckedArcoProfessionistsValues(checkedArcoProfessionistsValues.concat(arcoProfessionist));
    }

    const uncheckArcoProfessionist = (arcoProfessionist) => {
        let newList = [];
        checkedArcoProfessionistsValues.map(current => {
            if (current.id !== arcoProfessionist.id)
                newList.push(current);
        });
        setCheckedArcoProfessionistsValues(newList);
    };

    const deleteArcoProfessionistSubject = () => {
        let newArray = [...professionists];
        let isError = false;
        checkedArcoProfessionistsValues.map(arcoProfessionist => {
            if (!arcoProfessionist?.isNew) {
                actions.deleteArcoRelationsOfOperation(arcoSubject.id, props?.currentOperation?.id, "PROFESSIONALS", arcoProfessionist.id).then(
                    () => {
                        props.getArcoOperation();
                    },
                    (error) => {
                        isError = true;
                        PopupError({ ...this.props, text: error.title })
                    })

            }
            newArray = newArray.filter(elem => arcoProfessionist.id !== elem.id);

            document.getElementById(getRegistryCheckboxId(arcoProfessionist.id)).checked = false;
        });
        if (!isError) {
            setProfessionists(newArray);
            updateFormData({
                ...formData,
                arcoProfessionals: newArray
            });
        }
        let rowIds = document.querySelectorAll('[id^="arcoProfessionist-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
        setCheckedArcoProfessionistsValues([]);
    };

    const getRegistryCheckboxId = (id) => {
        return `registry-checkBox-${id}`;
    }

    const renderRowClients = () => {
        return clients.map((arcoClient) => {
            const idCheckbox = getRegistryCheckboxId(arcoClient.id); //checkboxClientDetails
            return (
                <React.Fragment key={`client-key-${arcoClient.id}`} >
                    <div
                        className={'text-start row-table'}
                        id={`client-${arcoClient.id}`}
                    >
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                className='arcoCheckbox'
                                id={idCheckbox}
                                onClick={e => onCheckBoxClientClick(e, arcoClient)}
                            />
                        </div>
                        <div className='ps-0 col-lg-11 div-td text-truncate' onClick={() => showArcoRegistryDetail(arcoClient)}><strong>&nbsp;&nbsp;{getSurnameOrDenomination(arcoClient).toUpperCase()}</strong> </div>
                    </div>
                </React.Fragment>
            )
        });
    }

    const renderRowCounterpart = () => {
        return counterpart.map((arcoContropart) => {
            const idCheckbox = getRegistryCheckboxId(arcoContropart.id);//checkboxCounterpartDetails
            return (

                <React.Fragment key={`counterpart-key-${arcoContropart.id}`}>
                    <div
                        className={'text-start row-table'}
                        id={`counterpart-${arcoContropart.id}`}
                    >
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                className='arcoCheckbox'
                                id={idCheckbox}
                                onClick={e => onCheckBoxContropartClick(e, arcoContropart)}
                            />
                        </div>
                        <div className='ps-0 col-lg-11 div-td text-truncate' onClick={() => showArcoRegistryDetail(arcoContropart)}><strong>&nbsp;&nbsp;{getSurnameOrDenomination(arcoContropart).toUpperCase()}</strong> </div>
                    </div>
                </React.Fragment>
            )
        });
    }

    const getAllArcoOperations = () => {
        dispatch(actions.getAllArcoOperations(arcoSubject.id, operationSearchObject));
    }

    const getArcoRegistries = (searchObjectParam) => {
        dispatch(actions.getAllArcoRegistriesBySubjectId(arcoSubject.id, searchObjectParam || searchObject));
    }


    const renderRowProfessionists = () => {
        return professionists.map((arcoProfessionist) => {
            const idCheckbox = getRegistryCheckboxId(arcoProfessionist.id);//checkboxArcoProfessionistDetails
            return (

                <React.Fragment  >
                    <div
                        className={'text-start row-table'}
                        id={`arcoProfessionist-${arcoProfessionist.id}`}
                        key={`arcoProfessionist-${arcoProfessionist.id}`}
                    >
                        <div className='col-lg-1 text-center div-td'>
                            <input type="checkbox"
                                className='arcoCheckbox'
                                id={idCheckbox}
                                onClick={e => onCheckBoxProfessionistClick(e, arcoProfessionist)}
                            />
                        </div>
                        <div className='ps-0 pe-0 col-lg-11 div-td text-truncate'><strong>&nbsp;&nbsp;{arcoProfessionist.name}</strong> </div>
                    </div>
                </React.Fragment>
            )
        });
    };

    const addArcoContract = (newContract) => {
        let contractsArr = [...contracts];
        if (newContract?.index === null || newContract?.index === undefined) {
            contractsArr.push(newContract);
        } else {
            contractsArr = contractsArr.map((el, index) => {
                if (index === newContract.index) {
                    return newContract
                }
                return el;
            })
        }
        setContracts(contractsArr)
        saveAllAndKeepOpen({
            ...formData,
            arcoContracts: contractsArr
        });
    };

    const renderRowContracts = () => {
        return contracts.map((arcoContract, index) => {
            if (arcoContract.status < 1) {
                arcoContract.index = index;
                const idCheckbox = `checkboxContractDetails${arcoContract.index}`;
                return (

                    <React.Fragment key={`contract-key-${arcoContract.id}`} >
                        <div
                            className={'text-start row-table'}
                            id={`contract-${arcoContract.id}`}
                        >
                            <div className='col-lg-1 text-center div-td'>
                                <input type="checkbox"
                                    className='arcoCheckbox'
                                    id={idCheckbox}
                                    onClick={e => onCheckBoxContractClick(e, arcoContract)}
                                />
                            </div>
                            <div className='ps-0 pe-0 col-lg-2 div-td' onClick={() => showDetail(arcoContract)}><strong>&nbsp;&nbsp;{formatDateForDisplay(arcoContract.contractDate)}</strong> </div>
                            <div className='ps-0 col-lg-6 div-td text-truncate' onClick={() => showDetail(arcoContract)}><strong>&nbsp;&nbsp;{arcoContract.arcoCausalOperationType.code + "-" + arcoContract.arcoCausalOperationType.description.toUpperCase()}</strong> </div>
                            <div className='col-lg-3 div-td' onClick={() => showDetail(arcoContract)}><p className="text-end fw-bold">{formatCurrency((arcoContract.amount || 0), arcoContract.currency?.isoCode)}</p> </div>
                        </div>
                    </React.Fragment>
                )
            }
            return <></>
        });
    };

    const handlerChangeDataProfilatura = (date) => {
        if (isValid(date)) {
            date.setHours(12, 0, 0, 0);
        }
        setDataProfilatura(date);
        setDataProfilaturaError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            profilingDate: date
        });
    };

    const handlerChangeDescrizione = (e) => {
        setDescrizione(e.target.value.toUpperCase());
        setDescrizioneError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            description: e.target.value.toUpperCase()
        }
        )
    };

    const handlerChangeNatura = (value) => {
        setNatura(value ? value?.toUpperCase() : "");
        setNaturaError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            nature: value ? value?.toUpperCase() : ""
        }
        )
    };

    const handlerChangeScopo = (value) => {

        setScopo(value ? value?.toUpperCase() : "")
        setScopoError({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            purpose: value ? value?.toUpperCase() : ""
        }
        )
    };

    const handlerChangeGoldPrice = (e) => {
        setGoldPrice(e.target.value.trim())
        updateFormData({
            ...formData,
            goldPrice: e.target.value.trim()
        }
        )
    };

    const handlerChangeSilverPrice = (e) => {
        setSilverPrice(e.target.value.trim())
        updateFormData({
            ...formData,
            silverPrice: e.target.value.trim()
        }
        )
    };


    const saveAllAndKeepOpen = (form) => {
        if (!handleValidation()) return;

        return new Promise(() => {
            actions.saveArcoOperation(form).then((response) => {
                updateFormData({
                    ...formData,
                    id: response.id,
                    arcoRegistriesAsCustomers: response.arcoRegistriesAsCustomers,
                    arcoRegistriesAsProfessionals: response.arcoRegistriesAsProfessionals,
                    arcoProfessionals: response.arcoProfessionals,
                    arcoContracts: response.arcoContracts
                });
                setCurrentOperation(response);
                setContracts(response.arcoContracts || []);
                props.getArcoOperation(null)

            }, (error) => {
                PopupError(
                    {
                        ...props,
                        text:
                            "Errore nel salvataggio"
                    }
                );
            });
        });


    }

    /**
     * 
     * @param {*} show determina se continuare a mostrare la schermata dell'operazione
     * @returns 
     */
    const saveAll = (show) => {
        if (!handleValidation()) return;

        if (props.disableInsert) {
            return props.getArcoOperation(show ? formData : undefined);
        }

        actions.saveArcoOperation(formData).then((response) => {
            setCurrentOperation(response);
            props.getArcoOperation(show ? response : undefined);
            if (!show) {
                props.onHide();
            }
        }, (error) => {
            PopupError(
                {
                    text:
                        "Errore nel salvataggio"
                }
            );
        });

    };

    const saveBeforePrintOperation = () => {
        if (!handleValidation()) return;

        if (props.disableInsert) {
            return printOperationPdf(formData);
        }

        return actions.saveArcoOperation(formData).then((response) => {
            props.getArcoOperation(null);
            if (response?.id) {
                printOperationPdf(response);
            }
            setCurrentOperation(response);
            updateFormData(response);
        }, (error) => {
            PopupError(
                {
                    ...props,
                    text:
                        "Errore nel salvataggio"
                }
            );
        });
    }

    const isProfessionals = () => {
        return arcoSubject?.arcoSubjectType?.code === 'PB';
    }

    const handleValidation = () => {
        let isValid = true;
        if (!dataProfilatura) {
            setDataProfilaturaError({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }
        if (!natura) {
            setNaturaError({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }

        if (!descrizione) {
            setDescrizioneError({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }

        if (!scopo) {
            setScopoError({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }

        if (!isValid) {
            PopupError({ text: "Compilare tutti i campi obbligatori" });
        }

        return isValid;
    }

    const printOperationPdf = (savedOperation) => {
        if (savedOperation?.id) {
            setLoading(true);
            const axiosInstance = axiosService.getInstance();
            axiosInstance({
                url: `/api/arco/custom/arco-operations-summary-document/${savedOperation.id}`,
                method: 'GET',
            }).then((response) => {
                if (response) {
                    downloadFile(base64ToArrayBuffer(response.data), savedOperation.description?.substring(0, 30) + ".pdf");
                }

            }, (err) => {
                if (err.errorKey === "arcoOperationCheck") {
                    PopupError({ ...props, text: err.title });
                } else {
                    PopupError({ text: "Errore download documento" });
                }
            }).then(() => {
                setLoading(false);
            });
        }
    }
    const checkSelectedClients = () => {
        if (checkedArcoClientsValues.length >= 1) {
            return false;
        }
        else {
            return true;
        }
    }
    const disablePrintProfessionalOrCounterpart = () => {
        if (isProfessionals()) {
            return true;
        }
        if (checkedArcoProfessionistsValues.length >= 1 || checkedArcoCounterpartValues.length >= 1) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <React.Fragment>

            <div className="row-detail bg-blue pt-4 pe-3 pb-4 ps-3 d-flex row-form big">
                <div>
                    <div className="soggetto-crs">
                        <form>
                            <div className='row'>
                                <div className="col-md-2">
                                    <DateField
                                        label="Data Profilatura"
                                        name={"disabilityDate"}
                                        date={dataProfilatura}
                                        onChange={date => handlerChangeDataProfilatura(date)}
                                        error={dataProfilaturaError}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <BwmInput name='Descrizione'
                                        label='Descrizione Operazione'
                                        className='form-control'
                                        value={descrizione}
                                        onChange={e => handlerChangeDescrizione(e)}
                                        error={descrizioneError}
                                    />
                                </div>
                                <div className='col-md-2' disabled="disabled">
                                    <BwmInput name='Numero'
                                        label='Numero'
                                        type="companyName"
                                        className='form-control'
                                        maxLength="60"
                                        value={numero || ""}
                                        readonly="readonly"
                                        disabled
                                    />
                                </div>
                                <div className='col-md-2'>
                                    <AutoCompileCustom //Autocompile
                                        label="Natura"
                                        className='form-control'
                                        handleInputAutocompile={handlerChangeNatura}
                                        suggestions={ARCO_NATURE_OPTIONS}
                                        filtro={ARCO_NATURE_OPTIONS}
                                        value={natura}
                                        error={naturaError}
                                        filterValues={false}
                                    />
                                    {/* <BwmInput label='Natura'
                                        className='form-control'
                                        maxLength="16"
                                        value={natura}
                                        onChange={e => handlerChangeNatura(e)}
                                        error={naturaError}
                                    /> */}
                                </div>

                                <div className='col-md-2'>

                                    <AutoCompileCustom //Autocompile
                                        label="Scopo"
                                        className='form-control'
                                        handleInputAutocompile={handlerChangeScopo}
                                        suggestions={ARCO_PURPOSE_OPTIONS}
                                        filtro={ARCO_PURPOSE_OPTIONS}
                                        value={scopo}
                                        error={scopoError}
                                        filterValues={false}
                                    />

                                </div>
                            </div>
                            {
                                arcoSubject?.arcoSubjectType?.code === "36" //COMPROORO
                                &&
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <BwmInput name='goldPrice'
                                            label='Prezzo Oro Puro €/g'
                                            className='form-control'
                                            maxLength="100"
                                            value={goldPrice}
                                            onChange={e => { handlerChangeGoldPrice(e) }}
                                        />
                                    </div>
                                    <div className='col-md-2'>

                                        <BwmInput name='silverPrice'
                                            label='Prezzo Argento Puro €/g'
                                            className='form-control'
                                            maxLength="100"
                                            value={silverPrice}
                                            onChange={e => { handlerChangeSilverPrice(e) }}
                                        />
                                    </div>

                                </div>
                            }

                            <div className="form-row">
                                <div className="tab-content relationship col-md-12 col-lg-12">
                                    <Tabs>
                                        <div label="ANAGRAFICHE E CONTRATTI (Ex AUI)" className="col-6" id="anagrafiche">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="row mb-1">
                                                        <div className="col-5 align-items-center d-flex"><span className="mt-2"><b>CLIENTI</b></span></div>
                                                        <div className='col-7 text-end'>
                                                            {addRegistryButton("Aggiungi clienti", () => showPopUp('CLIENTS'), props.disableInsert)}
                                                            {removeRegistryButton("Elimina clienti", deleteArcoClientSubject, props.disableInsert)}
                                                            {printRegistryButton("Stampa documenti", () => showPrintPopup('CLIENTS'), checkSelectedClients() || props.disableInsert)}
                                                        </div>
                                                    </div>
                                                    <div className="panel col-12 arco-new-operation">
                                                        {renderRowClients()}
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="row mb-1">
                                                        <div className="col-5 align-items-center d-flex"><span className="mt-2"><b>{isProfessionals() ? 'PROFESSIONISTI' : 'CONTROPARTI'}</b></span></div>
                                                        <div className='col-7 text-end'>
                                                            {addRegistryButton(`Aggiungi ${isProfessionals() ? 'professionisti' : 'controparti'}`, () => showPopUp(isProfessionals() ? 'PROFESSIONALS' : 'COUNTERPARTS'), props.disableInsert)}
                                                            {removeRegistryButton(`Elimina ${isProfessionals() ? 'professionisti' : 'controparti'}`, () => deleteArcoContropartOrProfessionalSubject(isProfessionals() ? 'PROFESSIONALS' : 'COUNTERPARTS'), props.disableInsert)}
                                                            {printRegistryButton("Stampa documenti", () => showPrintPopup(isProfessionals() ? 'PROFESSIONALS' : 'COUNTERPARTS'), disablePrintProfessionalOrCounterpart() || props.disableInsert)}

                                                        </div>
                                                    </div>
                                                    <div className="panel col-12 arco-new-operation">
                                                        {isProfessionals() ? renderRowProfessionists() : renderRowCounterpart()}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="row mb-1">
                                                        <div className="col-8 align-items-center d-flex"><span className="mt-2"><b>CONTRATTI</b></span></div>
                                                        <div className='col d-flex justify-content-end'>
                                                            {addRegistryButton("Aggiungi contratti", showPopUpContract, props.disableInsert)}
                                                            {removeRegistryButton("Elimina contratti", deleteArcoContractSubject, props.disableInsert)}
                                                        </div>
                                                    </div>
                                                    <div className="panel col-12 arco-new-operation">
                                                        {renderRowContracts()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6" label="RISCHIO OPERAZIONE" id="operation" >
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={tipologiaLista}
                                                            name='b1'
                                                            label='B1 - Tipologia'
                                                            className='form-control'
                                                            value={tipologia !== undefined ? tipologia.id : ''}
                                                            onChange={handlerChangeTipologia}
                                                        />
                                                    </div>
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={modalitaSvolgimentoLista}
                                                            name='b2'
                                                            label='B2 - Modalità di svolgimento'
                                                            className='form-control'
                                                            value={modalitaSvolgimento !== undefined ? modalitaSvolgimento.id : ''}
                                                            onChange={handlerChangeModalitaSvolgimento}
                                                        />
                                                    </div>
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={ammontareLista}
                                                            name='b3'
                                                            label='B3 - Ammontare'
                                                            className='form-control'
                                                            value={ammontare !== undefined ? ammontare.id : ''}
                                                            onChange={handlerChangeAmmontare}
                                                        />
                                                    </div>
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={frequenzaDurataLista}
                                                            name='b4'
                                                            label='B4 - Frequenza e Durata'
                                                            className='form-control'
                                                            value={frequenzaDurata !== undefined ? frequenzaDurata.id : ''}
                                                            onChange={handlerChangeFrequenzaDurata}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={ragionevolezzaLista}
                                                            name='b5'
                                                            label='B5 - Ragionevolezza'
                                                            className='form-control'
                                                            value={ragionevolezza !== undefined ? ragionevolezza.id : ''}
                                                            onChange={handlerChangeRagionevolezza}

                                                        />
                                                    </div>
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={areaGeograficaClienteLista}
                                                            name='b6'
                                                            label='B6 - Area geografica del cliente'
                                                            className='form-control'
                                                            value={areaGeograficaCliente !== undefined ? areaGeograficaCliente.id : ''}
                                                            onChange={handlerChangeAreaGeograficaCliente}

                                                        />
                                                    </div>
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={areaGeograficaControparteLista}
                                                            name='b7'
                                                            label='B7 - Area geografica di controparte'
                                                            className='form-control'
                                                            value={areaGeograficaControparte !== undefined ? areaGeograficaControparte.id : ''}
                                                            onChange={handlerChangeAreaGeograficaControparte}
                                                        />
                                                    </div>
                                                    <div className="col-3 pe-7 ps-7">
                                                        <BwmSelect
                                                            options={personalizzataLista}
                                                            name='b8'
                                                            label='B8 - Personalizzata'
                                                            className='form-control'
                                                            value={personalizzata !== undefined ? personalizzata.id : ''}
                                                            onChange={handlerChangePersonalizzata}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Tabs>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div
                        className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                        {loading && <PageSpinner />}
                        <button className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={handleClose}>
                            <IUndo className={"padding-right-4px svg"} width="20" fill={"#128186"} />&nbsp; ANNULLA
                        </button>

                        <button className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={() => saveAll(true)}>
                            <IClip className={"padding-right-4px svg"} width="20" fill={"#128186"} />&nbsp; ALLEGATI
                        </button>
                        <button className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={() => saveBeforePrintOperation()}>
                            <IExport className={"padding-right-4px svg"} width="20" fill={"#128186"} />&nbsp; STAMPA OPERAZIONE
                        </button>
                        <button className="btn btn-primary btn-new-rel px-5"
                            onClick={() => saveAll(false)}
                            disabled={props.disableInsert}
                        >
                            <ISave className={"padding-right-4px svg"} width="20" fill={"#FFFFFF"} />&nbsp; CONFERMA
                        </button>
                    </div>
                    {showModal && (popupType === 'CLIENTS' || popupType === 'COUNTERPARTS') &&
                        <ArcoRegistriesList
                            {...props}
                            getArcoRegistries={getArcoRegistries}
                            arcoRegistries={props.arcoRegistries}
                            showModalRegistriesList={showModal}
                            detailId={detailId}
                            depth={2}
                            onHide={showPopUp}
                            onSelectedItem={onSelectedItem}
                            disableInsert={props.disableInsert}

                        />
                    }
                    {showRegistryModal &&
                        <ArcoRegistryModal
                            {...props}
                            getArcoRegistries={getArcoRegistries}
                            depth={-999}
                            selectedItem={registryModalElement}
                            onHide={closeRegistryModal}
                            showModalCheck={showRegistryModal}
                            disableInsert={props.disableInsert}
                        />
                    }
                    {showModal && popupType === 'PROFESSIONALS' &&
                        <ArcoRegistrationProfessionalsModal
                            show={showModal}
                            onConfirm={(selectedItems) => insertSelectedProfessionists(selectedItems)}
                            professionistsData={professionistsData}
                            subject={arcoSubject}
                            onHide={hideProfessionalsModal}
                            disableInsert={props.disableInsert}
                        />
                    }
                    {showModalContract && <div>
                        <ArcoRegistrationContractModal
                            arcoCausalOperations={arcoCausalOperationsFromStore}
                            show={showModalContract}
                            onHide={showPopUpContract}
                            currencies={props.currencies}
                            addArcoContract={addArcoContract}
                            selectedContract={selectedContract}
                            selectedOperation={currentOperation}
                        />
                    </div>}

                    {showPrintModal && <div>
                        <ArcoRegistryPrintModal
                            {...props}
                            key={'printModal-' + selectedPrintItem?.id}
                            show={showPrintModal}
                            onHide={() => showPrintPopup(printPopupList, printIndex + 1)}
                            selectedPrintItem={selectedPrintItem}
                            currentOperation={currentOperation}
                        />
                    </div>}
                </div>
            </div >

        </React.Fragment >
    )
}


function addRegistryButton(dataTip, onClick, disabled = false) {
    return (
        <button type="button" disabled={disabled} className="btn btn-outline-info btn-empty  text-center  me-1"
            data-tip={dataTip} onClick={onClick}><IPlus className={"svg"} width="17" fill={disabled ? "#FFFFFF" : "#128186"} /></button>
    );
}

function removeRegistryButton(dataTip, onClick, disabled) {
    return (
        <button type="button" className={`btn btn-outline-info btn-empty  text-center  me-1 ${disabled && 'disabled'}`}
            disabled={disabled} data-tip={dataTip} onClick={onClick}>
            <IMinus className={" svg"} width="17" fill={` ${disabled ? '#FFFFFF' : '#128186'}`} /></button>
    );
}

function printRegistryButton(dataTip, onClick, disabled) {
    return (
        <button type="button" className={`btn btn-outline-info btn-empty  text-center  ${disabled && 'disabled'}`}
            disabled={disabled} data-tip={dataTip} onClick={onClick}>
            <span className="mb-0"><b>
                <IPrint className={"padding-right-4px svg"} width="20" fill={` ${disabled ? '#FFFFFF' : '#128186'}`} /></b>&nbsp;Documenti</span></button>
    );
}
