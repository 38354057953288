import { Row } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner'

export default function RowSpinner({ size = 60 }) {

    return (
        <Row className='justify-content-center' >
            <div style={{ width: size, height: size }}>
                <SimpleSpinner size={size} />

            </div>
        </Row>
    );

}

export function SimpleSpinner({ size }) {

    return (
        <RotatingLines
            strokeColor="#128186"
            height={size || 60}
            width={size || 60}
        />
    );

}

