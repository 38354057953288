import React from 'react';
import Modal from "react-bootstrap/Modal";
import ComureiForm from "./NewComureiForm";
import {IPlus, ISave, ITextFile, IUndo} from '../../../../styles/icons';
import {getComureiSubjectDetail} from "../../../../actions";
import Loader from "../../../Loader";

export class ComureiSubjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.hideButton,
            subject: undefined
        }
    }

    componentDidMount() {
        if (this.props.id) {
            getComureiSubjectDetail(this.props.id).then((response) => {
                this.setState({ subject: response.data })
            })
        }
    }

    closeModal = () =>{
        this.setState({showModal: false});
        if (this.props.closeModal) this.props.closeModal();
    };

    openModal = async () => {
        this.setState({showModal: true});
    };

    render() {
        return (
            <React.Fragment>
                {!this.props.hideButton && <button type="button" className="btn btn-primary btn-new-rel px-3"
                                                   onClick={this.openModal}><IPlus className={"padding-right-4px"}
                                                                                   width="14" fill={'#FFFFFF'}/>&nbsp;
                    <span>Nuovo soggetto</span>
                </button>}
                <Modal
                    dialogClassName="comurei-registry-modal"
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>{this.props.id ? (this.state.subject ? `Modifica Soggetto ${this.state.subject.companyName}` : 'Caricamento...') : 'Nuovo soggetto'}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-3">
                        {((this.props.id && this.state.subject) || (!this.props.id)) &&
                            <ComureiForm
                                locations={this.props.locations}
                                subjectItem={this.state.subject}
                                subjectTypes={this.props.subjectTypes}
                                closeModal={this.closeModal}
                                getSubjects={this.props.getSubjects}
                        />}
                        {(this.props.id && !this.state.subject) && <Loader />}
                    </Modal.Body>
                </Modal>


            </React.Fragment>
        )
    }
}
