import range from "underscore/modules/range";
import Skeleton from "react-loading-skeleton";

export const TableSkeleton = ({ colCount, rowsCount }) => {
    const rowColumns = range(colCount).map((i) => {
        return (
            <td key={i} className="star-table-loading">
                <Skeleton />
            </td>
        );
    });

    const rows = range(rowsCount).map((i) => {
        return <tr key={i}>{rowColumns}</tr>;
    });

    return (
        rows
    );
};
