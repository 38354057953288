import React, { useEffect, useState } from 'react';
import * as actions from "../../../../actions";
import * as Constants from "../../../../config/Constants";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/autocomplete.css";
import Modal from "react-bootstrap/Modal";
import { BwmInput } from "../../../shared/form/BwmInput";
import Tabs from "../../application-cora/relationships/Tabs";
import { ArcoRegistrySignatories } from "./ArcoRegistrySignatories";
import { ArcoRegistryHolders } from "./ArcoRegistryHolders";
import CodiceFiscale from "codice-fiscale-js";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import { ProvinceField } from "../../application-cora/relationships/ProvinceField";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import { PopupError } from "../../../shared/PopupError";
import { DateField } from "../../../shared/form/DateField";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import { useSelector, useDispatch } from 'react-redux';
import '../../../../styles/arcoTablesModal.css';
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { isEmpty } from '../../../shared/Utility';


registerLocale("it", it);

export function ArcoRegistryModal(props) {
    const DEFAULT_AUTHORITY = 'COMUNE';
    const arcoDocumentTypes = useSelector(state => state.arcoRelationshipDocuments.data);
    const domains = useSelector(state => state.arcoDomains.domains);
    const countries = useSelector(state => state.countries.data);
    const arcoSubject = useSelector(state => state.arcoSubject.data);
    const customer = useSelector(state => state.auth.customer);
    const locations = useSelector(state => state.locations.customLocations);
    const locationsBirth = useSelector(state => state.locations.customLocations);
    const tabRef = React.createRef();
    const [showRegistryModal, setshowRegistryModal] = useState(false);
    const [showDetailItem, setshowDetailItem] = useState(null);
    const [depth, setDepth] = useState(props.depth);
    const [header, setHeader] = useState("");
    const [showDetailType, setShowDetailType] = useState(null)
    const [loading, setLoading] = useState(false)

    //assegna valori di default domini di rischio
    const getDescription = (type) => {
        let defValue = null;
        let filteredList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === type) {
                if (arcoDomain.def) {
                    defValue = arcoDomain;
                }
                filteredList.push(arcoDomain);
            }
        });
        return defValue ? defValue.description : filteredList[0].description;
    }
    const getValue = (type) => {
        let defValue = null;
        let filteredList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === type) {
                if (arcoDomain.def) {
                    defValue = arcoDomain;
                }
                filteredList.push(arcoDomain);
            }
        });
        return defValue ? defValue.value : filteredList[0].value;
    }

    const convertToNewDocumentList = () => {
        let documentsWithDescription = [];
        arcoDocumentTypes.map((documento, index) => {
            documentsWithDescription.push({
                id: documento.id,
                description: documento.documentType
            })
        })
        return documentsWithDescription;
    };
    const documentTypesDropdownList = convertToNewDocumentList();

    const getInitialDocument = () => {
        return {
            id: null,
            number: '',
            arcoDocumentType: arcoDocumentTypes[0],
            beginDate: null,
            endDate: null,
            authority: DEFAULT_AUTHORITY,
            politicallyExposed: false,
            setSourceOfFunds: '',
            sourceOfFundsDescription: ''
        }
    }

    const getSelectedRegistry = () => {
        return props?.selectedItem ? props?.selectedItem
            : {
                personType: Constants.PF,
                fiscalCode: "",
                denomination: "",
                firstName: "",
                lastName: "",
                birthDate: null,
                locationLegal: "",
                location: "",
                birthLocation: "",
                provinceLegal: "",
                province: "",
                birthProvince: "",
                postalCode: "",
                address: "",
                nation: "ITALIA (REPUBBLICA ITALIANA)",
                gender: Constants.genderList[0].id,
                a1: getDescription('A1'),
                a2: getDescription('A2'),
                a3: getDescription('A3'),
                a4: getDescription('A4'),
                a5: getDescription('A5'),
                a1Value: getValue('A1'),
                a2Value: getValue('A2'),
                a3Value: getValue('A3'),
                a4Value: getValue('A4'),
                a5Value: getValue('A5'),
                arcoDocument: null,
                arcoRegistryHolders: [],
                arcoRegistrySignatories: [],
                arcoOperation: null,
                arcoSubject: arcoSubject,
                customer: customer

            };
    }
    const [formData, updateFormData] = useState(getSelectedRegistry());

    useEffect(() => {
        if (!formData.customer || !formData.arcoSubject) {
            updateFormData({
                ...formData,
                customer: customer,
                arcoSubject: arcoSubject
            });
        }
    });

    const naturaGiuridicaList = () => {
        let naturaGiuridicaList = [];

        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A1") {
                naturaGiuridicaList.push(arcoDomain);
            }
        });
        return naturaGiuridicaList;
    };
    const [naturaGiuridicaLista, setNaturaGiuridicaLista] = useState(naturaGiuridicaList());
    const naturaGiuridicaDef = () => {
        let naturaGiuridica;
        naturaGiuridicaLista.map((arcoDomain, index) => {
            if (formData !== undefined && formData.a1 !== '' && formData.a1 === arcoDomain.description) {
                naturaGiuridica = arcoDomain;
            } else {
                if (arcoDomain.type === "A1" && arcoDomain.def) {
                    naturaGiuridica = arcoDomain;
                }
            }
        });
        if (naturaGiuridica === undefined || naturaGiuridica === null || naturaGiuridica === "") {
            naturaGiuridica = naturaGiuridicaLista[0];
        }
        return naturaGiuridica;
    };
    const [naturaGiuridica, setNaturaGiuridica] = useState(naturaGiuridicaDef());

    const attivitaPrevalenteList = () => {
        let attivitaPrevalenteList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A2") {
                attivitaPrevalenteList.push(arcoDomain)
            }
        });
        return attivitaPrevalenteList;
    };
    const [attivitaPrevalenteLista, setAttivitaPrevalenteLista] = useState(attivitaPrevalenteList());
    const attivitaPrevalenteDef = () => {
        let attivitaPrevalente;
        attivitaPrevalenteLista.map((arcoDomain, index) => {
            if (formData !== undefined && formData.a2 !== '' && formData.a2 === arcoDomain.description) {
                attivitaPrevalente = arcoDomain;
            } else {
                if (arcoDomain.type === "A2" && arcoDomain.def) {
                    attivitaPrevalente = arcoDomain;
                }
                if (attivitaPrevalente === undefined || attivitaPrevalente === null || attivitaPrevalente === "") {
                    attivitaPrevalente = attivitaPrevalenteLista[0];
                }
            }
        });
        return attivitaPrevalente;
    };
    const [attivitaPrevalente, setAttivitaPrevalente] = useState(attivitaPrevalenteDef());

    const comportamentoList = () => {
        let comportamentoList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A3") {
                comportamentoList.push(arcoDomain)
            }

        });
        return comportamentoList;
    };
    const [comportamentoLista, setComportamentoLista] = useState(comportamentoList());
    const comportamentoDef = () => {
        let comportamento;
        comportamentoLista.map((arcoDomain, index) => {
            if (formData !== undefined && formData.a3 !== '' && formData.a3 === arcoDomain.description) {
                comportamento = arcoDomain;
            } else {
                if (arcoDomain.type === "A3" && arcoDomain.def) {
                    comportamento = arcoDomain;
                }
                if (comportamento === undefined || comportamento === null || comportamento === "") {
                    comportamento = comportamentoLista[0];
                }
            }
        });
        return comportamento;
    };
    const [comportamento, setComportamento] = useState(comportamentoDef());

    const areaGeograficaList = () => {
        let areaGeograficaList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A4") {
                areaGeograficaList.push(arcoDomain)
            }

        });
        return areaGeograficaList;
    };
    const [areaGeograficaLista, setAreaGeograficaLista] = useState(areaGeograficaList());
    const areaGeograficaDef = () => {
        let areaGeografica;
        areaGeograficaLista.map((arcoDomain, index) => {
            if (formData !== undefined && formData.a4 !== '' && formData.a4 === arcoDomain.description) {
                areaGeografica = arcoDomain;
            } else {
                if (arcoDomain.type === "A4" && arcoDomain.def) {
                    areaGeografica = arcoDomain
                }
                if (areaGeografica === undefined || areaGeografica === null || areaGeografica === "") {
                    areaGeografica = areaGeograficaLista[0];
                }
            }
        });
        return areaGeografica;
    };
    const [areaGeografica, setAreaGeografica] = useState(areaGeograficaDef());

    const personalizzataList = () => {
        let personalizzataList = [];
        domains.map((arcoDomain, index) => {
            if (arcoDomain.type === "A5") {
                personalizzataList.push(arcoDomain)
            }
        });
        return personalizzataList;
    };
    const [personalizzataLista, setPersonalizzataLista] = useState(personalizzataList());
    const personalizzataDef = () => {
        let personalizzata;
        personalizzataLista.map((arcoDomain, index) => {
            if (formData !== undefined && formData.a5 !== '' && formData.a5 === arcoDomain.description) {
                personalizzata = arcoDomain;
            } else {
                if (arcoDomain.type === "A5" && arcoDomain.def) {
                    personalizzata = arcoDomain
                }
            }
        });
        if (personalizzata === undefined || personalizzata === null || personalizzata === "") {
            personalizzata = personalizzataLista[0];
        }
        return personalizzata;
    };
    const [personalizzata, setPersonalizzata] = useState(personalizzataDef());
    const showModalCheck = props.showModalCheck;

    const [personType, setPersonType] = useState(formData.personType);
    const [fiscalCode, setFiscalCode] = useState(formData?.fiscalCode);
    const [nation, setNation] = useState(formData?.nation);

    const [location, setLocation] = useState(formData?.location);
    const [province, setProvince] = useState(formData?.province);
    const [birthLocation, setBirthLocation] = useState(formData?.birthLocation);
    const [birthProvince, setBirthProvince] = useState(formData?.birthProvince);

    const [denomination, setDenomination] = useState(formData?.denomination);
    const [lastName, setLastName] = useState(formData?.lastName);
    const [firstName, setFirstName] = useState(formData?.firstName);
    const [gender, setGender] = useState(formData?.gender || (personType === Constants.PF ? Constants.genderList[0].id : null));
    const [birthDate, setBirthDate] = useState(formData?.birthDate ? new Date(formData.birthDate) : null);
    const [cap, setCap] = useState(formData?.postalCode);
    const [localita, setLocalita] = useState(formData?.address);

    const [arcoDocument, setArcoDocument] = useState(formData?.arcoDocument);
    const [sourceOfFunds, setSourceOfFunds] = useState(arcoDocument?.sourceOfFunds || '');
    const [sourceOfFundsDescription, setSourceOfFundsDescription] = useState(arcoDocument?.sourceOfFundsDescription || '');
    const [number, setNumber] = useState(arcoDocument?.number || '');
    const [beginDate, setBeginDate] = useState(arcoDocument?.beginDate ? new Date(arcoDocument.beginDate) : null);
    const [endDate, setEndDate] = useState(arcoDocument?.endDate ? new Date(arcoDocument.endDate) : null);
    const [politicallyExposed, setPoliticallyExposed] = useState(arcoDocument?.politicallyExposed || false);
    const [authority, setAuthority] = useState(arcoDocument?.authority || DEFAULT_AUTHORITY);
    const [arcoDocumentType, setDocumentType] = useState(arcoDocument?.arcoDocumentType || arcoDocumentTypes[0]);

    const [errorFiscalCode, setErrorFiscalCode] = useState({ isValid: true, msg: "" });
    const [errorDenomination, setErrorDenomination] = useState({ isValid: true, msg: "" });
    const [errorLocation, setErrorLocation] = useState({ isValid: true, msg: "" });
    const [errorProvince, setErrorProvince] = useState({ isValid: true, msg: "" });
    const [errorBirthLocation, setErrorBirthLocation] = useState({ isValid: true, msg: "" });
    const [errorBirthProvince, setErrorBirthProvince] = useState({ isValid: true, msg: "" });
    const [errorLastName, setErrorLastName] = useState({ isValid: true, msg: "" });
    const [errorFirstName, setErrorFirstName] = useState({ isValid: true, msg: "" });
    const [errorBirthDate, setErrorBirthDate] = useState({ isValid: true, msg: "" });
    const [errorNation, setErrorNation] = useState({ isValid: true, msg: "" });
    const [errorGender, setErrorGender] = useState({ isValid: true, msg: "" });
    const [errorSourceOfFunds, setErrorSourceOfFunds] = useState({ isValid: true, msg: "" });
    const [errorNumber, setErrorNumber] = useState({ isValid: true, msg: "" });
    const [errorSourceOfFundsDescription, setErrorSourceOfFundsDescription] = useState({ isValid: true, msg: "" });
    const [errorBeginDate, setErrorBeginDate] = useState({ isValid: true, msg: "" });
    const [errorEndDate, setErrorEndDate] = useState({ isValid: true, msg: "" });
    const [errorPoliticallyExposed, setErrorPoliticallyExposed] = useState({ isValid: true, msg: "" });
    const [errorAuthority, setErrorAuthority] = useState({ isValid: true, msg: "" });
    const [errorCap, setErrorCap] = useState({ isValid: true, msg: "" });
    const [errorLocalita, setErrorLocalita] = useState({ isValid: true, msg: "" });

    useEffect(() => {
        if (!formData.arcoDocument) {
            updateFormData({
                ...formData,
                arcoDocument: (personType === Constants.PF ? getInitialDocument() : null)
            });
        }
    }, []);


    const handlePersonTypeClick = (e) => {
        setPersonType(e.target.value);
        updateFormData({
            ...formData,
            personType: e.target.value,
            arcoDocument: (personType === Constants.PF ? getInitialDocument() : null)
        });
    }

    const handlerChangeFiscalCode = (e) => {
        setFiscalCode(e.target.value.toUpperCase());
        setErrorFiscalCode({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            fiscalCode: e.target.value.toUpperCase()
        });
    };

    const handleInputAutocompileResidence = (newLocation) => {
        setLocation(newLocation.toUpperCase());
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setLocation(loc.location || '');
                    setProvince( loc.province || '');
                    setCap( loc.postalCode || '');
                    setErrorProvince({ isValid: true, msg: "" });
                    setErrorLocation({ isValid: true, msg: "" });
                    setErrorCap({ isValid: true, msg: "" });
                    updateFormData({
                        ...formData,
                        location: newLocation?.toUpperCase() || '',
                        province:  loc.province || '',
                        postalCode:  loc.postalCode || ''
                    });
                } else {
                    updateFormData({
                        ...formData,
                        location: newLocation?.toUpperCase()
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };

    const handleInputAutocompileBirth = (newLocation) => {
        setBirthLocation(newLocation.toUpperCase());
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    setBirthLocation(loc.location);
                    setBirthProvince(loc.province || '');
                    setErrorBirthProvince({ isValid: true, msg: "" });
                    setErrorBirthLocation({ isValid: true, msg: "" });
                    setErrorCap({ isValid: true, msg: "" });
                    updateFormData({
                        ...formData,
                        birthLocation: newLocation?.toUpperCase() || '',
                        birthProvince: loc.province || '',
                    });
                } else {
                    updateFormData({
                        ...formData,
                        birthLocation: newLocation.toUpperCase()
                    });
                }
            },
            (errors) => {
                console.log(errors);
            })
    };

    /**
     * 
     * @param {*} 'oggetto con dati di anagrafica'
     */
    const setFields = (reg) => {
        setPersonType(reg.personType);
        if (reg.personType === Constants.PF) {
            setLastName(reg.lastName);
            setFirstName(reg.firstName);
            setBirthDate(new Date(reg.birthDate));
            setBirthLocation(reg.birthLocation);
            setBirthProvince(reg.birthProvince);
            setGender(reg.gender);
            const document = reg.arcoDocument;
            setDocumentType(document.arcoDocumentType);
            setAuthority(document.authority);
            setNumber(document.number);
            setBeginDate(new Date(document.beginDate));
            setEndDate(new Date(document.endDate));
            setSourceOfFunds(document.sourceOfFunds);
            setSourceOfFundsDescription(document.sourceOfFundsDescription);
            setPoliticallyExposed(document.politicallyExposed);
        } else {
            setDenomination(reg.denomination);
        }
        setLocalita(reg.address);
        setLocation(reg.location)
        setProvince(reg.province);
        setCap(reg.postalCode);
        setNaturaGiuridica(findArcoDomainForListAndDescription(naturaGiuridicaLista, reg.a1));
        setAttivitaPrevalente(findArcoDomainForListAndDescription(attivitaPrevalenteLista, reg.a2))
        setComportamento(findArcoDomainForListAndDescription(comportamentoLista, reg.a3));
        setAreaGeografica(findArcoDomainForListAndDescription(areaGeograficaLista, reg.a4));
        setPersonalizzata(findArcoDomainForListAndDescription(personalizzataLista, reg.a5));
    }

    /**
     * 
     * @param {*} list 
     * @param {string} description 
     * 
     * @returns arcoDomain
     */
    const findArcoDomainForListAndDescription = (list, description) => {
        if (list && description) {
            return list.find((arcoDomain) => {
                if (description === arcoDomain.description) {
                    return arcoDomain;
                }
            })
        }
    }

    const handleKeyPress = val => {

        if (val && !formData.id) {
            actions.getArcoRegistryByFiscalCode(arcoSubject.id, val).then(
                //trova l'anagrafica a BE se già presente negli archivi dell'utente.
                //se presente, setta i campi del form
                //se proviene da soggetto diverso, pulisce l'id e riassegna il soggetto, anche per eventuali firmatari e controparti
                (registries) => {
                    if (registries && registries.length > 0) {
                        let reg = registries[0];
                        setFields(reg);
                        reg = cleanClonedRegistry(reg);
                        updateFormData(Object.assign(reg));
                    } else {
                        newCodiceFiscale(val);
                    }

                },
                (errors) => {
                    console.log(errors);
                    newCodiceFiscale(val);
                })
        }
    };

    const cleanClonedRegistry =(reg) =>{
        if(arcoSubject.id !== reg.arcoSubject.id){//l'anagrafica proviene da un soggetto diverso; azzero l'id anagrafica e documento e riassegno il soggetto
            reg.arcoSubject = arcoSubject;
            reg.id = undefined;
            if(reg.arcoDocument?.id){
                reg.arcoDocument.id = undefined;
            }
            if(reg.arcoRegistryHolders){
                reg.arcoRegistryHolders.map(el => cleanClonedRegistry(el));
            }
            if(reg.arcoRegistrySignatories){
                reg.arcoRegistrySignatories.map(el => cleanClonedRegistry(el));
            }
        }
        return reg
    }

    const newCodiceFiscale = (val) => {

        if (personType === Constants.PF) {
            try {
                const cf = new CodiceFiscale(val).toJSON();
                if (cf) {
                    const formatLoc = cf?.birthplace.toUpperCase();
                    setBirthDate(birthDate || new Date(cf?.birthday) || null);
                    setGender(cf?.gender === "M" ? 'MALE' : 'FEMALE');
                    setBirthLocation(birthLocation || formatLoc.toUpperCase() || '');
                    setBirthProvince(birthProvince || cf?.birthplaceProvincia || '');

                    updateFormData({
                        ...formData,
                        birthLocation: birthLocation || formatLoc.toUpperCase() || '',
                        birthProvince: birthProvince || cf?.birthplaceProvincia.toUpperCase() || '',
                        birthDate: birthDate || new Date(cf?.birthday) || null,
                        gender: cf?.gender === "M" ? 'MALE' : 'FEMALE',

                    });
                }
            } catch (e) {
                console.log("codice fiscale errato");
                setErrorFiscalCode({ isValid: false, msg: 'Codice fiscale non valido' })
            }
        }
    }

    const handleProvinceChange = (e) => {
        setProvince(e.target.value.toUpperCase());
        setErrorProvince({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            province: e.target.value.toUpperCase()
        });
    };

    const handleBirthProvinceChange = (e) => {
        setBirthProvince(e.target.value.toUpperCase());
        setErrorBirthProvince({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            birthProvince: e.target.value.toUpperCase()
        });
    };

    const handlerChangeBirthDate = (date) => {
        setBirthDate(date);

        setErrorBirthDate({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            birthDate: date
        });
    };

    const handlerChangeSurname = (e) => {
        setLastName(e.target.value.toUpperCase());
        setErrorLastName({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            lastName: e.target.value.toUpperCase()
        });
    };

    const handlerChangeFirstName = (e) => {
        setFirstName(e.target.value.toUpperCase());
        setErrorFirstName({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            firstName: e.target.value.toUpperCase()
        });
    };

    const handlerNation = (e) => {
        const countryId = e.target[e.target.selectedIndex].value;
        setNation(countryId);
        setErrorNation({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            nation: countryId
        });
    };

    const handlerChangeCap = (e) => {
        setCap(e.target.value);
        setErrorCap({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            postalCode: e.target.value
        });
    };

    const handlerChangeLocalita = (e) => {
        setLocalita(e.target.value.toUpperCase());
        setErrorLocalita({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            address: e.target.value.toUpperCase()
        });
    };



    const getCountryList = () => {
        let countriesWithDescription = [];
        countries.map((country, index) => {
            countriesWithDescription.push({
                name: country.code,
                description: country.description
            })
        })
        return countriesWithDescription;
    };


    const handlerChangeGender = (e) => {
        setGender(e.target.value);
        updateFormData({
            ...formData,
            gender: e.target.value
        });
    };

    const handlerChangeDenomination = (e) => {
        setDenomination(e.target.value.toUpperCase());
        setErrorDenomination({ isValid: true, msg: "" });
        updateFormData({
            ...formData,
            denomination: e.target.value.toUpperCase()
        });
    };

    const handlerChangeNaturaGiuridica = (e) => {
        const naturaGiuridicaId = e.target[e.target.selectedIndex].label;
        let naturaGiuridicaValue;
        naturaGiuridicaLista.map((arcoDomain, index) => {
            if (arcoDomain.description === naturaGiuridicaId) {
                naturaGiuridicaValue = arcoDomain
            }
        });
        setNaturaGiuridica(naturaGiuridicaId);
        updateFormData({
            ...formData,
            a1: naturaGiuridicaId,
            a1Value: naturaGiuridicaValue.value
        });

    };

    const handlerChangeAttivitaPrevalente = (e) => {
        const attivitaPrevalenteId = e.target[e.target.selectedIndex].label;
        let attivitaPrevalenteValue;
        attivitaPrevalenteLista.map((arcoDomain, index) => {
            if (arcoDomain.description === attivitaPrevalenteId) {
                attivitaPrevalenteValue = arcoDomain
            }
        });
        setAttivitaPrevalente(attivitaPrevalenteId);
        updateFormData({
            ...formData,
            a2: attivitaPrevalenteId,
            a2Value: attivitaPrevalenteValue.value
        });

    };

    const handlerChangeComportamento = (e) => {
        const comportamentoId = e.target[e.target.selectedIndex].label;
        let comportamentoValue;
        comportamentoLista.map((arcoDomain, index) => {
            if (arcoDomain.description === comportamentoId) {
                comportamentoValue = arcoDomain
            }
        });
        setComportamento(comportamentoId);
        updateFormData({
            ...formData,
            a3: comportamentoId,
            a3Value: comportamentoValue.value
        });

    };

    const handlerChangeAreaGeografica = (e) => {
        const areaGeograficaId = e.target[e.target.selectedIndex].label;
        let areaGeograficaValue;
        areaGeograficaLista.map((arcoDomain, index) => {
            if (arcoDomain.description === areaGeograficaId) {
                areaGeograficaValue = arcoDomain
            }
        });
        setAreaGeografica(areaGeograficaId);
        updateFormData({
            ...formData,
            a4: areaGeograficaId,
            a4Value: areaGeograficaValue.value
        });

    };

    const handlerChangePersonalizzata = (e) => {
        const personalizzataId = e.target[e.target.selectedIndex].label;
        let personalizzataValue;
        personalizzataLista.map((arcoDomain, index) => {
            if (arcoDomain.description === personalizzataId) {
                personalizzataValue = arcoDomain
            }
        });
        setPersonalizzata(personalizzataId);
        updateFormData({
            ...formData,
            a5: personalizzataId,
            a5Value: personalizzataValue.value
        });

    };

    //DOCUMENTO
    const handlerChangeFunds = (e) => {
        setSourceOfFunds(e.target.value.toUpperCase());
        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                sourceOfFunds: e.target.value.toUpperCase()
            }
        });

        setErrorSourceOfFunds({ isValid: true, msg: "" });
    };

    const handlerChangeFundsDetail = (e) => {
        setSourceOfFundsDescription(e.target.value.toUpperCase());

        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                sourceOfFundsDescription: e.target.value.toUpperCase()
            }
        });
        setErrorSourceOfFundsDescription({ isValid: true, msg: "" });
    };


    const addSignatoryOrHolder = (type, signatoryOrHolder) => {
        let newarray = [];
        if (type === Constants.HOLDER) {
            newarray = formData.arcoRegistryHolders || [];
            newarray.push(signatoryOrHolder);
            updateFormData({
                ...formData,
                arcoRegistryHolders: newarray
            });
        } else {
            newarray = formData.arcoRegistrySignatories || [];
            newarray.push(signatoryOrHolder);
            updateFormData({
                ...formData,
                arcoRegistrySignatories: newarray
            });
        }
    }

    const removeSignatoryOrHolder = (type, ids) => {
        let newarray = [];
        if (type === Constants.HOLDER) {
            newarray = formData.arcoRegistryHolders.filter(item => ids.indexOf(item.id) < 0);
            updateFormData({
                ...formData,
                arcoRegistryHolders: newarray
            });
        } else {
            newarray = formData.arcoRegistrySignatories.filter(item => ids.indexOf(item.id) < 0);
            updateFormData({
                ...formData,
                arcoRegistrySignatories: newarray
            });
        }
    }

    const handlerChangeNumber = (e) => {
        setNumber(e.target.value.toUpperCase());

        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                number: e.target.value.toUpperCase()
            }
        });
        setErrorNumber({ isValid: true, msg: "" });
    };

    const calculateEndDate = date => {
        let calculatedEndDate = null;
        let plusYears = 10;
        date = new Date(date);
        let birthDateFormData = new Date(formData.birthDate);
        if (formData.birthDate && date) {
            calculatedEndDate = new Date(formData.birthDate);
            if ((date.getMonth() > birthDateFormData.getMonth()) || (date.getMonth() == birthDateFormData.getMonth() && date.getDate() > birthDateFormData.getDate())) {
                plusYears = 11;
            }
            calculatedEndDate.setFullYear(date.getFullYear() + plusYears);
            setEndDate(calculatedEndDate);
        } else {
            calculatedEndDate = formData.arcoDocument.endDate;
        }

        return calculatedEndDate;
    }

    const handlerChangeReleaseDate = date => {
        setBeginDate(date);
        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                beginDate: date,
                endDate: calculateEndDate(date)
            }
        });
        setErrorBeginDate({ isValid: true, msg: "" });
        setErrorEndDate({ isValid: true, msg: "" });
    };

    const handlerChangeExpirationDate = date => {
        setEndDate(date);
        if (!date) {
            date = calculateEndDate(formData?.arcoDocument?.beginDate);
        }
        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                endDate: date
            }
        });
        setErrorEndDate({ isValid: true, msg: "" });
    };

    const handlerChangeAuthority = (e) => {
        setAuthority(e.target.value);
        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                authority: e.target.value
            }
        })

        setErrorAuthority({ isValid: true, msg: "" });
    };


    const handlerChangeAbility = (e) => {
        setPoliticallyExposed(e.target.checked);

        updateFormData({
            ...formData,
            arcoDocument: {
                ...formData.arcoDocument,
                politicallyExposed: e.target.checked
            }
        });
        setErrorPoliticallyExposed({ isValid: true, msg: "" });
    };

    const handlerDocumentType = (e) => {
        const documentTypeObj = arcoDocumentTypes[e.target[e.target.selectedIndex].value - 1];
        setDocumentType(documentTypeObj);

        let document = formData.arcoDocument;
        document.arcoDocumentType = documentTypeObj;

        if (documentTypeObj.id === 1) {
            setAuthority(authority || "COMUNE");
            document.authority = authority || "COMUNE"
        }
        updateFormData({
            ...formData,
            arcoDocument: document
        });


    };

    const handleRegistryValidation = () => {
        let isValid = true;
        let isValidDocument = true;
        let tab = null;

        if (nation === "ITALIA (REPUBBLICA ITALIANA)") {
            if (!fiscalCode && (birthProvince !== 'EE')) {
                setErrorFiscalCode({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
        }
        if (!location) {
            setErrorLocation({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }

        if (!province) {
            setErrorProvince({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }
        if (!nation) {
            setErrorNation({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }

        if (personType === Constants.PNF) {
            if (!denomination) {
                setErrorDenomination({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }

        } else if (personType === Constants.PF) {

            if (!firstName) {
                setErrorFirstName({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!lastName) {
                setErrorLastName({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!birthDate) {
                setErrorBirthDate({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!gender) {
                setErrorGender({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!cap) {
                setErrorCap({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!localita) {
                setErrorLocalita({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!birthLocation) {
                setErrorBirthLocation({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
            if (!birthProvince) {
                setErrorBirthProvince({ isValid: false, msg: "Campo obbligatorio" });
                isValid = false;
            }
        }
        if (!isValid) {
            tab = "Dati Anagrafici";
        }
        if (personType === Constants.PF) {
            isValidDocument = handleDocumentValidation();
            if (!isValidDocument && !tab) {
                tab = "Documenti";
            }
        }

        if (tab) {
            tabRef.current.setState({ activeTab: tab });
        }
        return isValid && isValidDocument;

    };

    const handleDocumentValidation = () => {
        let isValid = true;
        let checkEndDate = new Date();

        if(!arcoDocumentType){
            setDocumentType(arcoDocumentTypes[0]);
        }
        if (!number) {
            setErrorNumber({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }
        if (!beginDate) {
            setErrorBeginDate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else {
            checkEndDate = new Date(beginDate);
            checkEndDate.setFullYear(beginDate.getFullYear() + 10);
        }
        if (!endDate) {
            setErrorEndDate({ isValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        } else if (endDate < beginDate) {
            setErrorEndDate({ isValid: false, msg: "Data non valida" });
            isValid = false;
        }

        if (!authority) {
            setErrorAuthority({ sValid: false, msg: "Campo obbligatorio" });
            isValid = false;
        }
        return isValid;
    };

    const handleClose = () => {
        props.onHide();
    };

    const saveAll = () => {

        if (handleRegistryValidation()) {
            actions.saveArcoRegistry(formData).then((response) => {
                props.onHide(response);
            }, (error) => {
                handleRegistrySaveErrors(error);
            })

        }
    };

  const  handleRegistrySaveErrors = ((error) => {
        if (error?.message === "error.validation") {
            let errorList = "";
            error.fieldErrors.forEach((e) => {
                if (errorList) {
                    errorList = errorList + "</br>";
                }
                errorList = errorList + e.defaultMessage
            });
            PopupError(
                {
                    ...props,
                    text:
                        "Errore nel salvataggio dell'anagrafica",
                    second_title: errorList
                }
            );
    } else {
        PopupError(
            {
                ...props,
                text:
                    "Errore nel salvataggio dell'anagrafica"
            }
        );

    }});

    /**
     * effetto per salvare firmatari e procuratori sull'anagrafica padre
     */
    useEffect(() => {
        if (formData.id && handleRegistryValidation() && !props.disableInsert) {
            actions.saveArcoRegistry(formData).then((res) => {
                updateFormData({
                    ...formData,
                    res
                })
            }, (err) => {
                handleRegistrySaveErrors(err);
            })
        }
    }, [formData.arcoRegistryHolders, formData.arcoRegistrySignatories])

    const controlloAntiterrorismo = () => {
        if (formData.id && handleRegistryValidation()) {
            callDownloadService();
        }

    };

    const callDownloadService = () => {
        setLoading(true);
        actions.downloadAntiTerrorism(formData).then((response) => {
            if (response) {
                const url = window.URL.createObjectURL(actions.getBlobFromBase64([response]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "ANTITERRORISMO_" + getSurnameOrDenomination() + ".zip");
                document.body.appendChild(link);
                link.click();
                PopupSuccess()
            } else {
                PopupError({ ...props, text: "File non trovato" });
            }
        },
            (error) => {
                PopupError({ ...props, text: "Si è verificato un errore durante la creazione del report" });
            }).then(() => {
                setLoading(false);
            });
    };

    const getSurnameOrDenomination = () => {
        return Constants.PF === personType ? (lastName + ' ' + firstName) : denomination
    }

    useEffect(() => {
        setHeader(() => {
            if ((formData.firstName && formData.lastName) || formData.denomination) {
                return <React.Fragment><span>{"ANAGRAFICA DI "}</span> <strong>{getSurnameOrDenomination()}</strong></React.Fragment>;
            } else {
                return arcoSubject.companyName + " - NUOVA ANAGRAFICA";
            }
        })
    }, [formData.firstName, formData.lastName, formData.denomination])


    const openChildRegistryModal = (registry, type) => {
        setShowDetailType(type);
        setshowDetailItem(registry);
        setDepth(depth + 1);
        setshowRegistryModal(true);
    }

    const closeChildRegistryModal = (registry) => {
        if (registry) {
            if (showDetailType === Constants.SIGNATORY) {
                let newarray = formData.arcoRegistrySignatories || [];
                let index = newarray.findIndex(e => e.id === registry.id);
                if (index !== -1) {
                    newarray[index] = registry;
                }
                updateFormData({
                    ...formData,
                    arcoRegistrySignatories: newarray
                });
            } else if (showDetailType === Constants.HOLDER) {
                let newarray = formData.arcoRegistryHolders || [];
                let index = newarray.findIndex(e => e.id === registry.id);
                if (index !== -1) {
                    newarray[index] = registry;
                }
                updateFormData({
                    ...formData,
                    arcoRegistryHolders: newarray
                });
            }
        }
        setShowDetailType(null);
        setshowDetailItem(null);
        setDepth(depth - 1);
        setshowRegistryModal(false);
    }

    return (

        <React.Fragment>
            <Modal
                className={'arco-modal-registries'}
                backdrop="static"
                show={showModalCheck}
                onHide={handleClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable>
                <div className="bar"></div>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{header}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-5">
                    <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 d-flex">
                        <div className="tab-content col-lg-12">
                            <Tabs ref={tabRef}>
                                <div id="tab-sub" label="Dati Anagrafici">

                                    <div className="container">
                                        <div className="row">
                                            {/*TIPO PERSONA*/}
                                            <div className="col-lg-2">
                                                <div className="form-check">
                                                    <label>Tipo</label>
                                                    <div className="inputRadio mt-3" >
                                                        <input hidden={true} className="form-check-input"
                                                            type="radio"
                                                            name={`personalType${Constants.PNF}`}
                                                            checked={personType === Constants.PNF}
                                                            disabled={props.fromHolders != undefined || props.fromSignatories != undefined}
                                                            id={`cr1${Constants.PNF}`} value={Constants.PNF}
                                                            onClick={handlePersonTypeClick} />
                                                        <label className="form-check-label"
                                                            htmlFor={`cr1${Constants.PNF}`}>
                                                            <img width="20" className="me-2"
                                                                src={personType === Constants.PNF ? radioSel : radioUnsel} />
                                                            Persona Giuridica
                                                        </label>
                                                    </div>

                                                    <div className="inputRadio mt-3">
                                                        <input hidden={true} className="form-check-input"
                                                            type="radio"
                                                            name={`personalType${Constants.PF}`}
                                                            checked={personType === Constants.PF || props.fromHolders != undefined || props.fromSignatories != undefined}
                                                            id={`cr2${Constants.PF}`} value={Constants.PF}
                                                            onClick={handlePersonTypeClick} />
                                                        <label className="form-check-label"
                                                            htmlFor={`cr2${Constants.PF}`}>
                                                            <img width="20" className="me-2"
                                                                src={personType === Constants.PF ? radioSel : radioUnsel} />
                                                            Persona Fisica
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*DATI ANAGRAFICI*/}
                                            <div className="col-lg-10">
                                                <div className="row">
                                                    <div className="col-lg-3 ">
                                                        <BwmInput name='code'
                                                            label='Codice Fiscale'
                                                            className='form-control'
                                                            onChange={e => handlerChangeFiscalCode(e)}
                                                            error={errorFiscalCode}
                                                            value={fiscalCode}
                                                            onBlur={e => handleKeyPress(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="row">
                                                            {personType === Constants.PNF &&
                                                                <div className="col-lg-12 ms-2">
                                                                    <BwmInput 
                                                                        label="Denominazione"
                                                                        className='form-control'
                                                                        onChange={e => handlerChangeDenomination(e)}
                                                                        error={errorDenomination}
                                                                        value={denomination}
                                                                    />

                                                                </div>
                                                            }

                                                            {personType === Constants.PF &&
                                                                <div className='col-lg-6 '>
                                                                    <BwmInput name='lastName'
                                                                        label='Cognome'
                                                                        className='form-control'
                                                                        onChange={e => handlerChangeSurname(e)}
                                                                        value={lastName}
                                                                        error={errorLastName}
                                                                    />
                                                                </div>
                                                            }
                                                            {personType === Constants.PF &&
                                                                <div className="col-lg-6 ">
                                                                    <BwmInput 
                                                                        label="Nome "
                                                                        className='form-control'
                                                                        onChange={e => handlerChangeFirstName(e)}
                                                                        error={errorFirstName}
                                                                        value={firstName}
                                                                        name="name" />

                                                                </div>
                                                            }

                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {personType === Constants.PF &&
                                                        <div className="col-lg-1 text-truncate" style={{ paddingLeft: 15 + 'px' }}>
                                                            <BwmSelect
                                                                options={Constants.genderList}
                                                                name='gender'
                                                                label='Sesso'
                                                                className='form-control'
                                                                onChange={handlerChangeGender}
                                                                value={gender}
                                                                error={errorGender}
                                                            />
                                                        </div>
                                                    }
                                                    {personType === Constants.PF &&
                                                        <div className="col-lg-2 ">
                                                            <DateField label="Data di nascita"
                                                                date={birthDate}
                                                                onChange={date => handlerChangeBirthDate(date)}
                                                                error={errorBirthDate}
                                                            />
                                                        </div>
                                                    }
                                                    {personType === Constants.PF &&
                                                        <div className="col-lg-7 ">
                                                            <AutoCompileCustom //Autocompile
                                                                label="Comune di nascita"
                                                                id="Location"
                                                                className='form-control'
                                                                handleInputAutocompile={handleInputAutocompileBirth}
                                                                filtro={locationsBirth}
                                                                value={birthLocation}
                                                                error={errorBirthLocation}
                                                            />
                                                        </div>}
                                                    {personType === Constants.PF && <div className="col-2 p-0 ">
                                                        <ProvinceField province={birthProvince}
                                                            onChange={e => handleBirthProvinceChange(e)}
                                                            className='form-control'
                                                            error={errorBirthProvince}
                                                        />
                                                    </div>}
                                                </div>

                                                <div className="row">

                                                    <div className="col-lg-8 ">
                                                        <AutoCompileCustom //Autocompile
                                                            label={personType === Constants.PF ? "Località di residenza" : "Località sede legale"}
                                                            id="Location"
                                                            className='form-control'
                                                            handleInputAutocompile={handleInputAutocompileResidence}
                                                            filtro={locations}
                                                            value={location}
                                                            error={errorLocation}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <BwmInput 
                                                            label="Cap"
                                                            className='form-control'
                                                            onChange={e => handlerChangeCap(e)}
                                                            error={errorCap}
                                                            value={cap}
                                                            maxLength="5"
                                                        />
                                                    </div>
                                                    <div className="col-2 p-0">
                                                        <ProvinceField province={province}
                                                            onChange={e => handleProvinceChange(e)}
                                                            className='form-control'
                                                            error={errorProvince}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <BwmInput 
                                                            label={personType === Constants.PF ? "Indirizzo di residenza" : "Indirizzo sede legale"}
                                                            className='form-control'
                                                            onChange={e => handlerChangeLocalita(e)}
                                                            error={errorLocalita}
                                                            value={localita}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <BwmSelect
                                                            options={getCountryList()}
                                                            label='Stato'
                                                            className='form-control'
                                                            value={nation}
                                                            onChange={e => handlerNation(e)}
                                                            error={errorNation}
                                                            maxLength="3"
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {personType === Constants.PF ?
                                    <div id="tab-sub-doc" label="Documenti">
                                        {/*SEZIONE DOCUMENTI*/}

                                        <div className="row">
                                            <div className="col-3">
                                                <BwmSelect
                                                    options={documentTypesDropdownList}
                                                    name='b2'
                                                    label='Tipo documento'
                                                    className='form-control'
                                                    onChange={e => handlerDocumentType(e)}
                                                    value={arcoDocumentType.id}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <BwmInput 
                                                    label="Numero"
                                                    className='form-control'
                                                    onChange={e => handlerChangeNumber(e)}
                                                    error={errorNumber}
                                                    value={number.toUpperCase()}
                                                    name="name" />
                                            </div>
                                            <div className="col-2">
                                                <DateField 
                                                    label="Data Rilascio"
                                                    onChange={date => handlerChangeReleaseDate(date)}
                                                    date={beginDate}
                                                    name={"beginDate"}
                                                    error={errorBeginDate} />
                                            </div>
                                            <div className="col-sm-2">
                                                <DateField 
                                                    label="Data Scadenza"
                                                    onChange={date => handlerChangeExpirationDate(date)}
                                                    date={endDate}
                                                    name={"endDate"}
                                                    error={errorEndDate} />
                                            </div>
                                            <div className="col-3">
                                                <BwmInput 
                                                    label="Autorità Rilascio"
                                                    className='form-control'
                                                    onChange={e => handlerChangeAuthority(e)}
                                                    value={authority.toUpperCase()}
                                                    name="authority"
                                                    error={errorAuthority} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="ps-3 col-lg-3">
                                                <label><b style={{ color: '#128186' }}>Persona Politicamente esposta</b></label>
                                                <div className="input-group mt-2">
                                                    <BwmCheckbox
                                                        name="politicallyExposed"
                                                        value={politicallyExposed}
                                                        onClick={(e) => handlerChangeAbility(e)}
                                                        className='form-control'
                                                        error={errorPoliticallyExposed}
                                                    />
                                                </div>
                                            </div>
                                            
                                                <div className="col-4">
                                                    <BwmInput 
                                                        label="Origine Fondi"
                                                        className='form-control'
                                                        onChange={e => handlerChangeFunds(e)}
                                                        error={errorSourceOfFunds}
                                                        value={sourceOfFunds}
                                                        name="name" />
                                                </div>
                                            
                                            
                                                <div className="col-5">
                                                    <BwmInput 
                                                        label="Origine Fondi dettaglio"
                                                        className='form-control'
                                                        onChange={e => handlerChangeFundsDetail(e)}
                                                        value={sourceOfFundsDescription}
                                                        error={errorSourceOfFundsDescription}
                                                        name="name" />
                                                </div>
                                            
                                        </div>
                                    </div>
                                    : <div id="tab-sub" label="Documenti" disabled="disabled"></div>}


                                <div id="tab-sub-sign" label={formData?.personType === Constants.PF ? "Procuratori" : "Firmatari"}>


                                    <ArcoRegistrySignatories
                                        {...props}
                                        getArcoRegistries={props.getArcoRegistries}
                                        signatories={formData.arcoRegistrySignatories}
                                        handleClose={handleClose}
                                        addSignatory={(signatory) => addSignatoryOrHolder(Constants.SIGNATORY, signatory)}
                                        removeSignatory={(id) => removeSignatoryOrHolder(Constants.SIGNATORY, id)}
                                        selectedItem={props?.selectedItem}
                                        openChildRegistryModal={(registry) => openChildRegistryModal(registry, Constants.SIGNATORY)}
                                        disableInsert={props.disableInsert}
                                    />
                                </div>
                                {personType === Constants.PNF ?
                                    <div id="tab-sub-hold" label="Titolari Effettivi">

                                        <ArcoRegistryHolders
                                            {...props}
                                            getArcoRegistries={props.getArcoRegistries}
                                            holders={formData.arcoRegistryHolders}
                                            handleClose={handleClose}
                                            addHolder={(holder) => addSignatoryOrHolder(Constants.HOLDER, holder)}
                                            removeHolder={(id) => removeSignatoryOrHolder(Constants.HOLDER, id)}
                                            selectedItem={props?.selectedItem}
                                            openChildRegistryModal={(registry) => openChildRegistryModal(registry, Constants.HOLDER)}
                                            disableInsert={props.disableInsert}
                                        />

                                    </div> : <div id="tab-sub" label="Titolari Effettivi" disabled="disabled"></div>}
                                <div id="tab-sub-risk" label="Rischio Anagrafica">
                                    {/*SEZIONE RISCHI*/}
                                    <div className="row">
                                        <div className="col-3 pe-7 ps-7">
                                            <BwmSelect
                                                options={naturaGiuridicaLista}
                                                onChange={handlerChangeNaturaGiuridica}
                                                name='a1'
                                                label='A1 - Natura Giuridica'
                                                className='form-control'
                                                value={naturaGiuridica !== undefined ? naturaGiuridica.id : ''}

                                            />
                                        </div>
                                        <div className="col-3 pe-7 ps-7">
                                            <BwmSelect
                                                options={attivitaPrevalenteLista}
                                                onChange={handlerChangeAttivitaPrevalente}
                                                name='a2'
                                                label='A2 - Attività Prevalente'
                                                className='form-control'
                                                value={attivitaPrevalente !== undefined ? attivitaPrevalente.id : ''}
                                            />
                                        </div>
                                        <div className="col-3 pe-7 ps-7">
                                            <BwmSelect
                                                options={comportamentoLista}
                                                onChange={handlerChangeComportamento}
                                                name='a3'
                                                label='A3 - Comportamento'
                                                className='form-control'
                                                value={comportamento !== undefined ? comportamento.id : ''}

                                            />
                                        </div>
                                        <div className="col-3 pe-7 ps-7">
                                            <BwmSelect
                                                options={areaGeograficaLista}
                                                onChange={handlerChangeAreaGeografica}
                                                name='a4'
                                                label='A4 - Area Geografica'
                                                className='form-control'
                                                value={areaGeografica !== undefined ? areaGeografica.id : ''}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-3 pe-7 ps-7">
                                            <BwmSelect
                                                options={personalizzataLista}
                                                onChange={handlerChangePersonalizzata}
                                                name='a5'
                                                label='A5 - Personalizzata'
                                                className='form-control'
                                                value={personalizzata !== undefined ? personalizzata.id : ''}

                                            />
                                        </div>
                                    </div>
                                </div>

                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                        <button
                            className="btn btn-outline-primary btn-empty px-4 btn-sm"
                            onClick={handleClose}
                        ><IUndo className={"padding-right-4px svg"} width="16" fill={"#128186"} />&nbsp; CHIUDI
                        </button>
                        <button onClick={controlloAntiterrorismo} disabled={isEmpty(formData.id) || props.disableInsert}
                        
                            className="btn btn-primary btn-new-rel px-5"><ITextFile className={"padding-right-4px svg"} width="16" />&nbsp; CONTROLLO ANTITERRORISMO
                        </button>
                        {loading && <PageSpinner />}
                        <button onClick={saveAll}
                            disabled={props.disableInsert}
                            className="btn btn-primary btn-new-rel px-5"><ISave className={"padding-right-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {showRegistryModal && <div>
                <ArcoRegistryModal
                    {...props}
                    selectedItem={showDetailItem}
                    showModalCheck={showRegistryModal}
                    onHide={closeChildRegistryModal}
                    depth={depth}
                    fromRegistry={props.fromRegistry}
                    fromHolders={props.fromHolders}
                    fromSignatories={props.fromSignatories}
                />
            </div>}
        </React.Fragment >
    )
}
