import React from 'react';
import { format, isValid } from 'date-fns';
import { formatDateForInput, isValidDate } from '../Utility';

export class DateField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: props.error || {isValid:true, msg:''},
            touched: props.touched
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.error !== undefined){
            this.setState({error: nextProps.error});
            this.setState({touched: nextProps.touched});
        }
    }


    renderErrorMessage=(date)=>{
        if(!isValidDate(new Date(date))){
            return  <div className="text-danger">Inserire data valida</div>
        } else if (!this.state.error.isValid){
            return <div className="text-danger">{this.state.error.msg}</div>
        }
        return <></>
    }

    render() {
        const {label, date, onChange, isDisabled} = this.props;

        const inputWrapperClass = `input-wrapper w-100 ${this.state.touched && this.props.error !== undefined ? 'has-error' : ''}`;

        return (
            <React.Fragment>
                <div className={inputWrapperClass}>
                    <div className="form-group">
                        {label && <span className="floatingLabel-datepickr">{label}</span>}
                        <div className="input-group">
                            <input
                                type='date'
                                value={(date !== null && isValid(new Date(date)) )? formatDateForInput(date) : undefined}
                                disabled={isDisabled}
                                onChange={(e)=>onChange(isValidDate(new Date(e.target.value))?new Date(e.target.value):undefined)}
                                className={"form-control form-date px-2"}
                                max={'2500-01-01'}
                            >

                            </input>

                        </div>
                        {/* {  this.renderErrorMessage(date)
                        } */}
                    </div>
                </div>

            </React.Fragment>
        )
    }
}



