import React, { useEffect, useState } from 'react';
import { CommunicationStatus } from '../../../../config/CommunicationStatus';
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg';
import { ReactComponent as IRotateRight } from "../../../../styles/images/svgs/solid/redo.svg";
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg';
import { ReactComponent as IZipFile } from '../../../../styles/images/svg/file-archive.svg';
import { ReactComponent as IEnvelope } from "../../../../styles/images/svgs/solid/envelope.svg"

import { ElaborateCommunicationModal } from './ElaborateCommunicationModal';
import { computeIsError, computeStatusText, isReceipt } from './CommunicationRow';
import { formatDateForDisplay, isEmpty, isNotEmpty } from '../../../shared/Utility';

export function CommunicationHistoryList(props) {
    const { communications, subjectId, renderDeleteButton, openEmailModal
        , downloadReceiptTxt, downloadTxt, downloadPecTz, downloadPdf, downloadDgn, downloadErrorReport
        , reelaborateCommunicationTxt, SearchBar, hasRowsToShow } = props;
    const [allChecked, setAllChecked] = useState(false);
    const [disableDeleteButton, setDisableDeleteButton] = useState(true);
    const [checkedCommunications, setCheckedCommunications] = useState(new Map());


    const filterReceipts=()=> {
        return communications.filter(c => isReceipt(c) === false);
    }

    //rivaluto la flag del bottone di eliminazione, e la flag di check delle righe
    useEffect(() => {
        if (checkedCommunications.size > 0) {
            setDisableDeleteButton(false);
        } else {
            setDisableDeleteButton(true);
        }
        setAllChecked(checkedCommunications.size === filterReceipts().length && checkedCommunications.size > 0);
    }, [checkedCommunications]);


    //resetto la lista di comunicazioni selezionate al ricaricamento della lista
    useEffect(() => {
        setCheckedCommunications(new Map());
    }, [communications]);

    const handleAllChecked = (e) => {
        let checkedComms = new Map();
        if (e.target.checked) {
            filterReceipts().forEach(element => {
                //le comunicazioni con ricevuta non sono eliminabili
                checkedComms.set(element.id, element);
            })
        } 
        setCheckedCommunications(checkedComms);
    }

    const handleCheckChange = (e, communication) => {
        let checkedComms = new Map(checkedCommunications);
        if (e.target.checked) {
            checkedComms.set(communication.id, communication)
        } else {
            checkedComms.delete(communication.id)
        }
        setCheckedCommunications(checkedComms);
    }

    const renderRowHeader = () => {
        return (
            <React.Fragment>
                <div className='col w-5 text-center div-td'>
                    <input type="checkbox" checked={allChecked} onChange={(e) => {
                        handleAllChecked(e);
                    }} />
                </div>
                <div className='col w-10 div-td'>Data creazione</div>
                <div className='col w-5 div-td'>Periodo</div>
                <div className='col w-15 div-td'>Comunicazione</div>
                <div className='col w-10 div-td'>Tipologia di invio</div>
                <div className='col w-50 div-td'>Stato</div>
                <div className='col w-10 div-td'>Scarica</div>
            </React.Fragment>
        )
    }




    const renderRows = (communications=[]) => {
        return communications.map((communication, index) => {
            let status = computeStatusText(communication);
            let receipt = isReceipt(communication);
            let deletable = receipt || isEmpty(communication?.pecSentDate)
            let created = communication.communicationStatus === CommunicationStatus.CREATED || communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR;
            let isError = computeIsError(communication.communicationStatus);
            let canDownloadPecat = communication.communicationStatus === CommunicationStatus.CREATED || communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED || communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR;

            const isChecked = checkedCommunications.has(communication.id);

            return (
                <React.Fragment key={`key-comm-history-${communication.id}`}>
                    <div className={`text-start row-table ${isChecked && "bg-cobalt bold"}`} id={`history-row-${communication.id}`}>
                        <div className='col w-5 text-center div-td'>
                            {(deletable === true)
                                &&
                                <input type="checkbox" aria-label="Checkbox for following text input" id={`history-row-check-${communication.id}`}
                                    name="communicationCheck"
                                    value={communication.id} checked={isChecked}
                                    onChange={(e) => handleCheckChange(e, communication)}
                                />
                            }

                        </div>
                        <div
                            className='col w-10 div-td text-truncate'>{formatDateForDisplay(communication?.creationDate)}</div>
                        <div className='col w-5 div-td text-truncate'>{communication?.referencePeriod}</div>
                        <div
                            className='col w-15 div-td text-truncate'>{communication.communicationType?.code} - {communication?.communicationType?.description}</div>
                        <div className='col w-10 div-td text-truncate'>{communication?.dispatchType === "ORDINARY" ? "Ordinario" : "Straordinario"}</div>
                        <div data-toggle="tooltip" data-placement="top" title={status} className={`col w-50 div-td text-truncate ${isError && 'color-red'}`}>{status}</div>
                        <div className='col w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4 containerPopOver'>
                            {(created || receipt) &&
                                <span data-tip={"Invia COMUNICAZIONE"} onClick={() => openEmailModal(communication)}><IEnvelope width="20" fill="#128186" /></span>
                            }
                            {receipt &&
                                <span data-tip={"Scarica RICEVUTA"} onClick={() => downloadReceiptTxt(communication)}><ITextFile width="20" /></span>
                            }
                            <span data-tip={"Scarica COMUNICAZIONE TXT"} onClick={() => downloadTxt(communication)}><ITextFile width="20" fill="#128186" /></span>
                            {(canDownloadPecat) &&
                                <div data-tip={"Scarica COPPIA FILE PECAT"}><IZipFile width="20" fill="#128186" onClick={() => downloadPecTz(communication)} /></div>
                            }
                            {(communication.communicationStatus !== CommunicationStatus.ERROR_SID && !communication.elaboratedTxt) &&
                                <span data-tip={"Scarica STAMPA COMUNICAZIONE PDF"} onClick={() => downloadPdf(communication)}><IPdfFile width="20" fill="#128186" /></span>
                            }
                            <span data-tip={"Rielabora FILE TXT"} onClick={() => reelaborateCommunicationTxt(communication)}><IRotateRight width="20" fill="#128186" /></span>
                        </div>
                    </div>

                </React.Fragment>
            )
        });
    }

    const renderReelaborateCommunicationButton = () => {
        return <ElaborateCommunicationModal  {...props}
            subjectId={subjectId}
            downloadPecTz={(communication) => downloadPecTz(communication)}
            downloadPdf={(communication) => downloadPdf(communication)}
            downloadTxt={(communication) => downloadTxt(communication)}
            downloadDgn={(communication) => downloadDgn(communication)}
            downloadErrorReport={(communication) => downloadErrorReport(communication)}
        />

    }
    return (
        <>

               { hasRowsToShow ?
                <React.Fragment>
                    <div className="row header-btn-history">
                        <div className={"col-3"}>
                            STORICO COMUNICAZIONI &nbsp; &nbsp; &nbsp;
                            {renderDeleteButton(disableDeleteButton, checkedCommunications)}
                        </div>
                        <div className={"col-7"}>
                            {SearchBar()}
                        </div>
                        <div className="col-2 text-end">
                            {renderReelaborateCommunicationButton()}
                        </div>
                    </div>

                    <div className="text-start header-table">
                        {renderRowHeader()}
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="row justify-content-center pt-4 pe-4">
                        <div className='col text-center'>
                        {renderReelaborateCommunicationButton()}

                        </div>
                    </div>
                </React.Fragment>}
            {renderRows(communications)}
        </>)

  
}